import { styled } from '@mui/material';

export const StyledContainer = styled('div')(() => ({
  maxWidth: 'calc(1216px + 2rem)',
  width: '100%',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  marginLeft: 'auto',
  marginRight: 'auto',
}));
