import { Breadcrumbs, Link as ExternalLink, styled, Theme } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createTypography';
import { Link as InternalLink } from 'react-router-dom';

export const sxStyles = {
  currentPage: (theme: Theme) => ({
    color: theme.palette.primary.main,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
  }),
};

export const StyledBreadcrumbs = styled(Breadcrumbs)(() => ({
  display: 'flex',
  alignItems: 'center',
  svg: {
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
    fill: '#A8A9AD',
  },
}));

const commonLinkStyles = (theme: Theme) => ({
  ...(theme.typography as unknown as { body4: CSSProperties }).body4,
  display: 'inline-flex',
  alignItems: 'center',
  color: theme.palette.text.secondary,
  fontSize: theme.typography.body2.fontSize,

  '& > svg:first-of-type': {
    marginRight: '0.75rem',
    fill: '#A8A9AD',
  },
});

export const StyledInternalLink = styled(InternalLink)(({ theme }) => ({ ...commonLinkStyles(theme) }));

export const StyledExternalLink = styled(ExternalLink)(({ theme }) => ({
  ...commonLinkStyles(theme),
  textDecoration: 'none',
}));
