import { CircularProgress, CircularProgressProps } from '@mui/material';
import { CoreBox, CoreBoxProps } from '@youscience/khaleesi';
import { defaultSpinnerProps, defaultWrapperProps } from './Loader.styles';

interface LoaderProps {
  spinnerProps?: CircularProgressProps;
  wrapperProps?: CoreBoxProps;
}

export const Loader = (props: LoaderProps) => {
  const wrapperProps = { ...defaultWrapperProps, ...props.wrapperProps };
  const spinnerProps = { ...defaultSpinnerProps, ...props.spinnerProps };

  return (
    <CoreBox {...wrapperProps}>
      <CircularProgress {...spinnerProps} />
    </CoreBox>
  );
};
