import { createContext, useCallback, useContext, useLayoutEffect, useState } from 'react';

import { MeWithAccessRecordsApiResponse } from '@youscience/user-service-common/';

import TokenService from '@services/token';

import AuthService from '../services/auth';
import { AuthProvider } from './authStore';

interface AuthSessionProps {
  isAuthenticated: boolean;
  error: unknown;
  isLoading: boolean;
}

const getIsAuthenticated = (user: MeWithAccessRecordsApiResponse): boolean => {
  return !!user;
};

export const useAuthentication = (): AuthSessionProps => {
  const [isLoading, setIsLoading] = useState(true);

  const { getMe } = AuthProvider();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState<unknown>();

  const refreshState = useCallback(async () => {
    setIsLoading(true);

    try {
      TokenService.removeRefreshToken();

      await AuthService.authorize();
      const currentUser = await getMe();
      const isAuthenticatedValid = getIsAuthenticated(currentUser);

      setIsAuthenticated(isAuthenticatedValid);
      setError('');
      setIsLoading(false);
    } catch (err) {
      setIsAuthenticated(false);

      // TO-DO complete errors handling
      if (err === 'not authenticated') {
        setError('');
      } else {
        setError(err);
      }
      setIsLoading(false);
    }
  }, []);

  useLayoutEffect(() => {
    void refreshState();
  }, [refreshState]);

  return {
    isAuthenticated,
    isLoading,
    error,
  };
};

export const AuthSessionContext = createContext<AuthSessionProps>({} as AuthSessionProps);

export function useAuthSessionContext() {
  return useContext(AuthSessionContext);
}
