import { Outlet } from 'react-router-dom';

import { Header } from '@components/Layout/components';
import { Loader } from '@components/Loader';
import { useAuthStoreContext } from '@context/authStore';
import { Footer } from '@youscience/brightpath-header';
import { CoreBox } from '@youscience/khaleesi';
import { sxStyles } from './AdminLayout.styles';
import { SideNav } from './components/SideNav';

export const AdminLayout = () => {
  const { isUpdatingUserPreferences } = useAuthStoreContext();

  return isUpdatingUserPreferences ? (
    <Loader />
  ) : (
    <>
      <CoreBox sx={{ display: 'flex' }}>
        <CoreBox sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Header />
        </CoreBox>
        <SideNav />
      </CoreBox>
      <CoreBox sx={sxStyles.wrapper}>
        <CoreBox component='main' sx={sxStyles.main}>
          <Outlet />
        </CoreBox>
      </CoreBox>
      <CoreBox sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Footer />
      </CoreBox>
    </>
  );
};
