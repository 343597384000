import { BRIGHTPATH_ADMIN_URL, EMPLOYERS_URL } from '@constants/externalRoutes';
import EmployersSvg from '@images/employers.svg?react';
import { CorporateFareOutlined, PersonOutline, ShoppingBagOutlined } from '@mui/icons-material';
import { RoleSpecificNav } from './types';

export const NAVIGATION_ITEMS: RoleSpecificNav[] = [
  {
    allowedRoles: ['Admin', 'Staff'],
    icon: <PersonOutline fontSize='large' sx={{ height: '1.5rem', width: '1.5rem' }} />,
    key: 'users',
    path: `${BRIGHTPATH_ADMIN_URL}/users`,
    title: 'Users',
    translateKey: 'Users',
  },
  {
    allowedRoles: ['Admin', 'Staff'],
    icon: <CorporateFareOutlined fontSize='large' sx={{ height: '1.5rem', width: '1.5rem' }} />,
    key: 'organizations',
    path: `${BRIGHTPATH_ADMIN_URL}/organizations`,
    title: 'Organizations',
    translateKey: 'Organizations',
  },
  {
    allowedRoles: ['Admin'],
    icon: <EmployersSvg style={{ height: '1.5rem', width: '1.5rem' }} />,
    key: 'employers',
    path: `${EMPLOYERS_URL}/micro/admin/employers`,
    restrictOrg: true,
    title: 'Employers',
    translateKey: 'Employers',
  },
  {
    allowedRoles: ['Admin'],
    icon: <ShoppingBagOutlined fontSize='large' sx={{ height: '1.5rem', width: '1.5rem' }} />,
    key: 'd2c',
    path: `${BRIGHTPATH_ADMIN_URL}/d2c`,
    restrictOrg: true,
    title: 'DTC Purchases',
    translateKey: 'DTC Purchases',
  },
];

export const ROLE_SPECIFIC_ROUTES: RoleSpecificNav[] = [
  {
    allowedRoles: ['Admin'],
    icon: <EmployersSvg style={{ height: '1.5rem', width: '1.5rem' }} />,
    key: 'employers',
    path: `${EMPLOYERS_URL}m/micro/admin/employers`,
    restrictOrg: true,
    title: 'Employers',
    translateKey: 'Employers',
  },
];
