export const sxStyles = {
  sectionTitle: {
    fontSize: '2.25rem',
    lineHeight: '54px',
  },
  subSectionTitle: {
    fontSize: '28px',
    lineHeight: '42px',
    marginTop: '2.5rem',
  },
  subSectionSubTitle: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  interestsContainer: {
    margin: '2rem 0',
  },
  careersContainer: {
    marginTop: '2.5rem',
  },
};
