import { styled, Theme } from '@mui/material';

export const sxStyles = {
  header: (theme: Theme) => ({
    backgroundColor: theme.palette.common.white,
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
  }),
};

export const StyledHeaderInner = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  margin: '0 auto',
}));

export const StyledHeaderImage = styled('img')(() => ({
  maxHeight: '30px',
  maxWidth: 'none',
}));
