import { MouseEvent, useEffect, useState } from 'react';
import { AssignmentInd, PostAdd } from '@mui/icons-material';
import { CoreTypography } from '@youscience/khaleesi';

import { ROUTES } from '@constants/routes';

import { Resume } from '@interfaces';

import NavigationCard from '@components/NavigationCard';

import ResumeBuilderService from '@services/resumeBuilder';

import { StyledCardsWrap } from './ResumeBuilderActions.styles';

interface ResumeBuilderActionsProp {
  title?: string;
  sxTitle: unknown;
}

export const ResumeBuilderActions = ({ title = 'Resume builder', sxTitle }: ResumeBuilderActionsProp) => {
  const [downloadingSample, setDownloadingSample] = useState(false);
  const [resume, setResume] = useState<Resume | null>(null);

  const handleSampleResumeDownload = async (event: MouseEvent) => {
    event.preventDefault();
    if (downloadingSample) {
      return;
    }
    setDownloadingSample(true);
    try {
      await ResumeBuilderService.downloadSampleResume();
      setDownloadingSample(false);
    } catch (error) {
      setDownloadingSample(false);
    }
  };

  const setCurrentResume = async () => {
    const currentResume = await ResumeBuilderService.getCurrentResume({ resumeId: null, preventCreation: true });

    setResume(currentResume);
  };

  useEffect(() => {
    void setCurrentResume();
  }, []);

  return (
    <>
      <CoreTypography sx={sxTitle || {}} variant='h4'>
        {title}
      </CoreTypography>

      <StyledCardsWrap>
        <NavigationCard
          icon={<PostAdd />}
          title={resume ? 'Edit your Resume' : 'Start Building'}
          navigationUrl={resume ? ROUTES.RESUME : ROUTES.RESUME_BUILDER_WELCOME}
        />

        <NavigationCard
          icon={<AssignmentInd />}
          title='Sample resume'
          navigationUrl='/#download-sample'
          onClickAction={handleSampleResumeDownload}
        />
      </StyledCardsWrap>
    </>
  );
};
