import { styled, Theme } from '@mui/material';
import { brightpathTheme } from '@youscience/theme';
import { Link } from 'react-router-dom';

export const sxStyles = {
  header: (theme: Theme) => ({
    padding: '1.5rem',
    alignSelf: 'center',
    borderBottom: `2px solid ${theme.palette.grey[300]}`,

    '& > h5:first-of-type': {
      fontSize: '1.5rem',
    },
  }),
  measurementShort: {
    marginTop: '0.5rem',
  },
  actionsWrap: {
    padding: '1.25rem',
    justifySelf: 'end',
  },
};

export const StyledCardWrap = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
  background: theme.palette.common.white,
  boxShadow: `0px 8px 24px -4px ${theme.palette.divider}`,
  borderRadius: '10px',
  transition: 'box-shadow 0.5s',

  '&:hover': {
    boxShadow: `0px 8px 32px -4px ${theme.palette.divider}`,
  },
}));

export const StyledTalentsWrap = styled('ul')(({ theme }) => ({
  padding: '1.5rem 1.5rem 1.5rem 3rem',
  borderBottom: `2px solid ${brightpathTheme.palette.grey[300]}`,
  margin: 0,
}));

export const StyledNavigationLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: '38px',
  fontSize: '0.875rem',
  fontWeight: 500,
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  padding: theme.spacing(2, 4),
  borderRadius: theme.spacing(8),
  textDecoration: 'none',
  cursor: 'pointer',
  borderStyle: 'solid',
  borderWidth: '2px',
  borderColor: theme.palette.primary.main,

  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },

  [theme.breakpoints.down('md')]: {
    maxHeight: '30px',
  },
}));
