import { ECP_API_URL } from '@constants/externalRoutes';
import { Resume } from '@interfaces';
import { AxiosError } from 'axios';
import api from '../utils/api';

const get = async (id: string): Promise<Resume> => {
  const response = await api(`${ECP_API_URL}/resumes/${id}`);

  return response?.data as Resume;
};

const create = async (body: Resume): Promise<Resume> => {
  const response = await api(`${ECP_API_URL}/resumes`, {
    method: 'POST',
    data: body,
  });

  return response?.data as Resume;
};

const update = async (id: string, body: Resume): Promise<Resume> => {
  const response = await api(`${ECP_API_URL}/resumes/${id}`, {
    method: 'PUT',
    data: body,
  });

  return response?.data as Resume;
};

const list = async (): Promise<Resume[]> => {
  const response = await api(`${ECP_API_URL}/resumes`);

  return response?.data as Resume[];
};

const getPreview = async (id: string): Promise<string> => {
  try {
    const response = await api(`${ECP_API_URL}/resumes/${id}/download?format=base64`);
    const { base64 } = response.data as { base64: string };

    return base64;
  } catch (error) {
    const { response } = error as {
      response: {
        status: number;
      };
    };

    return `${response.status}`;
  }
};

const downloadSampleResume = async (): Promise<BlobPart | string> => {
  try {
    const response = await api(`${ECP_API_URL}/resumes/download-sample`, { responseType: 'blob' });

    return response?.data as BlobPart;
  } catch (error) {
    const { response } = error as AxiosError;

    return `${response?.status ?? ''}`;
  }
};

const ResumeApi = {
  get,
  create,
  update,
  list,
  getPreview,
  downloadSampleResume,
};

export default ResumeApi;
