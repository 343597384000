import { CoreBox, CoreTypography } from '@youscience/khaleesi';
import { MouseEvent, ReactNode } from 'react';

import ChevronRight from '@images/chevron-right.svg?react';

import { StyledCardWrap, sxStyles } from './NavigationCard.styles';

interface NavigationCardProps {
  icon: ReactNode;
  title: string;
  navigationUrl: string;
  isOpenInNewTab?: boolean;
  onClickAction?: (event: MouseEvent) => Promise<void>;
}

export const NavigationCard = (props: NavigationCardProps) => {
  const { icon, title, navigationUrl, isOpenInNewTab = false, onClickAction } = props;

  return (
    <StyledCardWrap
      href={navigationUrl}
      target={isOpenInNewTab ? '_blank' : '_self'}
      rel='noreferrer'
      onClick={onClickAction}
    >
      <CoreBox sx={sxStyles.titleWrap}>
        <CoreBox sx={sxStyles.icon}>{icon}</CoreBox>

        <CoreTypography variant='h5'>{title}</CoreTypography>
      </CoreBox>

      <ChevronRight />
    </StyledCardWrap>
  );
};
