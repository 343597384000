import StateApi from '@api/state';
import { Resume } from '@interfaces';
import ResumeBuilderService from '@services/resumeBuilder';
import { useMutation, useQuery } from '@tanstack/react-query';

export const useFetchCurrentResume = (options?: { resumeId: string | null; force?: boolean }) =>
  useQuery({ queryKey: ['current-resume'], queryFn: () => ResumeBuilderService.getCurrentResume(options) });

export const useUpdateCurrentResume = () =>
  useMutation({
    mutationKey: ['current-resume', 'update'],
    mutationFn: (body: Resume) => ResumeBuilderService.updateCurrentResume(body),
  });

export const useFetchStates = () => useQuery({ queryKey: ['states'], queryFn: StateApi.list });
