import { useAuthStoreContext } from '@context/authStore';
import { USER_ROLES } from '@constants';

export const useIsRootAdmin = () => {
  const { currentAccess } = useAuthStoreContext();

  return (
    currentAccess?.tenant?.tenantId === import.meta.env.VITE_ROOT_ORG_ID &&
    currentAccess?.tenant?.permission?.role === USER_ROLES.ADMIN
  );
};
