export const DISCOVERY_API_URL: string = import.meta.env.VITE_DISCOVERY_API_URL;

export const DISCOVERY_ADMIN_URL: string = import.meta.env.VITE_DISCOVERY_ADMIN_URL;

export const DISCOVERY_URL: string = import.meta.env.VITE_DISCOVERY_URL;

export const ECP_API_URL: string = import.meta.env.VITE_ECP_API_URL;

export const COMMONAPP_API_URL: string = import.meta.env.VITE_COMMON_API_URL;

export const ISP_API_URL: string = import.meta.env.VITE_ISP_API_URL;

export const BRIGHTPATH_HOME_URL: string = import.meta.env.VITE_BRIGHTPATH_HOME_URL;

export const BRIGHTPATH_ADMIN_URL: string = import.meta.env.VITE_BRIGHTPATH_ADMIN_URL;

export const EMPLOYERS_URL: string = import.meta.env.VITE_EMPLOYERS_URL;

export const CERTIFICATIONS_URL: string = import.meta.env.VITE_CERTIFICATIONS_URL;

export const EDUCATION_CONNECTIONS_URL: string = import.meta.env.VITE_EDUCATION_CONNECTIONS_URL;

export const GUS_API_URL: string = import.meta.env.VITE_GUS_API_URL;

export const YS_SCHEDULE_DEMO_URL: string = import.meta.env.VITE_YS_SCHEDULE_DEMO_URL;

export const DISCOVERY_BRAINGAMES_URL: string = import.meta.env.VITE_DISCOVERY_BRAINGAMES_URL;

export const YS_HELP_CENTER_URL: string = import.meta.env.VITE_YS_HELP_CENTER_URL;
