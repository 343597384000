import { styled } from '@mui/material';

export const StyledCardsWrap = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
  marginTop: '1.25rem',

  [theme.breakpoints.up('xxs')]: {
    flexDirection: 'row',
  },
}));
