import { isECPEnabled } from '@components/ProtectedRoute/utils';
import { useAuthStoreContext } from '@context/authStore';
import { useFetchFeatureFLags } from '@hooks/featureFlags';
import { useTenantSettings } from '@hooks/TenantSettings';
import { useEffect, useMemo, useState } from 'react';

export const useEcpEnabled = () => {
  const { isLoading: isLoadingFeatureFlags, data: featureFlags } = useFetchFeatureFLags();

  const { userData, currentAccess } = useAuthStoreContext();

  const {
    isLoading: isLoadingTenantSettings,
    data: tenantSettings,
    isError: isErrorGettingTenantSettings,
  } = useTenantSettings(currentAccess?.tenant?.tenantId ?? '');

  const [ecpEnabled, setEcpEnabled] = useState<boolean | null>(null);
  const [settingsRefactorEnabled, setSettingsRefactorEnabled] = useState<boolean>(false);

  const isLoadingData = useMemo(
    () => isLoadingFeatureFlags || isLoadingTenantSettings,
    [isLoadingFeatureFlags, isLoadingTenantSettings],
  );

  useEffect(() => {
    if (featureFlags) {
      const settingsRefactor = featureFlags.SETTINGS_REFACTOR_ENABLED;

      if (settingsRefactor) setSettingsRefactorEnabled(settingsRefactor);
    }
  }, [featureFlags]);

  useEffect(() => {
    if (settingsRefactorEnabled && isErrorGettingTenantSettings) setEcpEnabled(false);
  }, [isErrorGettingTenantSettings, settingsRefactorEnabled]);

  useEffect(() => {
    if (!isLoadingData && tenantSettings) setEcpEnabled(isECPEnabled(settingsRefactorEnabled, userData));
  }, [isLoadingData, settingsRefactorEnabled]);

  return { ecpEnabled, isLoadingData, tenantSettings };
};
