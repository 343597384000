import { useState } from 'react';
import { Divider } from '@mui/material';
import {
  AttachMoney,
  ChevronRight,
  Favorite,
  FavoriteBorder,
  PeopleAltOutlined,
  SchoolOutlined,
} from '@mui/icons-material';
import { CoreBox, CoreButton, CoreGrid, CoreTypography } from '@youscience/khaleesi';

import { SCHOOL_LENGTH_LABELS } from '@constants';
import { EDUCATION_CONNECTIONS_URL } from '@constants/externalRoutes';

import { useUnsaveFavoriteCollege } from '@hooks/College';
import { College } from '@services/college';

import { StyledCard, StyledContainerLink, StyledLogo, sxStyles } from './CollegeCard.styles';

export interface CollegeCardProps {
  college: College;
  collegeUrl?: string;
}

export const CollegeCard = ({ college, collegeUrl }: CollegeCardProps) => {
  const [saved, setSaved] = useState<boolean>(college.favoriteCollege);
  const { mutateAsync: unsaveFavoriteCollege, isLoading: isUnsaving } = useUnsaveFavoriteCollege(`${college.id}`);

  const handleToggleFavorite = async () => {
    if (isUnsaving) return;

    if (saved) {
      setSaved(!saved);
      await unsaveFavoriteCollege();
    }
  };

  const handleMoreDetails = () => {
    window.open(`${EDUCATION_CONNECTIONS_URL}/schools/${college.id}`, '_blank');
  };

  return (
    <StyledContainerLink href={collegeUrl} target='_blank' rel='noreferrer'>
      <StyledCard>
        <CoreBox sx={sxStyles.logoCover} onClick={handleMoreDetails}>
          <StyledLogo src={college.attributes.pictureUrl} alt={`${college.attributes.name} logo`} />
        </CoreBox>
        <Divider />

        <CoreBox sx={sxStyles.boxPadding} onClick={handleMoreDetails}>
          <CoreTypography sx={sxStyles.title} variant='h5'>
            {college.attributes.name}
          </CoreTypography>

          <CoreTypography variant='body2' sx={sxStyles.subTitle}>
            {college.attributes.address}, {college.attributes.city}, {college.attributes.state}
          </CoreTypography>
        </CoreBox>

        <Divider />

        <CoreBox sx={sxStyles.boxPadding} onClick={handleMoreDetails}>
          <CoreGrid item container xs={12}>
            <CoreGrid item xs={1.5}>
              <SchoolOutlined color='primary' />
            </CoreGrid>

            <CoreGrid item xs={7.5}>
              <CoreTypography variant='body2'>School length</CoreTypography>
            </CoreGrid>

            <CoreGrid item xs={3} sx={{ textAlign: 'right' }}>
              <CoreTypography variant='body2'>{SCHOOL_LENGTH_LABELS[college.attributes.schoolLength]}</CoreTypography>
            </CoreGrid>
          </CoreGrid>

          <CoreGrid item container xs={12}>
            <CoreGrid item xs={1.5}>
              <PeopleAltOutlined color='primary' />
            </CoreGrid>

            <CoreGrid item xs={7.5}>
              <CoreTypography variant='body2'>Enrolment size</CoreTypography>
            </CoreGrid>

            <CoreGrid item xs={3} sx={{ textAlign: 'right' }}>
              <CoreTypography variant='body2'>
                {'>'}
                {college.attributes.totalStudentsEnrolled}
              </CoreTypography>
            </CoreGrid>
          </CoreGrid>

          <CoreGrid item container xs={12}>
            <CoreGrid item xs={1.5}>
              <AttachMoney color='primary' />
            </CoreGrid>

            <CoreGrid item xs={7.5}>
              <CoreTypography variant='body2'>Cost per year, from</CoreTypography>
            </CoreGrid>

            <CoreGrid item xs={3} sx={{ textAlign: 'right' }}>
              <CoreTypography variant='body2'>{college.attributes.cost}</CoreTypography>
            </CoreGrid>
          </CoreGrid>
        </CoreBox>

        <Divider />

        <CoreBox sx={sxStyles.containerButtons}>
          <CoreGrid item container xs={12}>
            <CoreGrid item xs={6} sx={sxStyles.leftButton}>
              <CoreButton variant='text' onClick={handleToggleFavorite}>
                {saved ? <Favorite /> : <FavoriteBorder />}
              </CoreButton>
            </CoreGrid>

            <CoreGrid item xs={6}>
              <CoreButton variant='text' onClick={handleMoreDetails}>
                More <ChevronRight />
              </CoreButton>
            </CoreGrid>
          </CoreGrid>
        </CoreBox>
      </StyledCard>
    </StyledContainerLink>
  );
};
