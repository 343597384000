import { styled, Theme } from '@mui/material';

export const StyledItem = styled('div')(({ theme }) => ({
  borderWidth: '2px',
  borderStyle: 'solid',
  cursor: 'pointer',
  overflow: 'hidden',
  display: 'flex',
  position: 'relative',
  height: '47px',
  alignItems: 'center',
  fontSize: '24px',
  [theme.breakpoints.up('lg')]: {
    flex: 1,
    height: '50px',
  },
}));

export const StyledColorItem = styled('div')(({ theme }) => ({
  flex: 4,
  height: '100%',
  '&:before': {
    content: '""',
    width: '25px',
    top: '-44px',
    backgroundColor: theme.palette.common.white,
    height: '200%',
    position: 'absolute',
    transform: 'rotate(200deg)',
  },
}));

const baseText = {
  marginLeft: '10px',
  flex: 9,
  zIndex: 2,
  transition: 'font-weight ease 100ms',
  whiteSpace: 'nowrap',
};

export const sxStyles = {
  textH4: (theme: Theme) => ({
    ...baseText,
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  }),
  textH6: (theme: Theme) => ({
    ...baseText,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
  }),
};
