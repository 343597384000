import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const sxStyles = {
  icon: {
    marginBottom: '0.75rem',

    '& > svg:first-of-type': {
      width: '80px',
      height: '80px',
    },
  },
  description: {
    marginTop: '0.5rem',
    fontSize: '0.875rem',
    color: 'rgba(0, 0, 0, 0.6)',
  },
};

export const StyledCardWrap = styled(Link)(({ theme }) => ({
  maxWidth: '386px',
  width: '100%',
  height: '222px',
  padding: '1.5rem 1.25rem',
  borderRadius: '12px',
  boxShadow: `0px 8px 24px -4px ${theme.palette.divider}`,
  transition: 'box-shadow 0.5s',
  color: theme.palette.common.black,
  background: theme.palette.common.white,

  '&:hover': {
    boxShadow: `0px 8px 32px -4px ${theme.palette.divider}`,
    cursor: 'pointer',
  },
}));
