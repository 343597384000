import { OrderType } from '@interfaces';

import { BRIGHTPATH_HOME_URL, DISCOVERY_URL } from './externalRoutes';
import { FLAG_ECP_NEW_ROUTES } from './routes';

export const DEFAULT_DATE_FORMAT = 'yyyy-dd-MM';
export const DEFAULT_DATE_FORMAT_WITH_TIME = 'yyyy-dd-MM hh:mm';

export const SORT_OPTIONS: Record<string, OrderType> = {
  ASC: 'asc',
  DESC: 'desc',
};

export const CURRENT_YEAR = new Date().getFullYear();
export const CURRENT_MONTH = new Date().getMonth();

export const RETRY_LIMIT = 10;

export const DEFAULT_BREADCRUMBS = [
  FLAG_ECP_NEW_ROUTES
    ? { to: `${BRIGHTPATH_HOME_URL}`, text: 'Home', type: 'external' }
    : { to: `${DISCOVERY_URL}/ecp`, text: 'ECP', type: 'external' },
];

export const ALLOWED_GRADES_FOR_COURSE_PLANNER = [6, 7, 8, 9, 10, 11, 12];

export const ALLOWED_GRADES_FOR_ECP = [6, 7, 8, 9, 10, 11, 12];

export const NO_OF_GRADES_TO_SHOW = 4;

export const MAX_GRADE = 12;

export const DEFAULT_GRADE = 9;

export const MIN_SCHOOL_GRADE = 6;

export const MAX_MIDDLE_SCHOOL_GRADE = 8;

export const COURSE_PLAN_PAGE_NAME = 'My Course Plan';

export const GRADE_ROUTE_PREFIX = 'grades';

export const REMOVE_USER_COURSE_MODAL_CONTENT =
  'Are you sure you want to remove this course? Changes will be saved when you move to the next step. Do you want to continue?';

export const ECP_SOURCE = 'ecp';

export const ECP_HASH = '#ecp';
export const EDC_HASH = '#edc';

export const USER_ROLES = {
  ADMIN: 'Admin',
  AUTHOR: 'Author',
  LEARNER: 'Learner',
  PROCTOR: 'Proctor',
  STAFF: 'Staff',
  ALUMNI: 'Alum',
};

export const ALLOWED_USER_ROLES_FOR_LANDING = [
  USER_ROLES.ADMIN,
  USER_ROLES.LEARNER,
  USER_ROLES.PROCTOR,
  USER_ROLES.STAFF,
  USER_ROLES.ALUMNI,
];

export const RESULTS_READY_STATUS = 'results_ready';

export const SCHOOL_LENGTH_LABELS: Record<string, string> = {
  '2-year': '2 YRS',
  '4-year': '4+YRS',
  Graduate: 'GRAD',
  'Vocational/Technical': 'TRADE',
} as const;

export const BREAKPOINTS: Record<string, string> = {
  xSmall: '(min-width: 460px)',
  small: '(min-width: 640px)',
  medium: '(min-width: 768px)',
  large: '(min-width: 1024px)',
  xLarge: '(min-width: 1200px)',
  xxLarge: '(min-width: 1450px)',
} as const;

export const PDF_FILE_PREFIX = 'data:application/pdf;base64,';
