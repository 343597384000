import { DISCOVERY_API_URL } from '@constants/externalRoutes';
import api from '../utils/api';

export interface Career {
  employment: {
    national: {
      openings: number;
    };
  };
  images: {
    url: string;
  }[];
  inserted_at: string;
  investment: {
    education: {
      short_label: string;
    };
  };
  match: {
    ranked_ar_fit: number;
    ranked_ir_fit: number;
    ranked_overall_fit: number;
  };
  onetsoc_code: string;
  salary: {
    national: {
      average: number;
    };
  };
  saved: boolean;
  title: string;
}

const getCareersSavedAndSuggested = async (): Promise<Career[]> => {
  const response = await api(`${DISCOVERY_API_URL}/careers/saved_and_suggested`);

  return response?.data as Career[];
};

const saveFavoriteCareer = async (onetsocCode: string, userId: string): Promise<Career> => {
  const response = await api(`${DISCOVERY_API_URL}/users/${userId}/favorite_careers`, {
    method: 'POST',
    data: {
      career: {
        onetsoc_code: onetsocCode,
        user_id: userId,
      },
    },
  });

  return response?.data as Career;
};

const unsaveFavoriteCareer = async (onetsocCode: string, userId: string): Promise<Career> => {
  const response = await api(`${DISCOVERY_API_URL}/users/${userId}/favorite_careers/${onetsocCode}`, {
    method: 'DELETE',
  });

  return response?.data as Career;
};

const CareerService = { getCareersSavedAndSuggested, saveFavoriteCareer, unsaveFavoriteCareer };

export default CareerService;
