import CareerService from '@services/career';
import { useMutation, useQuery } from '@tanstack/react-query';

export const useGetCareersSavedAndSuggested = () =>
  useQuery({
    queryKey: ['get-careers-saved-and-suggested'],
    queryFn: () => CareerService.getCareersSavedAndSuggested(),
  });

export const useSaveFavoriteCareer = (onetsocCode: string, userId: string) =>
  useMutation({
    mutationKey: ['save-favorite-career'],
    mutationFn: () => CareerService.saveFavoriteCareer(onetsocCode, userId),
  });

export const useUnsaveFavoriteCareer = (onetsocCode: string, userId: string) =>
  useMutation({
    mutationKey: ['unsave-favorite-career'],
    mutationFn: () => CareerService.unsaveFavoriteCareer(onetsocCode, userId),
  });
