import userBioAfterSchoolSchema from '@components/ReflectionForm/schemas/userBioAfterSchool';
import { ReflectionType } from '@interfaces';

export const CAREER_REFLECTION_TYPES: ReflectionType[] = [
  {
    type: 'career',
    name: 'careerMotivation',
    stage: 'highSchool',
    content: '',
    title: 'What excites you most about the careers you chose?',
    placeholder: 'Example: I love to build things, so I think a construction career will be awesome.',
    limit: 500,
    fieldType: 'input',
  },
  {
    type: 'career',
    name: 'careerFollowingSteps',
    stage: 'highSchool',
    content: '',
    title: 'What steps can you take today for your career?',
    placeholder:
      "Example: I can Google these jobs and try to find more information. I could call some people who work in architecture and see if they'll talk to me about what they do. My neighbor is an architect, I want to talk to him and see if he'll let me job shadow with him.",
    limit: 500,
    fieldType: 'input',
  },
];

export const HS_COURSE_PLANNER_REFLECTION_TYPES: ReflectionType[] = [
  {
    type: 'highSchool',
    name: 'highSchoolWantToLearn',
    stage: 'highSchool',
    content: '',
    title: 'What do you want to learn this year?',
    placeholder: 'Example: I want to take the graphic design CTE class and maybe find an internship.',
    limit: 500,
    fieldType: 'input',
  },
  {
    type: 'highSchool',
    name: 'highSchoolDoToday',
    stage: 'highSchool',
    content: '',
    title: 'What can you do today to get ready for the year and meet your goals?',
    placeholder:
      'Example: Talk with the counselor to get her take. Start studying for exams now. Work with my friends who take tests well and get their take on how to do it.',
    limit: 500,
    fieldType: 'input',
  },
];

export const MS_COURSE_PLANNER_REFLECTION_TYPES: ReflectionType[] = [
  {
    type: 'middleSchool',
    name: 'middleSchoolWantToDo',
    stage: 'middleSchool',
    content: '',
    title:
      'What are you looking forward to most this year in middle school? Share a goal or activity that excites you.',
    placeholder:
      "Example: I can't wait to join the school's talent show and share my singing talent with everyone. I've been practicing my favorite songs, and I'm excited to perform in front of my classmates and teachers. It's a great opportunity for me to express myself and showcase my passion for singing.",
    limit: 500,
    fieldType: 'input',
  },
  {
    type: 'middleSchool',
    name: 'middleSchoolWantToAchieve',
    stage: 'middleSchool',
    content: '',
    title: 'What are you looking forward to achieving in high school?',
    placeholder:
      "Example: I can't wait to join the drama club in high school and show off my acting skills on stage. I've always loved performing, and being part of a play will be an amazing experience for me",
    limit: 500,
    fieldType: 'input',
  },
];

export const sharedAfterSchoolOptions: { key: string; value: string }[] = [
  { key: 'twoYearCollege', value: '2-year college' },
  { key: 'fourYearCollege', value: '4-year college' },
  { key: 'technicalSchool', value: 'Enrolling in a technical school' },
  { key: 'joinMilitary', value: 'Joining the military' },
  {
    key: 'workBasedLearningOpportunities',
    value: 'Apprenticeship, internship, or other work-based learning opportunities',
  },
  { key: 'workPartTime', value: 'Working part-time' },
  { key: 'workFullTime', value: 'Working full-time' },
  { key: 'notSure', value: 'Not sure' },
];

export const POST_SECONDARY_REFLECTION_TYPES: ReflectionType[] = [
  {
    type: 'postSecondary',
    name: 'postSecondaryAfterSchoolOptions',
    stage: 'highSchool',
    content: '',
    title: 'What options are you considering after high school? (select all that apply)',
    placeholder: '',
    limit: 0,
    fieldType: 'checkbox',
    options: [...sharedAfterSchoolOptions],
    schema: userBioAfterSchoolSchema,
  },
  {
    type: 'postSecondary',
    name: 'postSecondaryOptions',
    stage: 'highSchool',
    content: '',
    title: 'What can you do to learn more about these options today?',
    placeholder:
      'Example: I will talk with my counselor to see what she recommends. I plan to start studying for my exams sooner and work with my friends that do well in testing scenarios.',
    limit: 500,
    fieldType: 'input',
  },
];

export const MS_APTITUDE_REFLECTION_TYPES: ReflectionType[] = [
  {
    type: 'aptitude',
    name: 'careerTalentsDiscovered',
    stage: 'middleSchool',
    content: '',
    title: 'What aptitude or talent did you discover about yourself that made you go, "Wow!"?',
    placeholder:
      "Example: I was so surprised to find out that I'm really good at solving problems. I love coming up with creative solutions and helping people. It makes me feel great knowing that I can use my problem-solving skills to make a positive impact in the world.",
    limit: 500,
    fieldType: 'input',
  },
  {
    type: 'aptitude',
    name: 'careerTalentsShowUp',
    stage: 'middleSchool',
    content: '',
    title: 'How have you seen your aptitudes show up in your life?',
    placeholder:
      "Example: I've noticed that I'm really good at organizing things. Whether it's keeping my school materials in order or planning fun activities with my friends, my talent for organization always comes in handy. It helps me stay on top of things and be more efficient.",
    limit: 500,
    fieldType: 'input',
  },
];
export const HS_APTITUDE_REFLECTION_TYPES: ReflectionType[] = [
  {
    type: 'aptitude',
    name: 'aptitudeTalentsSurprised',
    stage: 'highSchool',
    content: '',
    title: 'Which of your talents surprised you after seeing your results?',
    placeholder:
      'Example: I was surprised to see I am a blended energizer. I tend to feel more extroverted and vocal. This made me think... I do tend to get tired after a busy day around others.',
    limit: 500,
    fieldType: 'input',
  },
  {
    type: 'aptitude',
    name: 'aptitudeTalentsShowUp',
    stage: 'highSchool',
    content: '',
    title: 'How have you seen your talents show up in your life?',
    placeholder:
      'Example: I’ve realized I do well in areas where I get to brainstorm and collaborate. Working on posters for student government with my friends was exciting. Not only did I come up with a slogan, I came up with an awesome illustration!',
    limit: 500,
    fieldType: 'input',
  },
];

export const MS_INTERESTS_REFLECTION_TYPES: ReflectionType[] = [
  {
    type: 'career',
    name: 'interestsExcitedAbout',
    stage: 'middleSchool',
    content: '',
    title: "What excites you about the areas you're interested in?",
    placeholder:
      'Example: I feel really excited about the possibility of working in construction and being able to use my skills to build amazing things. It would be so fulfilling to see my creations come to life!',
    limit: 500,
    fieldType: 'input',
  },
];

export const MS_CLUSTERS_REFLECTION_TYPES: ReflectionType[] = [
  {
    type: 'career',
    name: 'clustersExpectedGoal',
    stage: 'middleSchool',
    content: '',
    title: 'What can you do to learn more about these options and explore your interests further?',
    placeholder:
      "Example: I can talk to my school counselor and ask about the courses in the Sales & Marketing field. They can tell me about subjects like advertising, market research, or sales strategies. It's fascinating to learn more about these topics, and it will help me make informed decisions about my academic path.",
    limit: 500,
    fieldType: 'input',
  },
];

// TODO: Create a new constant to contain reflection types for another type of reflections
// export const <TYPE>_REFLECTION_TYPES: ReflectionType[] = []
