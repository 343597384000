import { CoreBox } from '@youscience/khaleesi';

import HeadSection from '@components/HeadSection';

import { BREADCRUMBS_PAGE_TEXT } from '@constants/breadcrumbs';

import ToolsSection from './components/ToolsSection';

export const RecommendersHomePage = () => {
  return (
    <CoreBox>
      <HeadSection
        title={BREADCRUMBS_PAGE_TEXT.RECOMMENDER_WORKSPACE}
        subtitle='Discover student resources supporting self-reflection, career exploration, resume building, course planning, and postsecondary education options.'
        breadcrumbsCurrentPage={BREADCRUMBS_PAGE_TEXT.RECOMMENDER_WORKSPACE}
      />

      <ToolsSection />
    </CoreBox>
  );
};
