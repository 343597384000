import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import RecommendersHomePage from '@pages/RecommendersHomePage';

import { PageLoader } from '@components/Layout';
import { USER_ROLES } from '@constants';
import { ROUTES } from '@constants/routes';
import { useAuthStoreContext } from '@context/authStore';

export const Root = () => {
  const navigate = useNavigate();
  const { currentAccess, isLoadingUser, isLoadingUserPreferences } = useAuthStoreContext(); // GUS User

  const isLearner = currentAccess.tenant?.permission?.role === USER_ROLES.LEARNER;

  const isLoadingData = useMemo(
    () => isLoadingUser || isLoadingUserPreferences || !currentAccess.tenant,
    [isLoadingUser, isLoadingUserPreferences, currentAccess],
  );

  useEffect(() => {
    if (isLearner) navigate(ROUTES.LANDING);
  }, [isLearner, isLoadingData]);

  if (isLoadingData) return <PageLoader />;

  if (!isLearner) {
    return <RecommendersHomePage />;
  }

  return null;
};
