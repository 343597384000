import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { CoreBox, CoreButton, CoreTypography } from '@youscience/khaleesi';
import { useRef } from 'react';
import { Navigation, Pagination, Swiper } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { SwiperSlide } from 'swiper/react';
import { NavigationOptions } from 'swiper/types';

import CareerCard from '@components/CareerCard';

import { Career } from '@services/career';

import { DISCOVERY_URL } from '@constants/externalRoutes';

import {
  StyledNavigationButtonsWrap,
  StyledNavigationLink,
  StyledSliderHeader,
  StyledSwiper,
  sxStyles,
} from './Careers.styles';

const SWIPER_DATA = {
  PAGINATION: {
    clickable: true,
  },
  BREAKPOINTS: {
    1180: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 3,
    },
    576: {
      slidesPerView: 3,
    },
  },
};

interface CareersProps {
  careers: Career[];
  userId: string;
}

export const Careers = ({ careers, userId }: CareersProps) => {
  const trimmedCareers = [
    ...careers.filter((career) => career.saved),
    ...careers.filter((career) => !career.saved),
  ].slice(0, 9);
  const navigationPrevRef = useRef<HTMLDivElement>(null);
  const navigationNextRef = useRef<HTMLDivElement>(null);

  const handleBeforeInit = (swiper: Swiper) => {
    // eslint-disable-next-line no-param-reassign
    swiper.params.navigation = {
      ...(swiper.params.navigation as NavigationOptions),
      prevEl: navigationPrevRef.current,
      nextEl: navigationNextRef.current,
    };
  };

  const renderCareers = (careersToRender: Career[]) => {
    if (!careersToRender?.length) {
      return <CoreTypography variant='body1'>{`You haven't selected any potential careers yet.`}</CoreTypography>;
    }

    return (
      <StyledSwiper
        spaceBetween='32px'
        pagination={SWIPER_DATA.PAGINATION}
        navigation={{
          nextEl: navigationNextRef.current,
          prevEl: navigationPrevRef.current,
        }}
        modules={[Pagination, Navigation]}
        breakpoints={SWIPER_DATA.BREAKPOINTS}
        onBeforeInit={handleBeforeInit}
      >
        {careersToRender.map((career) => (
          <SwiperSlide key={career.onetsoc_code}>
            <CareerCard career={career} userId={userId} key={`career-card-${career.onetsoc_code}`} />
          </SwiperSlide>
        ))}
      </StyledSwiper>
    );
  };

  return (
    <CoreBox sx={sxStyles.cardSlider}>
      <StyledSliderHeader>
        <CoreTypography sx={sxStyles.sliderTitle} variant='h4'>
          My career interests
        </CoreTypography>

        <StyledNavigationButtonsWrap>
          <StyledNavigationLink to={`${DISCOVERY_URL}/careers`}>Explore careers</StyledNavigationLink>

          {trimmedCareers.length > 3 && (
            <>
              <div ref={navigationPrevRef}>
                <CoreButton sx={sxStyles.navigationButton} variant='text'>
                  <ChevronLeft />
                </CoreButton>
              </div>

              <div ref={navigationNextRef}>
                <CoreButton sx={sxStyles.navigationButton} variant='text'>
                  <ChevronRight />
                </CoreButton>
              </div>
            </>
          )}
        </StyledNavigationButtonsWrap>
      </StyledSliderHeader>

      {renderCareers(trimmedCareers)}
    </CoreBox>
  );
};
