import { CoreDialogActions } from '@youscience/khaleesi';

import ActionButton from '@components/ActionButton';

import { camelize } from '@utils/helper';
import { sxStyles } from './DialogFooter.styles';

export interface DialogFooterProps {
  goBackButtonTitle?: string;
  goBackActionButtonTitle?: string;
  isDisableNextButton?: boolean;
  goNextButtonTitle?: string;
  goNextActionButtonTitle?: string;

  onGoBack?: () => Promise<void> | void;
  onGoNext?: () => Promise<void> | void;
}

export const DialogFooter = (props: DialogFooterProps) => {
  const {
    goBackButtonTitle,
    goBackActionButtonTitle,
    goNextButtonTitle,
    isDisableNextButton,
    goNextActionButtonTitle,
    onGoBack,
    onGoNext,
  } = props;

  return (
    <CoreDialogActions data-testid='dialogFooter' sx={sxStyles.footer}>
      {onGoBack && goBackButtonTitle ? (
        <ActionButton
          size='large'
          data-testid={`${camelize(goBackButtonTitle)}Btn`}
          variant='outlined'
          onClick={onGoBack}
          busyText={goBackActionButtonTitle}
        >
          {goBackButtonTitle}
        </ActionButton>
      ) : null}

      {onGoNext && goNextButtonTitle ? (
        <ActionButton
          size='large'
          data-testid={`${camelize(goNextButtonTitle)}Btn`}
          onClick={onGoNext}
          disabled={isDisableNextButton}
          busyText={goNextActionButtonTitle}
        >
          {goNextButtonTitle}
        </ActionButton>
      ) : null}
    </CoreDialogActions>
  );
};
