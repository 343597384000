import { AppMenuListItem, AppMenuListItemProps } from '@youscience/brightpath-header';

interface MenuLinksProps {
  options: AppMenuListItemProps[];
}

export const MenuLinks = ({ options }: MenuLinksProps): JSX.Element => {
  const currentPath = window.location.pathname;

  return (
    <>
      {options.map(({ item }) => (
        <AppMenuListItem
          item={item}
          key={item.key}
          onClick={() => {
            window.location.href = item.path;
          }}
          selected={currentPath.indexOf(item.path) > -1}
        />
      ))}
    </>
  );
};
