import { format } from 'date-fns';

export const SHORT_FORMAT_DATE = 'MMM dd, yyyy';
export const parseDate = (value: string): Date | null => {
  if (!value) {
    return null;
  }

  const now = new Date();
  const date = new Date(value);
  const utcDate = new Date(date.getTime() + now.getTimezoneOffset() * 60000);

  return utcDate;
};

export const formatDate = (date: Date, formatParam: string) => format(date, formatParam);
