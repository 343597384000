import { MenuList, styled } from '@mui/material';
import { CoreMenuItem, CoreTypography, coreThemeColors } from '@youscience/khaleesi';

const lightGreyBg = '#F9F9F9';

export const StyledSidebarWrap = styled('div')(({ theme }) => ({
  maxWidth: '284px',
  width: '100%',
  height: 'auto',
  overflow: 'visible',
  visibility: 'visible',

  [theme.breakpoints.down('md')]: {
    maxWidth: '64px',
    marginRight: '1.5rem',
  },

  [theme.breakpoints.down('xs')]: {
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    visibility: 'hidden',
    marginRight: 0,
  },
}));

export const StyledSidebarInner = styled(MenuList)(({ theme }) => ({
  position: 'sticky',
  top: '114px',

  'li.Mui-selected': {
    border: `1px solid ${coreThemeColors.educationConnectionsPrimaryColors.primary.main}`,
    borderRadius: '4px',
    backgroundColor: lightGreyBg,
    color: coreThemeColors.educationConnectionsPrimaryColors.primary.main,

    svg: {
      fill: coreThemeColors.educationConnectionsPrimaryColors.primary.main,
    },

    '&:hover': {
      cursor: 'default',
      backgroundColor: theme.palette.common.white,
    },
  },
}));

export const StyledSidebarItemInner = styled(CoreMenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '0.5rem',
  padding: '15px',
  border: `1px solid ${lightGreyBg}`,
  color: theme.palette.common.black,

  '& > span:first-of-type': {
    display: 'none',
  },

  '& > div:first-of-type': {
    display: 'flex',
  },

  '&:hover': {
    backgroundColor: lightGreyBg,
    color: coreThemeColors.educationConnectionsPrimaryColors.primary.main,
    border: `1px solid ${coreThemeColors.educationConnectionsPrimaryColors.primary.main}`,
    borderRadius: '4px',
    transition: 'border 0.3s color 0.3s',

    svg: {
      fill: coreThemeColors.educationConnectionsPrimaryColors.primary.main,
    },
  },
}));

export const StyledSidebarItemTitle = styled(CoreTypography)(({ theme }) => ({
  height: 'auto',
  overflow: 'visible',
  visibility: 'visible',
  marginLeft: '0.75rem',

  [theme.breakpoints.down('md')]: {
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    visibility: 'hidden',
  },
}));
