import React from 'react';

import { CoreTypography } from '@youscience/khaleesi';
import { sxStyles, StyledItem, StyledColorItem } from './PersonalApproachItem.styles';

interface PersonalApproachItemProps {
  name: string;
  color: string;
  monikerName: string;
  isActive: boolean;
  onItemSelection: (itemName: string) => void;
}

export const PersonalApproachItem = ({
  name,
  color,
  monikerName,
  isActive,
  onItemSelection,
}: PersonalApproachItemProps) => {
  const onItemHover = (event: React.MouseEvent, itemName: string) => {
    event.stopPropagation();

    setTimeout(() => onItemSelection(itemName), 150);
  };

  return (
    <StyledItem style={{ borderColor: color }} onMouseOver={(event) => onItemHover(event, name)}>
      <CoreTypography sx={[sxStyles.textH4, { fontWeight: isActive ? '900' : 'initial' }]} variant='h4'>
        {monikerName}
      </CoreTypography>
      <CoreTypography sx={[sxStyles.textH6, { fontWeight: isActive ? '900' : 'initial' }]} variant='h6'>
        {monikerName}
      </CoreTypography>
      <StyledColorItem sx={{ backgroundColor: color }} />
    </StyledItem>
  );
};
