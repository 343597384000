export const sxStyles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: '0 0 30%',
    justifyContent: 'space-between',
  },
  cardWrap: {
    width: '31%',
  },
};
