import { Feedback, Interest } from '@interfaces';

const TOP_INTERESTS_SIZE = 3;

export const INTEREST_NAMES = ['Artistic', 'Conventional', 'Enterprising', 'Investigative', 'Realistic', 'Social'];

export const getInterests = (feedback: Feedback): Interest[] => {
  let interests: Interest[] = [];

  if (feedback.interests?.length) {
    interests = feedback.interests;
  }

  if ('dimensions' in feedback && feedback.dimensions) {
    interests = Object.entries(feedback.dimensions)
      .map(([acronym, dimension]) => ({
        acronym,
        highlight: dimension.general?.highlight ? dimension.general?.highlight[0]?.content || '' : '',
        name: dimension.general?.name,
        rank: dimension.general?.rank,
      }))
      .filter(({ name }) => INTEREST_NAMES.indexOf(name) > -1);
  }

  if (interests.length) {
    return interests
      .sort(({ rank: rankFirst }, { rank: rankSecond }) => Number(rankFirst) - Number(rankSecond))
      .slice(0, TOP_INTERESTS_SIZE);
  }

  return interests;
};
