import * as yup from 'yup';

const userBioAfterSchoolSchema = yup
  .object({
    twoYearCollege: yup.boolean(),
    fourYearCollege: yup.boolean(),
    technicalSchool: yup.boolean(),
    joinMilitary: yup.boolean(),
    workBasedLearningOpportunities: yup.boolean(),
    workPartTime: yup.boolean(),
    workFullTime: yup.boolean(),
    notSure: yup.boolean(),
  })
  .required();

export default userBioAfterSchoolSchema;
