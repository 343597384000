import { styled } from '@mui/material';
import { coreThemeColors } from '@youscience/khaleesi';

export const sxStyles = {
  titleWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    display: 'flex',
    height: '24px',
    marginRight: '0.5rem',
  },
};

export const StyledCardWrap = styled('a')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  minHeight: '80px',
  padding: '1rem 1.5rem',
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  boxShadow: `0px 8px 24px -4px ${theme.palette.divider}`,
  borderBottomRightRadius: '12px',
  borderTopRightRadius: '12px',
  borderLeft: `5px solid ${coreThemeColors.educationConnectionsPrimaryColors.primary.main}`,
  transition: 'box-shadow 0.5s',

  [theme.breakpoints.down('sm')]: {
    padding: '1rem',
  },

  '&:hover': {
    boxShadow: `0px 8px 32px -4px ${theme.palette.divider}`,
  },
}));
