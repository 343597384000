import { Theme } from '@mui/material';
import { coreThemeColors } from '@youscience/khaleesi';

const dividerColor = 'rgba(0, 0, 0, 0.12)';

export const sxStyles = {
  accordionContainer: {
    borderRadius: '8px !important',
    marginBottom: '20px',
    boxShadow: `0px 8px 24px -4px ${dividerColor}`,
    transition: 'box-shadow 0.5s',

    '&:hover': {
      boxShadow: `0px 8px 32px -4px ${dividerColor}`,
    },

    '&:before': {
      height: '0',
    },
  },
  accordionTitleContainer: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  accordionTitle: {
    width: '200px',
    color: coreThemeColors.educationConnectionsPrimaryColors.primary.main,
    fontWeight: '600',
  },
  accordionTitleDate: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  readReflectionsContainer: {
    margin: '0px 20px 20px 20px',
    padding: '20px',
    borderRadius: '8px',
    border: `solid 1px ${dividerColor}`,
  },
  readReflectionContainer: {
    paddingBottom: '20px',
    marginBottom: '20px',
    borderBottom: `solid 1px ${dividerColor}`,

    '&:last-child': {
      borderBottom: '0px',
      paddingBottom: '0px',
      marginBottom: '0px',
    },
  },
  readReflectionIcon: {
    display: 'block',
    margin: 'auto',
    height: '100%',
    fill: coreThemeColors.educationConnectionsPrimaryColors.primary.main,
  },
  editReflectionsContainer: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  editReflectionsActionsContainer: {
    padding: '20px',
  },
  buttonContainer: (theme: Theme) => ({
    display: 'flex',
    gap: theme.spacing(4),
    flexWrap: 'wrap',

    [theme.breakpoints.down('xs')]: {
      margin: 'auto',
    },
  }),
  button: (theme: Theme) => ({
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(8),

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  }),
  iconButton: {
    marginRight: '8px',
  },
  limitText: (theme: Theme) => ({
    fontSize: theme.typography.body2.fontSize,
  }),
  error: (theme: Theme) => ({
    color: theme.palette.error.main,
  }),
  highlight: (theme: Theme) => ({
    border: `1px solid ${theme.palette.error.main}`,
  }),
  reflectionTitle: {
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.001em',
    textTransform: 'unset',
    marginBottom: '4px',
    wordWrap: 'break-word',
  },
  reflectionContent: {
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.001em',
    textTransform: 'unset',
    color: 'rgba(0, 0, 0, 0.6)',
    wordWrap: 'break-word',
    whiteSpace: 'pre-line',
  },
  bottomGap: {
    marginBottom: '20px',
  },
};
