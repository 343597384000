/* eslint-disable import/no-cycle */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-param-reassign */
import { RETRY_LIMIT } from '@constants';
import AuthService from '@services/auth';
import axios, { AxiosError, AxiosRequestHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

interface RetryAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry: boolean;
}

let retries = 0;

const axiosConfig = {
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

const instance = axios.create(axiosConfig);
const {
  interceptors: { request, response },
} = instance;

const requestInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const contentType =
    config.headers && config.headers['Content-Type'] ? config.headers['Content-Type'] : 'application/json';

  config.headers = {
    ...config.headers,
    Accept: 'application/json',
    'Content-Type': contentType,
  } as AxiosRequestHeaders;

  return config;
};

const responseErrorInterceptor = async (err: AxiosError): Promise<AxiosError> => {
  const originalConfig = err.config as RetryAxiosRequestConfig;

  retries++;

  if (err.response && retries < RETRY_LIMIT) {
    // Access Token was expired (401 from Discovery, 403 from Lambda Authorizer)
    if ((err.response.status === 401 || err.response.status === 403) && !originalConfig._retry) {
      originalConfig._retry = true;

      try {
        await AuthService.authorize();

        retries = 0;
        return await instance(originalConfig);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
  }

  return Promise.reject(err);
};

const responseInterceptor = (res: AxiosResponse): AxiosResponse => res;

const requestErrorInterceptor = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

request.use(requestInterceptor, requestErrorInterceptor);
response.use(responseInterceptor, responseErrorInterceptor);

export default instance;

export const downloadFileResponse = (responseData: BlobPart, options: { filename: string }) => {
  const filename = decodeURI(options.filename);

  const url = window.URL.createObjectURL(new Blob([responseData]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);

  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
};
