import { CoreBox, CoreTypography } from '@youscience/khaleesi';
import { ReactNode } from 'react';

import ToolCard from '@components/ToolCard';

import { ECP_HASH } from '@constants';
import { BREADCRUMBS_PAGE_TEXT } from '@constants/breadcrumbs';
import { EDUCATION_CONNECTIONS_URL } from '@constants/externalRoutes';
import { ROUTES } from '@constants/routes';

import { Breadcrumb } from '@interfaces';

import CommonAppIcon from '@images/tools/common-app-logo.svg?react';
import GraduateCapIcon from '@images/tools/graduate-cap.svg?react';

import { useAuthStoreContext } from '../../../../context/authStore';
import { StyledToolsSection, sxStyles } from './ToolsSection.styles';

enum ToolCardId {
  Landing,
  CommonAppApplicants,
}

export interface ToolCardData {
  id: ToolCardId;
  icon: ReactNode;
  title: string;
  description: string;
  navigateTo?: string;
  navigateState?: { from: Breadcrumb[] };
}

const TOOLS_CARD_DATA: ToolCardData[] = [
  {
    id: ToolCardId.Landing,
    icon: <GraduateCapIcon />,
    title: BREADCRUMBS_PAGE_TEXT.LANDING,
    description: 'Preview student resources for self-reflection, academic planning, and career exploration.',
    navigateTo: ROUTES.LANDING,
    navigateState: { from: [{ to: ROUTES.ROOT, text: BREADCRUMBS_PAGE_TEXT.RECOMMENDER_WORKSPACE, type: 'external' }] },
  },
  {
    id: ToolCardId.CommonAppApplicants,
    icon: <CommonAppIcon />,
    title: 'Common App applicants',
    description: 'Review application requests from students applying to Common App member schools.',
    navigateTo: `${EDUCATION_CONNECTIONS_URL}/recommender-workspace${ECP_HASH}`,
  },
];

export const ToolsSection = () => {
  const { currentAccess } = useAuthStoreContext();
  const isCommonAppEnabled = Boolean(currentAccess.tenant?.productAvailability?.commonApp);

  return (
    <CoreBox sx={sxStyles.toolsBannerBg}>
      <StyledToolsSection>
        <CoreTypography variant='h3'>Explore your tools</CoreTypography>

        <CoreBox sx={sxStyles.toolsCardWrap}>
          {TOOLS_CARD_DATA.map((card) => {
            if (card.id === ToolCardId.CommonAppApplicants && !isCommonAppEnabled) return null;
            return <ToolCard key={card.id} cardData={card} />;
          })}
        </CoreBox>
      </StyledToolsSection>
    </CoreBox>
  );
};
