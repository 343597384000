import { ECP_API_URL } from '@constants/externalRoutes';
import { TenantSettings } from '@interfaces';
import api from '@utils/api';

const get = async (tenantId: string): Promise<TenantSettings> => {
  const response = await api(`${ECP_API_URL}/tenants/${tenantId}/ecp-settings`);

  return response?.data as TenantSettings;
};

const createOrUpdate = async (data: Partial<TenantSettings>): Promise<TenantSettings> => {
  const response = await api(`${ECP_API_URL}/tenants/${data?.tenantId ?? ''}/ecp-settings`, {
    method: 'POST',
    data,
  });

  return response?.data as TenantSettings;
};

const TenantSettingsApi = { get, createOrUpdate };

export default TenantSettingsApi;
