import { CoreBox, CoreTypography } from '@youscience/khaleesi';

import NavigationCard from '@components/NavigationCard';

import { ECP_HASH } from '@constants';
import { EDUCATION_CONNECTIONS_URL } from '@constants/externalRoutes';

import ChecklistRightIcon from '@images/checklist.svg?react';

import { StyledCardsWrap, sxStyles } from './CollegePlannerSection.styles';

export const CollegePlannerSection = () => {
  const plannerNavigationUrl = `${EDUCATION_CONNECTIONS_URL}/education-planner${ECP_HASH}`;
  const applicationsNavigationUrl = `${EDUCATION_CONNECTIONS_URL}/education-planner-applications${ECP_HASH}`;

  return (
    <CoreBox>
      <CoreTypography sx={sxStyles.sectionTitle} variant='h4'>
        College planner
      </CoreTypography>

      <StyledCardsWrap>
        <NavigationCard
          icon={<ChecklistRightIcon />}
          title='Go to College planner'
          navigationUrl={plannerNavigationUrl}
        />

        <NavigationCard
          icon={<ChecklistRightIcon />}
          title='Go to College applications'
          navigationUrl={applicationsNavigationUrl}
        />
      </StyledCardsWrap>
    </CoreBox>
  );
};
