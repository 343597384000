import GusApi from '@api/gus';
import {
  MeWithAccessRecordsApiResponse,
  PreferencesRecord,
  TenantDocument,
  UserDocumentWithAccess,
} from '@youscience/user-service-common';
import IspService from './isp';

const getMe = async (): Promise<MeWithAccessRecordsApiResponse & { impersonatedBy?: unknown }> => {
  const response = await GusApi.getMe();

  return response;
};

const updateMe = async (data: Partial<UserDocumentWithAccess>): Promise<UserDocumentWithAccess> => {
  const response = await GusApi.updateMe(data);

  return response;
};

const getPreferences = async (): Promise<PreferencesRecord> => {
  const response = await GusApi.getPreferences();

  return response;
};

const setPreferences = async (id: string): Promise<PreferencesRecord> => {
  const response = await GusApi.setPreferences(id);

  await IspService.refreshToken();

  return response;
};

const getTenant = async (tenantId: string): Promise<TenantDocument> => {
  const response = await GusApi.getTenant(tenantId);

  return response;
};

const GusService = { getMe, updateMe, getPreferences, setPreferences, getTenant };

export default GusService;
