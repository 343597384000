import { ECP_API_URL } from '@constants/externalRoutes';
import { State } from '@interfaces';
import api from '../utils/api';

const list = async (): Promise<State[]> => {
  const response = await api(`${ECP_API_URL}/states`);

  return response.data as State[];
};

const StateApi = {
  list,
};

export default StateApi;
