import { AxiosError, AxiosResponse } from 'axios';

import TokenService, { refreshToken } from '../services/token';

import { ISP_API_URL } from '../constants/externalRoutes';

import api from '../utils/api';

interface AuthServiceInterface {
  authorize: () => Promise<refreshToken>;
}

const authorize = () => {
  return api(`${ISP_API_URL}/refreshToken`, {
    method: 'POST',
    withCredentials: true,
  })
    .then((response: AxiosResponse) => {
      return response.data as refreshToken;
    })
    .catch((error: AxiosError) => {
      TokenService.removeRefreshToken();

      throw error;
    });
};

const AuthService: AuthServiceInterface = {
  authorize,
};

export default AuthService;
