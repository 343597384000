import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { CoreBox, CoreButton, CoreTypography } from '@youscience/khaleesi';
import { useRef } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';

import { Career } from '@services/career';

import { DISCOVERY_URL } from '@constants/externalRoutes';

import CareerSwiper from '@components/CareerSwiper';
import ConditionWrapper from '@components/ConditionWrapper';
import { StyledNavigationLink, StyledSliderHeader, StyledSwiperNav, sxStyles } from './Careers.styles';

interface CareersProps {
  careers: Career[];
  userId: string;
}

export const Careers = ({ careers, userId }: CareersProps) => {
  const previousButtonRef = useRef<HTMLDivElement>(null);
  const nextButtonRef = useRef<HTMLDivElement>(null);

  const trimmedCareers = [
    ...careers.filter((career) => career.saved),
    ...careers.filter((career) => !career.saved),
  ].slice(0, 9);
  const showNavigationButtons = trimmedCareers.length > 3;

  return (
    <CoreBox sx={sxStyles.cardSlider}>
      <StyledSliderHeader>
        <CoreTypography sx={sxStyles.sliderTitle} variant='h4'>
          My career interests
        </CoreTypography>

        <StyledSwiperNav>
          <StyledNavigationLink to={`${DISCOVERY_URL}/careers`}>Explore careers</StyledNavigationLink>

          <ConditionWrapper condition={showNavigationButtons}>
            <div ref={previousButtonRef}>
              <CoreButton sx={sxStyles.navigationButton} variant='text'>
                <ChevronLeft />
              </CoreButton>
            </div>

            <div ref={nextButtonRef}>
              <CoreButton sx={sxStyles.navigationButton} variant='text'>
                <ChevronRight />
              </CoreButton>
            </div>
          </ConditionWrapper>
        </StyledSwiperNav>
      </StyledSliderHeader>

      <CareerSwiper
        careers={trimmedCareers}
        userId={userId}
        previousButtonRef={previousButtonRef}
        nextButtonRef={nextButtonRef}
      />
    </CoreBox>
  );
};
