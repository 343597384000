type aptitudesType = Record<
  string,
  {
    name: string;
    color: {
      default: string;
      primary: string;
      secondary: string;
      middleSchool?: string;
    };
    linear: boolean;
    middleSchool: boolean;
    highSchool: boolean;
    topTalent: boolean;
    fixedPercentage?: number;
    icon: string;
    monikerPrefix?: string;
  }
>;

export const APTITUDES: aptitudesType = {
  'Associative Memory': {
    name: 'Associative Memory',
    color: {
      default: '#EAA1CE',
      primary: '#C15AB3',
      secondary: '#EFD6EC',
    },
    linear: true,
    middleSchool: false,
    highSchool: false,
    topTalent: false,
    icon: 'ys-icon-link',
  },
  'Hand-Eye Coordination': {
    name: 'Hand-Eye Coordination',
    color: {
      default: '#D38A8C',
      primary: '#EE756C',
      secondary: '#FADCDA',
    },
    linear: true,
    middleSchool: false,
    highSchool: false,
    topTalent: false,
    icon: 'ys-icon-target',
  },
  'Idea Generation': {
    name: 'Idea Generation',
    color: {
      default: '#5AD07D',
      primary: '#ADD632',
      secondary: '#EAF4CC',
    },
    linear: true,
    middleSchool: false,
    highSchool: true,
    topTalent: false,
    icon: 'ys-icon-lightbulb',
  },
  'Inductive Reasoning': {
    name: 'Inductive Reasoning',
    color: {
      default: '#F68E56',
      primary: '#EE756C',
      secondary: '#FADCDA',
    },
    linear: true,
    middleSchool: false,
    highSchool: true,
    topTalent: false,
    icon: 'ys-icon-search',
  },
  'Interpersonal Style': {
    name: 'Interpersonal Style',
    color: {
      default: '#8593FF',
      primary: '#FFB459',
      secondary: '#FFECD5',
    },
    linear: true,
    middleSchool: false,
    highSchool: false,
    topTalent: false,
    icon: 'ys-icon-interpersonal-style',
  },
  'Numerical Computation': {
    name: 'Numerical Computation',
    color: {
      default: '#4AA364',
      primary: '#ADD632',
      secondary: '#EAF4CC',
    },
    linear: true,
    middleSchool: false,
    highSchool: false,
    topTalent: false,
    icon: 'ys-icon-plus-minus',
  },
  'Numerical Reasoning': {
    name: 'Numerical Reasoning',
    color: {
      default: '#FF9585',
      middleSchool: '#19B023',
      primary: '#FFB459',
      secondary: '#FFECD5',
    },
    linear: true,
    middleSchool: true,
    highSchool: true,
    topTalent: true,
    icon: 'ys-icon-puzzle',
  },
  'Pattern Memory': {
    name: 'Pattern Memory',
    color: {
      default: '#85B3FF',
      primary: '#3795DF',
      secondary: '#CDE4F7',
    },
    linear: true,
    middleSchool: false,
    highSchool: false,
    topTalent: false,
    icon: 'ys-icon-nine-dots',
  },
  'Sequential Reasoning': {
    name: 'Sequential Reasoning',
    color: {
      default: '#647F96',
      middleSchool: '#4918C7',
      primary: '#3EC6C2',
      secondary: '#CFF0EF',
    },
    linear: true,
    middleSchool: true,
    highSchool: true,
    topTalent: true,
    icon: 'ys-icon-layers',
  },
  'Spatial Visualization': {
    name: 'Spatial Visualization',
    color: {
      default: '#5677B2',
      middleSchool: '#FF8052',
      primary: '#3795DF',
      secondary: '#CDE4F7',
    },
    linear: true,
    middleSchool: true,
    highSchool: true,
    topTalent: true,
    icon: 'ys-icon-box',
  },
  'Timeframe Orientation': {
    name: 'Timeframe Orientation',
    color: {
      default: '#FFC485',
      middleSchool: '#0350C8',
      primary: '#FF71B6',
      secondary: '#FFDBEC',
    },
    linear: true,
    middleSchool: true,
    highSchool: false,
    topTalent: false,
    fixedPercentage: 20,
    icon: 'ys-icon-clock',
  },
  'Visual Comparison Speed': {
    name: 'Visual Comparison Speed',
    color: {
      default: '#8781BD',
      middleSchool: '#804EFC',
      primary: '#C15AB3',
      secondary: '#EFD6EC',
    },
    linear: true,
    middleSchool: true,
    highSchool: true,
    topTalent: true,
    icon: 'ys-icon-speed',
  },
  'Visual Memory': {
    name: 'Visual Memory',
    color: {
      default: '#EFCE75',
      primary: '#F8E17E',
      secondary: '#FDF7DF',
    },
    linear: true,
    middleSchool: false,
    highSchool: false,
    topTalent: false,
    icon: 'ys-icon-visual-memory',
  },
  Vocabulary: {
    name: 'Vocabulary',
    color: {
      default: '#4A99B7',
      primary: '#3EC6C2',
      secondary: '#CFF0EF',
    },
    linear: true,
    middleSchool: false,
    highSchool: false,
    topTalent: false,
    icon: 'ys-icon-book-1',
    monikerPrefix: 'You have',
  },
  'Work Approach': {
    name: 'Work Approach',
    color: {
      default: '#00A99D',
      primary: '#3795DF',
      secondary: '#CDE4F7',
    },
    linear: true,
    middleSchool: false,
    highSchool: false,
    topTalent: false,
    icon: 'ys-icon-video',
  },
};

export const PERSONAL_APPROACH_DIMENSIONS = ['IE', 'WA', 'FS', 'VO'];
