import { DISCOVERY_API_URL } from '@constants/externalRoutes';
import { AxiosResponse } from 'axios';
import api from '../utils/api';

export interface Cluster {
  id: string;
  name: string;
  description: string;
  image: string;
  isFavorite: boolean;
}

export interface ClusterSuggestion {
  cluster: Cluster;
  raw_aptitude_fit: number;
  raw_interest_fit: number;
}

const orderClusters = (clustersSuggested: ClusterSuggestion[], order: 'aptitude' | 'interest') =>
  clustersSuggested.sort((a: ClusterSuggestion, b: ClusterSuggestion) => {
    const greaterNumber = 1;
    const lowerNumber = -1;

    const aFit: number = a[`raw_${order}_fit`];
    const bFit: number = b[`raw_${order}_fit`];

    const aHasHigherFit = aFit > bFit;
    const equalFitAndAHasLowerName = aFit === bFit && a.cluster.name < b.cluster.name;

    return aHasHigherFit || equalFitAndAHasLowerName ? greaterNumber : lowerNumber;
  });

const getClustersSaved = async (userId: string): Promise<Cluster[]> => {
  const response: AxiosResponse<{
    data: {
      favorites: ClusterSuggestion[];
      suggested: ClusterSuggestion[];
    };
  }> = await api(`${DISCOVERY_API_URL}/users/${userId}/favorite_clusters`);

  const orderedFavorites: Cluster[] = orderClusters(response?.data?.data?.favorites, 'aptitude').map(
    (x: ClusterSuggestion) => ({
      ...x.cluster,
      isFavorite: true,
    }),
  );

  const orderedSuggestions: Cluster[] = orderClusters(response?.data?.data?.suggested, 'aptitude').map(
    (x: ClusterSuggestion) => ({
      ...x.cluster,
      isFavorite: false,
    }),
  );

  return [...orderedFavorites, ...orderedSuggestions];
};

const saveFavoriteCluster = async (clusterId: string, userId: string): Promise<Cluster> => {
  const response: AxiosResponse<Cluster> = await api(`${DISCOVERY_API_URL}/users/${userId}/favorite_clusters`, {
    method: 'POST',
    data: {
      cluster: {
        cluster_id: clusterId,
        user_id: userId,
      },
    },
  });

  return response?.data;
};

const unsaveFavoriteCluster = async (clusterId: string, userId: string): Promise<Cluster> => {
  const response: AxiosResponse<Cluster> = await api(
    `${DISCOVERY_API_URL}/users/${userId}/favorite_clusters/${clusterId}`,
    {
      method: 'DELETE',
      data: {
        cluster: {
          cluster_id: clusterId,
          user_id: userId,
        },
      },
    },
  );

  return response?.data;
};

const ClusterService = { getClustersSaved, saveFavoriteCluster, unsaveFavoriteCluster };

export default ClusterService;
