import { SyntheticEvent, useState } from 'react';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { CoreBox, CoreButton, CoreTooltip } from '@youscience/khaleesi';

import { DISCOVERY_URL } from '@constants/externalRoutes';

import EllipsisTypography from '@components/EllipsisTypography';

import { useSaveFavoriteCluster, useUnsaveFavoriteCluster } from '@hooks/Cluster';

import { Cluster } from '@services/cluster';

import { StyledCard, StyledCardInfo, StyledContainerLink, StyledLogo, sxStyles } from './ClusterCard.styles';

export interface ClusterCardProps {
  cluster: Cluster;
  userId: string;
}

export const ClusterCard = ({ cluster, userId }: ClusterCardProps) => {
  const [saved, setSaved] = useState<boolean>(cluster?.isFavorite);
  const { mutateAsync: saveFavoriteCluster, isLoading: isSaving } = useSaveFavoriteCluster(`${cluster.id}`, userId);
  const { mutateAsync: unsaveFavoriteCluster, isLoading: isUnsaving } = useUnsaveFavoriteCluster(
    `${cluster.id}`,
    userId,
  );

  const handleToggleFavorite = async (event: SyntheticEvent) => {
    event.stopPropagation();

    if (isSaving || isUnsaving) return;

    setSaved((prevState) => !prevState);

    if (saved) {
      await unsaveFavoriteCluster();
    } else {
      await saveFavoriteCluster();
    }
  };

  const handleMoreDetails = () => {
    window.open(`${DISCOVERY_URL}/clusters/${cluster.id}`, '_blank');
  };

  return (
    <StyledContainerLink target='_blank' rel='noreferrer' data-testid='clusterCard' onClick={handleMoreDetails}>
      <StyledCard>
        <CoreBox sx={sxStyles.logoCover}>
          <StyledLogo src={cluster.image} alt={`${cluster.name} logo`} />
        </CoreBox>

        <StyledCardInfo>
          <CoreTooltip title={cluster.name} disableInteractive>
            <EllipsisTypography sx={sxStyles.title} stringCount={2} variant='h5'>
              {cluster.name}
            </EllipsisTypography>
          </CoreTooltip>

          <CoreTooltip title={cluster.description} disableInteractive>
            <EllipsisTypography sx={sxStyles.description} stringCount={2}>
              {cluster.description}
            </EllipsisTypography>
          </CoreTooltip>
        </StyledCardInfo>

        <CoreBox sx={sxStyles.containerButtons}>
          <CoreButton
            sx={sxStyles.favoriteBtn}
            variant='text'
            data-testid='toggleFavorite'
            onClick={handleToggleFavorite}
          >
            {saved ? <Favorite /> : <FavoriteBorder />}
          </CoreButton>
        </CoreBox>
      </StyledCard>
    </StyledContainerLink>
  );
};
