export const defaultSpinnerProps = {
  size: 60,
  thickness: 2,
  disableShrink: true,
};

export const defaultWrapperProps = {
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
};
