/* eslint-disable @typescript-eslint/no-unsafe-call */
import { styled } from '@mui/material/styles';
import { coreThemeColors } from '@youscience/khaleesi';
import { Theme } from '@mui/material';

const CARD_WIDTH = '284px';

export const sxStyles = {
  logoCover: {
    height: '180px',
    width: CARD_WIDTH,
  },
  title: {
    fontSize: '1.5rem',
  },
  description: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  containerButtons: {
    padding: '1rem',
  },
  favoriteBtn: (theme: Theme) => ({
    width: '100%',
    padding: '0.75rem',
    borderRadius: '40px',
    color: coreThemeColors.educationConnectionsPrimaryColors.primary.main,

    '&:hover': {
      background: theme.palette.grey[100],
    },
  }),
};

export const StyledContainerLink = styled('a')(() => ({
  textDecoration: 'none',

  '&:hover': {
    cursor: 'pointer',
  },
}));

export const StyledCard = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '12px',
  color: theme.palette.common.black,
  maxWidth: CARD_WIDTH,
  position: 'relative',
  width: '100%',
  boxShadow: `0px 8px 24px -4px ${theme.palette.divider}`,
  transition: 'box-shadow 0.5s',

  '&:hover': {
    boxShadow: `0px 8px 32px -4px ${theme.palette.divider}`,
  },
}));

export const StyledLogo = styled('img')(() => ({
  aspectRatio: '2/1',
  blockSize: 'auto',
  height: '100%',
  maxInlineSize: '100%',
  objectFit: 'fill',
  borderRadius: '12px 12px 0 0',
}));

export const StyledCardInfo = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minHeight: '144px',
  padding: '1rem',
}));
