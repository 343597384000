import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CoreBox } from '@youscience/khaleesi';

import { Breadcrumbs } from '@components/Breadcrumbs';
import {
  StyledHeadSectionInfo,
  StyledHeadSectionInner,
  StyledHeadSectionSubTitle,
  StyledHeadSectionTitle,
  sxStyles,
} from '@components/HeadSection/HeadSection.styles';

import { DEFAULT_BREADCRUMBS, EDC_HASH } from '@constants';
import { ROUTES } from '@constants/routes.ts';
import { BREADCRUMBS_PAGE_TEXT } from '@constants/breadcrumbs.ts';

import { ECPLocation } from '@interfaces';

export interface HeadSectionProps {
  title: string;
  subtitle?: string;
  breadcrumbsCurrentPage: string;
}

export const HeadSection = ({ title, subtitle, breadcrumbsCurrentPage }: HeadSectionProps) => {
  const location: ECPLocation = useLocation();

  const [initialLocation, setInitialLocation] = useState<ECPLocation | null>(null);

  const recommenderWorkspaceLinks = [
    { to: ROUTES.ROOT, text: BREADCRUMBS_PAGE_TEXT.RECOMMENDER_WORKSPACE, type: 'external' },
  ];

  const cameFromEdcBreadcrumbs =
    initialLocation?.hash === EDC_HASH && location.pathname === ROUTES.LANDING ? recommenderWorkspaceLinks : [];

  const breadcrumbsLinks = [...DEFAULT_BREADCRUMBS, ...(initialLocation?.state?.from ?? cameFromEdcBreadcrumbs)];

  useEffect(() => {
    setInitialLocation(location);
  }, []);

  return (
    <CoreBox sx={sxStyles.headSectionBg}>
      <StyledHeadSectionInner>
        <StyledHeadSectionInfo>
          <Breadcrumbs links={breadcrumbsLinks} currentPage={breadcrumbsCurrentPage} />

          <StyledHeadSectionTitle variant='h1'>{title}</StyledHeadSectionTitle>

          {subtitle && <StyledHeadSectionSubTitle variant='h2'>{subtitle}</StyledHeadSectionSubTitle>}
        </StyledHeadSectionInfo>
      </StyledHeadSectionInner>
    </CoreBox>
  );
};
