import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { Dimension } from '@interfaces';

interface PersonalApproachContextProps {
  selectedItem: Dimension;
  setSelectedItem: Dispatch<SetStateAction<object>>;
}

export const usePersonalApproachState = () => {
  const [selectedItem, setSelectedItem] = useState({});

  return {
    selectedItem,
    setSelectedItem,
  } as PersonalApproachContextProps;
};

export const PersonalApproachContext = createContext<PersonalApproachContextProps>({} as PersonalApproachContextProps);

export function usePersonalApproachContext() {
  return useContext(PersonalApproachContext);
}
