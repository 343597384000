export const sxStyles = {
  sectionTitle: {
    fontSize: '2.25rem',
  },
  subSectionTitle: {
    fontSize: '28px',
    lineHeight: '42px',
    margin: '2.5rem 0 2rem',
  },
};
