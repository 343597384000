import ClusterService from '@services/cluster';
import { useMutation, useQuery } from '@tanstack/react-query';

export const useGetClustersSaved = (userId: string) =>
  useQuery({
    queryKey: ['get-clusters-saved'],
    queryFn: () => ClusterService.getClustersSaved(userId),
    enabled: !!userId,
  });

export const useSaveFavoriteCluster = (clusterName: string, userId: string) =>
  useMutation({
    mutationKey: ['save-favorite-college'],
    mutationFn: () => ClusterService.saveFavoriteCluster(clusterName, userId),
  });

export const useUnsaveFavoriteCluster = (clusterName: string, userId: string) =>
  useMutation({
    mutationKey: ['unsave-favorite-college'],
    mutationFn: () => ClusterService.unsaveFavoriteCluster(clusterName, userId),
  });
