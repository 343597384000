import { SyntheticEvent, useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { CoreSnackbar, CoreSnackbarProps } from '@youscience/khaleesi';

import { NotifyOptions, useNotificationStore } from '@stores/notificationStore';

const Notification = () => {
  const notifications = useNotificationStore((state) => state.notifications, shallow);
  const notificationInfo = useNotificationStore((state) => state.notificationInfo, shallow);
  const setNotificationInfo = useNotificationStore((state) => state.setNotificationInfo);
  const setNotifications = useNotificationStore((state) => state.setNotifications);

  const defaultSnackbar: CoreSnackbarProps = {
    actionHandler: undefined,
    actionText: undefined,
    autoHideDuration: 3000,
    expanded: false,
    horizontal: 'right',
    title: '',
    message: '',
    severity: 'info',
    vertical: 'bottom',
  };

  useEffect(() => {
    if (notifications.length > 0 && !notificationInfo) {
      // Set a new snack when we don't have an active one
      setNotificationInfo({ ...notifications[0] } as NotifyOptions);

      setNotifications([...notifications.slice(1)]);
    } else if (notifications.length > 0 && notificationInfo && notificationInfo.open) {
      // Close an active snack when a new one is added
      setNotificationInfo(undefined);
    }
  }, [notifications, notificationInfo, notificationInfo?.open]);

  // TODO add persistant option until user clicks action text on snackbar
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClose = (event: Event | SyntheticEvent<any, Event>, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotificationInfo({ ...notificationInfo, open: false, message: '' });
  };

  const handleExited = () => {
    setNotificationInfo(undefined);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { key, ...rest } = notificationInfo || {};

  return notificationInfo ? (
    <CoreSnackbar {...defaultSnackbar} {...rest} onClose={handleClose} TransitionProps={{ onExited: handleExited }} />
  ) : null;
};

export default Notification;
