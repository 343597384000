import { Outlet } from 'react-router-dom';

import { Loader } from '@components/Loader';
import { useAuthStoreContext } from '@context/authStore';
import { Footer, Header } from './components';

export const Layout = () => {
  const { isUpdatingUserPreferences } = useAuthStoreContext();

  return isUpdatingUserPreferences ? (
    <Loader />
  ) : (
    <>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};
