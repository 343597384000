import { Card, styled, Theme } from '@mui/material';
import { coreThemeColors } from '@youscience/khaleesi';

export const sxStyles = {
  title: (theme: Theme) => ({
    fontSize: '24px',
    fontWeight: theme.typography.subtitle1.fontWeight,
    textDecoration: 'none',
    textAlign: 'left' as const,
    marginBottom: '10px',
  }),
  subtitle: () => ({
    fontSize: '16px',
    fontWeight: '400',
    textDecoration: 'none',
    textAlign: 'left' as const,
    color: 'rgba(0, 0, 0, 0.6)',
  }),
  image: () => ({
    textAlign: 'left',
    marginBottom: '10px',
  }),
};

export const StyledCard = styled(Card)(({ theme }) => ({
  paddingTop: '10px',
  width: '100%',
  height: '220px',
  borderTop: `5px solid ${coreThemeColors.educationConnectionsPrimaryColors.primary.main}`,
  borderRadius: '0px 0px 12px 12px',
  boxShadow: `0px 8px 24px -4px ${theme.palette.divider}`,
  transition: 'box-shadow 0.5s',

  '&:hover': {
    boxShadow: `0px 8px 32px -4px ${theme.palette.divider}`,
  },
}));
