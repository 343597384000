import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { discoveryTheme } from '@youscience/khaleesi';
import { Suspense, lazy } from 'react';
import TagManager from 'react-gtm-module';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AdminLayout } from '@components/AdminLayout';
import { Layout, PageLoader } from '@components/Layout';
import Notification from '@components/Notification';
import {
  ProtectedAdminRoute,
  ProtectedCoursePlannerRoute,
  ProtectedLandingRoute,
  ProtectedResumeBuilderRoute,
  ProtectedRootRoute,
} from '@components/ProtectedRoute';

import { BRIGHTPATH_ADMIN_URL } from '@constants/externalRoutes';
import { ROUTES } from '@constants/routes';

import { AuthSessionContext, useAuthentication } from '@context/authSession';
import { AuthProvider, AuthStoreContext } from '@context/authStore';

import { usePdfWorker } from '@hooks/usePdfWorker';
import ErrorPage from '@pages/Error';
import LandingPage from '@pages/Landing';
import Root from '@pages/Root';

const tagManagerArgs = {
  gtmId: import.meta.env.VITE_GTM_TRACKING_ID,
};

if (tagManagerArgs.gtmId && tagManagerArgs.gtmId !== '') {
  TagManager.initialize(tagManagerArgs);
}

const WelcomePage = lazy(() => import('./pages/Welcome'));
const ResumeBuilderPage = lazy(() => import('./pages/ResumeBuilder'));
const CoursePlannerPage = lazy(() => import('./pages/CoursePlanner'));
const EcpConfigurePage = lazy(() => import('./pages/Admin/Tenants/Configure'));

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  usePdfWorker();
  const authentication = useAuthentication();
  const authStore = AuthProvider();

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={discoveryTheme}>
        <Suspense fallback={<PageLoader />}>
          <CssBaseline />

          <BrowserRouter>
            <AuthSessionContext.Provider value={authentication}>
              <AuthStoreContext.Provider value={authStore}>
                <Routes>
                  <Route element={<ProtectedRootRoute />} key='protected-root-route'>
                    <Route element={<Layout />} key='layout-route'>
                      <Route path={ROUTES.ROOT} element={<Root key='root-page' />} />
                    </Route>
                  </Route>

                  <Route element={<ProtectedLandingRoute />} key='protected-landing-route'>
                    <Route element={<Layout />} key='layout-route'>
                      <Route path={ROUTES.LANDING} element={<LandingPage key='landing-page' />} />
                    </Route>
                  </Route>

                  <Route element={<ProtectedResumeBuilderRoute />} key='protected-resume-builder-route'>
                    <Route element={<Layout />} key='layout-route'>
                      <Route path={ROUTES.RESUME_BUILDER_WELCOME} element={<WelcomePage key='welcome-page' />} />
                      <Route path='/resume/*' element={<ResumeBuilderPage key='resume-builder-page' />} />
                    </Route>
                  </Route>

                  <Route element={<ProtectedCoursePlannerRoute />} key='protected-course-planner-route'>
                    <Route element={<Layout />} key='layout-route'>
                      <Route path='/course-planner/*' element={<CoursePlannerPage key='course-planner-page' />} />
                    </Route>
                  </Route>

                  <Route
                    element={<ProtectedAdminRoute redirectPath={BRIGHTPATH_ADMIN_URL} />}
                    key='protected-admin-route'
                    path='/admin'
                  >
                    <Route element={<AdminLayout />} key='admin-layout-route'>
                      <Route
                        path='organizations/:tenantId/configure'
                        element={<EcpConfigurePage key='configure-page' />}
                      />
                      <Route path='tenants/:tenantId/course-planner-settings' />
                    </Route>

                    <Route path='*' element={<ErrorPage errorType='route-not-found' />} />
                  </Route>
                </Routes>
              </AuthStoreContext.Provider>
            </AuthSessionContext.Provider>
          </BrowserRouter>

          <Notification />
        </Suspense>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
