import { CoreBox } from '@youscience/khaleesi';
import { useEffect, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ActiveElement, ArcElement, ChartEvent, Chart as ChartJS, Legend, Tooltip } from 'chart.js';

import { AdjustedPercentage } from '@interfaces';
import { AptitudeTalents } from '@interfaces/feedback';

import { AptitudeTalent } from './AptitudeTalent';

ChartJS.register(ArcElement, Tooltip, Legend);

interface DoughnutChartProps {
  aptitudePercentages: AdjustedPercentage[];
  aptitudeTalents: AptitudeTalents[];
  isShowExploreTalentsBtn?: boolean;
}

export const DoughnutChart = ({
  aptitudePercentages,
  aptitudeTalents,
  isShowExploreTalentsBtn,
}: DoughnutChartProps) => {
  const chartRef = useRef(null);

  const [clickedElementIndex, setClickedElementIndex] = useState<number | null>(null);

  useEffect(() => {
    setTimeout(() => {
      const chart = chartRef.current as ChartJS<'doughnut'> | null;

      if (chart) {
        const dataSet = chart.getDatasetMeta(0);

        if (dataSet && dataSet.data[0]) {
          const element = chart.getDatasetMeta(0).data[0];

          element.active = true;
          setClickedElementIndex(0);

          chart.update();
        }
      }
    }, 0);
  }, [chartRef.current]);

  const labels: string[] = [];
  const data: number[] = [];
  const backgroundColor: string[] = [];

  [...aptitudePercentages].forEach((percentage) => {
    labels.push(percentage.name);
    data.push(percentage.percent);
    backgroundColor.push(percentage.otherColors?.primary ?? 'black');
  });

  const chartData = {
    labels,
    datasets: [
      {
        data,
        backgroundColor,
        hoverOffset: 50,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: 'lightgray',
        titleMarginBottom: -2,
        titleColor: 'black',
        callbacks: {
          label: () => '',
        },
      },
    },
    onHover: (event: ChartEvent, elements: ActiveElement[], chart: ChartJS) => {
      if (!event.native) return;

      const target = event.native.target as HTMLElement;

      target.style.cursor = elements.length > 0 ? 'pointer' : 'default';

      const dataSet = chart.getDatasetMeta(0);

      dataSet.data.forEach((element) => {
        const arcElement = element as ArcElement;

        arcElement.active = false;
      });

      if (clickedElementIndex !== null) {
        const dataSet = chart.getDatasetMeta(0);

        dataSet.data[clickedElementIndex].active = true;

        chart.update();
      }
    },
    radius: '90%',
    onClick: (evt: ChartEvent, activeElements: ActiveElement[], chart: ChartJS) => {
      if (activeElements.length > 0) {
        const event = evt.native!;
        const clickedElement = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true)[0];

        const { index } = clickedElement;

        setClickedElementIndex(index);
      }
    },
  };

  const plugins = [
    {
      id: 'custom1',
      afterDraw: (chart: ChartJS<'doughnut'>) => {
        const { ctx } = chart;

        ctx.save();

        const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
        const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.font = '1.5rem Roboto';
        ctx.fillStyle = 'black';
        ctx.fillText('Your aptitudes', centerX, centerY);
        ctx.restore();
      },
    },
  ];

  return (
    <CoreBox sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '1rem' }}>
      <CoreBox
        sx={{
          position: 'relative',
          width: '400px',
          height: '400px',
        }}
      >
        <Doughnut ref={chartRef} data={chartData} options={options} plugins={plugins} />
      </CoreBox>

      {clickedElementIndex !== null ? (
        <AptitudeTalent
          dimensionKey={labels[clickedElementIndex]}
          dimensions={aptitudeTalents}
          isShowExploreBtn={isShowExploreTalentsBtn}
        />
      ) : null}
    </CoreBox>
  );
};
