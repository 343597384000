import { styled } from '@mui/material';
import { coreThemeColors } from '@youscience/khaleesi';
import { Swiper } from 'swiper/react';

export const StyledSwiper = styled(Swiper)(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  with: '896px',
  marginLeft: '-30px',
  marginRight: '-30px',
  padding: '0px 28px',

  '.swiper-pagination-bullet': {
    background: theme.palette.common.white,
    width: '12px',
    height: '12px',
    border: `1px solid ${coreThemeColors.educationConnectionsPrimaryColors.primary.main}`,
    opacity: '1',
  },

  '.swiper-pagination-bullets': {
    bottom: '-6px !important',
  },

  '.swiper-pagination-bullet-active': {
    background: coreThemeColors.educationConnectionsPrimaryColors.primary.main,
  },

  '.swiper-slide': {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',

    '& > div:first-of-type': {
      boxSizing: 'border-box',
      minHeight: '364px',
    },

    [theme.breakpoints.up('md')]: {
      justifyContent: 'start',
    },
  },

  '.swiper-slide-prev > a > div': {
    boxShadow: 'none',
  },

  'div.swiper-slide-next + div + div': {
    '& > a > div': {
      boxShadow: 'none',
    },
  },
}));
