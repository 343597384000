import { Theme } from '@mui/material';

export const sxStyles = {
  sectionTitle: {
    fontSize: '2.25rem',
    lineHeight: '54px',
  },
  subSectionTitle: {
    fontSize: '1.75rem',
    lineHeight: '42px',
    marginTop: '2.5rem',
    marginBottom: '2rem',
  },
  subSectionSubTitle: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  button: (theme: Theme) => ({
    marginTop: theme.spacing(8),
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(8),
  }),
  topTwoAptitude: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '2.5rem',
    margin: '2.5rem 0',
  },
};
