import { CoreTypography } from '@youscience/khaleesi';
import { MutableRefObject, useEffect } from 'react';

import { MS_COURSE_PLANNER_REFLECTION_TYPES } from '@constants/reflectionTypes';
import { ROUTES } from '@constants/routes';

import LearnerReflections from '@components/LearnerReflections';
import NavigationCard from '@components/NavigationCard';

import { useIsLoadingContent } from '@customHooks/useIsLoadingContent';

import ListIcon from '@images/list.svg?react';

import { sxStyles } from './EducationSection.styles';

interface EducationSectionProps {
  isCoursePlannerEnabled: boolean;
  coursePlannerRef: MutableRefObject<HTMLAnchorElement | null>;
  onLoad?: () => void;
}

export const EducationSection = ({ coursePlannerRef, isCoursePlannerEnabled, onLoad }: EducationSectionProps) => {
  const { isLoadingContent, addLoadingContent } = useIsLoadingContent();

  useEffect(() => {
    if (!isLoadingContent && onLoad) {
      onLoad();
    }
  }, [isLoadingContent]);

  return (
    <>
      <CoreTypography sx={sxStyles.sectionTitle} variant='h3'>
        Education
      </CoreTypography>

      {isCoursePlannerEnabled ? (
        <>
          <CoreTypography sx={sxStyles.subSectionTitle} variant='h4' ref={coursePlannerRef}>
            My course planner
          </CoreTypography>

          <NavigationCard icon={<ListIcon />} title='Plan your courses' navigationUrl={ROUTES.COURSE_PLANNER} />
        </>
      ) : null}

      <CoreTypography sx={sxStyles.subSectionTitle} variant='h4'>
        My middle school reflections
      </CoreTypography>

      <LearnerReflections
        reflectionTypes={MS_COURSE_PLANNER_REFLECTION_TYPES}
        onLoad={addLoadingContent('loading-reflections')}
      />
    </>
  );
};
