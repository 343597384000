import { styled } from '@mui/material';
import { CoreDialog, CoreDialogTitle } from '@youscience/khaleesi';

export const sxStyles = {
  content: {
    margin: '0 auto',
    padding: '1rem 5rem 2.5rem',
    paddingTop: '1rem !important',
  },
};

export const StyledDialog = styled(CoreDialog)(() => ({
  '.MuiPaper-root': {
    maxWidth: '720px',
    width: '100%',
    borderRadius: '12px',
  },
}));

export const StyledDialogFormControls = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',

  '& > div:first-of-type': {
    width: 'auto',
  },
}));

export const StyledDialogTitle = styled(CoreDialogTitle)(({ theme }) => ({
  ...theme.typography.h3,
  maxWidth: '540px',
  margin: '0 auto',
  padding: '2.5rem 1.5rem 1rem',
  textAlign: 'center',
}));
