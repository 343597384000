import { FC, ReactElement } from 'react';

import { useAuthStoreContext } from '@context/authStore';
import { USER_ROLES } from '@constants';

import useIsRootAdmin from '@hooks/useIsRootAdmin';

export const AccessWrapper: FC<{
  children: ReactElement;
  allowedRoles: string[];
  restrictOrg?: boolean;
}> = ({ children, allowedRoles, restrictOrg = false }) => {
  const { currentAccess } = useAuthStoreContext();
  const rolesToShow = [USER_ROLES.ADMIN, ...allowedRoles];

  const isRootAdmin = useIsRootAdmin();

  if (restrictOrg) {
    return isRootAdmin ? children : null;
  }

  return rolesToShow.includes(currentAccess.tenant?.permission?.role ?? USER_ROLES.LEARNER) ? children : null;
};
