import { CoreBox, CoreTypography } from '@youscience/khaleesi';

import { ToolCardData } from '@pages/RecommendersHomePage/components/ToolsSection/ToolsSection';

import { StyledCardWrap, sxStyles } from './ToolCard.styles';

interface ToolCardProps {
  cardData: ToolCardData;
}

export const ToolCard = (props: ToolCardProps) => {
  const {
    cardData: { icon, title, description, navigateTo = '', navigateState },
  } = props;

  return (
    <StyledCardWrap to={navigateTo} state={navigateState}>
      <CoreBox sx={sxStyles.icon}>{icon}</CoreBox>

      <CoreBox>
        <CoreTypography variant='h5'>{title}</CoreTypography>

        <CoreTypography sx={sxStyles.description}>{description}</CoreTypography>
      </CoreBox>
    </StyledCardWrap>
  );
};
