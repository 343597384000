import { Link } from '@mui/material';
import { MutableRefObject, useEffect } from 'react';

import { CoreBox, CoreTypography } from '@youscience/khaleesi';

import { HS_COURSE_PLANNER_REFLECTION_TYPES, POST_SECONDARY_REFLECTION_TYPES } from '@constants/reflectionTypes';
import { ROUTES } from '@constants/routes';

import Colleges from '@components/Colleges';
import LearnerReflections from '@components/LearnerReflections';
import { Loader } from '@components/Loader';
import NavigationCard from '@components/NavigationCard';

import { useIsLoadingContent } from '@customHooks/useIsLoadingContent';

import { useGetCollegesSaved } from '@hooks/College';

import ListIcon from '@images/list.svg?react';

import { DISCOVERY_BRAINGAMES_URL } from '@constants/externalRoutes';
import CollegePlannerSection from '../../CollegePlannerSection';

import { sxStyles } from './EducationSection.styles';

interface EducationSectionProps {
  isCoursePlannerEnabled?: boolean;
  coursePlannerRef: MutableRefObject<HTMLAnchorElement | null>;
  collegePlannerRef: MutableRefObject<HTMLAnchorElement | null>;
  userWithResultsReady: boolean;
  onLoad?: () => void;
}

export const EducationSection = (props: EducationSectionProps) => {
  const { isCoursePlannerEnabled, coursePlannerRef, collegePlannerRef, userWithResultsReady, onLoad } = props;

  const { isLoading: isLoadingColleges, data: colleges = [] } = useGetCollegesSaved();

  const { isLoadingContent, addLoadingContent } = useIsLoadingContent();

  useEffect(() => addLoadingContent('loading-colleges')(isLoadingColleges), [isLoadingColleges]);

  useEffect(() => {
    if (!isLoadingContent && onLoad) {
      onLoad();
    }
  }, [isLoadingContent]);

  return (
    <CoreBox>
      <CoreTypography variant='h3' sx={sxStyles.sectionTitle}>
        Education
      </CoreTypography>
      {!userWithResultsReady ? (
        <CoreTypography sx={sxStyles.subSectionSubTitle}>
          Unlock this entire section by visiting Aptitude and Career Discovery and completing your{' '}
          <Link href={DISCOVERY_BRAINGAMES_URL} color='primary' sx={{ fontWeight: 'bold' }}>
            Brain games.
          </Link>{' '}
          You&apos;ll be able to plan and reflect on your goals for high school and beyond.
        </CoreTypography>
      ) : null}
      {isCoursePlannerEnabled ? (
        <>
          <CoreTypography sx={sxStyles.subSectionTitle} variant='h4' ref={coursePlannerRef}>
            High school course planner
          </CoreTypography>

          <NavigationCard icon={<ListIcon />} title='Plan your courses' navigationUrl={ROUTES.COURSE_PLANNER} />
        </>
      ) : null}
      <CoreTypography sx={sxStyles.subSectionTitle} variant='h4'>
        My high school reflections
      </CoreTypography>
      <LearnerReflections
        reflectionTypes={HS_COURSE_PLANNER_REFLECTION_TYPES}
        onLoad={addLoadingContent('highschool-reflections')}
      />
      <CoreBox ref={collegePlannerRef}>
        <CollegePlannerSection />
      </CoreBox>
      {userWithResultsReady ? (
        <CoreBox sx={sxStyles.collegesContainer}>
          {isLoadingColleges ? <Loader /> : <Colleges colleges={colleges} />}
        </CoreBox>
      ) : null}
      <CoreTypography sx={sxStyles.subSectionTitle} variant='h4'>
        My postsecondary reflections
      </CoreTypography>
      <LearnerReflections
        reflectionTypes={POST_SECONDARY_REFLECTION_TYPES}
        onLoad={addLoadingContent('postsecondary-reflections')}
      />
    </CoreBox>
  );
};
