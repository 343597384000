import { Link } from 'react-router-dom';
import { styled, Theme } from '@mui/material';
import { Swiper } from 'swiper/react';
import { coreThemeColors } from '@youscience/khaleesi';

export const sxStyles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: '0 0 30%',
    justifyContent: 'space-between',
  },
  cardSlider: {
    maxWidth: '1320px',
    margin: '0 auto',
  },
  sliderTitle: {
    fontSize: '28px',
  },
  navigationButton: (theme: Theme) => ({
    minWidth: '48px',
    padding: '0.75rem',
    borderRadius: '40px',
    color: coreThemeColors.educationConnectionsPrimaryColors.primary.main,

    '&:hover': {
      background: theme.palette.grey[200],
    },
  }),
};

export const StyledSwiper = styled(Swiper)(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  with: '896px',
  marginLeft: '-30px',
  marginRight: '-30px',
  padding: '0px 30px',

  '.swiper-pagination-bullet': {
    background: theme.palette.common.white,
    width: '12px',
    height: '12px',
    border: `1px solid ${theme.palette.primary.dark}`,
    opacity: '1',
  },

  '.swiper-pagination-bullets': {
    bottom: '-7px !important',
  },

  '.swiper-pagination-bullet-active': {
    background: theme.palette.primary.dark,
  },

  '.swiper-slide': {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',

    '& > div:first-of-type': {
      boxSizing: 'border-box',
      minHeight: '364px',
    },

    [theme.breakpoints.up('md')]: {
      justifyContent: 'start',
    },
  },

  '.swiper-slide-prev > a > div': {
    boxShadow: 'none',
  },

  'div.swiper-slide-next + div + div': {
    '& > a > div': {
      boxShadow: 'none',
    },
  },
}));

export const StyledNavigationButtonsWrap = styled('div')(({ theme }) => ({
  display: 'flex',
  maxHeight: '48px',
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  visibility: 'hidden',

  [theme.breakpoints.up('md')]: {
    overflow: 'visible',
    visibility: 'visible',
    width: 'auto',
    height: 'auto',
  },
}));

export const StyledNavigationLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '210px',
  maxHeight: '48px',
  fontWeight: 600,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(2, 4),
  borderRadius: theme.spacing(8),

  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export const StyledSliderHeader = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gridColumnGap: '2rem',
  alignItems: 'center',
  marginBottom: '2rem',
}));
