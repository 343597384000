import { useEffect, useState } from 'react';

export const useIsLoadingContent = () => {
  const [isLoadingContent, setIsLoadingContent] = useState(true);
  const [loadingContent, setLoadingContent] = useState<Record<string, boolean>>({});

  const addLoadingContent = (name: string): ((value?: boolean) => void) => {
    if (loadingContent[name] === undefined) {
      setLoadingContent((prev) => ({ ...prev, [name]: true }));
    }

    return (value?: boolean) => {
      setLoadingContent((prev) => ({ ...prev, [name]: value ?? false }));
    };
  };

  useEffect(() => {
    if (Object.keys(loadingContent).length === 0) return;

    const isLoading = Object.values(loadingContent).some((loading) => loading);

    if (isLoading !== isLoadingContent) setIsLoadingContent(isLoading);
  }, [loadingContent]);

  return { isLoadingContent, addLoadingContent };
};
