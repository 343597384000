import { Footer, Header } from '@components/Layout/components';
import { BRIGHTPATH_HOME_URL } from '@constants/externalRoutes';
import { CoreBox, CoreButton, CoreTypography } from '@youscience/khaleesi';
import { sxStyles } from './Error.styles';
import { ErrorMessage, ErrorPageProps, errorMessages } from './constants';

export const ErrorPage = ({ errorType }: ErrorPageProps) => {
  const errorMessage: ErrorMessage = errorMessages[errorType];

  const redirectToBPHome = () => {
    window.location.href = `${BRIGHTPATH_HOME_URL}/home`;
  };

  return (
    <>
      <Header />
      <CoreBox sx={sxStyles.container}>
        <CoreBox sx={sxStyles.content}>
          <CoreTypography sx={sxStyles.error}>{errorMessage.error}</CoreTypography>
          <CoreTypography sx={sxStyles.mainMessage}>{errorMessage.mainMessage}</CoreTypography>
          <CoreTypography sx={sxStyles.subMessage}>{errorMessage.subMessage}</CoreTypography>
          <CoreButton sx={sxStyles.button} onClick={redirectToBPHome}>
            Take me home
          </CoreButton>
        </CoreBox>
      </CoreBox>
      <Footer />
    </>
  );
};
