import { MeWithAccessRecordsApiResponse } from '@youscience/user-service-common';

export const isECPEnabled = (settingsRefactorEnabled: boolean, userData: MeWithAccessRecordsApiResponse) => {
  let isEcpEnabled = false;

  const productAvailability = userData.access.some((access) => access.tenant.productAvailability?.ecp);

  const conditionSettingsRefactorEnabled = settingsRefactorEnabled && productAvailability;
  const conditionSettingsRefactorDisabled = !settingsRefactorEnabled;

  if (conditionSettingsRefactorEnabled || conditionSettingsRefactorDisabled) {
    isEcpEnabled = true;
  }

  return isEcpEnabled;
};
