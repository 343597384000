import { CoreBox } from '@youscience/khaleesi';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Breadcrumbs } from '@components/Breadcrumbs';
import {
  StyledHeadSectionInfo,
  StyledHeadSectionInner,
  StyledHeadSectionSubTitle,
  StyledHeadSectionTitle,
  sxStyles,
} from '@components/HeadSection/HeadSection.styles';

import { DEFAULT_BREADCRUMBS } from '@constants';
import { ECPLocation } from '@interfaces';

export interface HeadSectionProps {
  title: string;
  subtitle?: string;
  breadcrumbsCurrentPage: string;
}

export const HeadSection = ({ title, subtitle, breadcrumbsCurrentPage }: HeadSectionProps) => {
  const location: ECPLocation = useLocation();

  const [initialLocation, setInitialLocation] = useState<ECPLocation | null>(null);

  const breadcrumbsLinks = [...DEFAULT_BREADCRUMBS, ...(initialLocation?.state?.from || [])];

  useEffect(() => {
    setInitialLocation(location);
  }, []);

  return (
    <CoreBox sx={sxStyles.headSectionBg}>
      <StyledHeadSectionInner>
        <StyledHeadSectionInfo>
          <Breadcrumbs links={breadcrumbsLinks} currentPage={breadcrumbsCurrentPage} />

          <StyledHeadSectionTitle variant='h1'>{title}</StyledHeadSectionTitle>

          {subtitle && <StyledHeadSectionSubTitle variant='h2'>{subtitle}</StyledHeadSectionSubTitle>}
        </StyledHeadSectionInfo>
      </StyledHeadSectionInner>
    </CoreBox>
  );
};
