import { GRADE_ROUTE_PREFIX, MAX_GRADE, NO_OF_GRADES_TO_SHOW } from '@constants';
import { Course, CreditsByGrade, UserCourse, UserCoursePlan } from '@interfaces';

export type StepperMetaData = Record<number, { label: string; path: string; grade: number; field: string }>;

const getGradesInCoursePlan = (): number[] => {
  const leastGradeRequiredToShow = MAX_GRADE - NO_OF_GRADES_TO_SHOW + 1;

  return Array.from(Array(NO_OF_GRADES_TO_SHOW), (_, i) => leastGradeRequiredToShow + i);
};

const generateGradeStepperMetaData = (gradesToShow: number[]): StepperMetaData => {
  const gradesStepperMetaData = {} as StepperMetaData;

  gradesToShow.forEach((grade, index) => {
    gradesStepperMetaData[index + 1] = {
      grade,
      label: `Grade ${grade}`,
      path: `${GRADE_ROUTE_PREFIX}/${grade}`,
      field: `grade-${grade}`,
    };
  });
  return gradesStepperMetaData;
};

const formatGradeToDisplay = (grade: number | undefined) => (grade ? `${grade}th` : '-');

const getStepNumberFromPath = (steps: StepperMetaData, path: string | undefined) => {
  // eslint-disable-next-line no-useless-escape
  if (path?.includes(`${GRADE_ROUTE_PREFIX}\/`)) {
    const stepsKeys = Object.keys(steps);
    const reqPath = stepsKeys.find((step) => steps[Number(step)]?.path === path);

    if (reqPath) return reqPath;

    return stepsKeys.length > 0 ? stepsKeys[0] : null;
  }
  return null;
};

export const parseJSONData = (data: string): string => {
  try {
    const parsedData = JSON.parse(data) as string;

    return parsedData;
  } catch (err) {
    return data;
  }
};

const getTotalCredits = (creditsByGrade: CreditsByGrade | undefined) => {
  const grades = Object.keys(creditsByGrade || {});

  return creditsByGrade && grades.length
    ? parseFloat(grades.reduce((acc, key) => acc + creditsByGrade[key], 0).toFixed(2))
    : 'N/A';
};

const filterCoursesByGrade = (courses: Course[] | undefined, grade: number): Course[] =>
  courses?.filter(({ grade: courseGrade }) => Number(courseGrade) === Number(grade)) || [];

const getAchievedCredits = (userCourses: UserCourse[] | undefined) => {
  return userCourses
    ? parseFloat(userCourses.reduce((acc, { credits }) => (credits ? acc + Number(credits) : acc), 0).toFixed(2))
    : 0;
};

const isCoursePlanCompleted = (coursePlan: UserCoursePlan, gradesInCoursePlan: number[]): boolean => {
  const { statusByGrade } = coursePlan;

  if (statusByGrade) {
    return !gradesInCoursePlan.some(
      (grade: number) =>
        !Object.keys(statusByGrade).find((key) => key === grade.toString()) || statusByGrade[grade] == null,
    );
  }
  return false;
};

export {
  filterCoursesByGrade,
  formatGradeToDisplay,
  generateGradeStepperMetaData,
  getAchievedCredits,
  getGradesInCoursePlan,
  getStepNumberFromPath,
  getTotalCredits,
  isCoursePlanCompleted,
};
