import IconArtistic from '@images/interests/artistic.svg?react';
import IconAssociativeMemory from '@images/interests/associative-memory.svg?react';
import IconConventional from '@images/interests/conventional.svg?react';
import IconEnterprising from '@images/interests/enterprising.svg?react';
import IconHandEyeCoordination from '@images/interests/hand-eye-coordination.svg?react';
import IconIdeaGeneration from '@images/interests/idea-generation.svg?react';
import IconInductiveReasoning from '@images/interests/inductive-reasoning.svg?react';
import IconInterpersonalStyle from '@images/interests/interpersonal-style.svg?react';
import IconInvestigative from '@images/interests/investigative.svg?react';
import IconNumericalComputation from '@images/interests/numerical-computation.svg?react';
import IconNumericalReasoning from '@images/interests/numerical-reasoning.svg?react';
import IconPatternMemory from '@images/interests/pattern-memory.svg?react';
import IconRealistic from '@images/interests/realistic.svg?react';
import IconSequentialReasoning from '@images/interests/sequential-reasoning.svg?react';
import IconSocial from '@images/interests/social.svg?react';
import IconSpatialVisualization from '@images/interests/spatial-visualization.svg?react';
import IconTimeframeOrientation from '@images/interests/timeframe-orientation.svg?react';
import IconVisualComparisonSpeed from '@images/interests/visual-comparison-speed.svg?react';
import IconVisualMemory from '@images/interests/visual-memory.svg?react';
import IconVocabulary from '@images/interests/vocabulary.svg?react';
import IconWorkApproach from '@images/interests/work-approach.svg?react';

interface IconsProps {
  acronym: string;
}

export const InterestIcon = (props: IconsProps) => {
  const INTEREST_ICONS: Record<string, JSX.Element> = {
    A: <IconArtistic />,
    AM: <IconAssociativeMemory />,
    AR: <IconSequentialReasoning />,
    C: <IconConventional />,
    CL: <IconVisualComparisonSpeed />,
    DM: <IconPatternMemory />,
    E: <IconEnterprising />,
    FS: <IconTimeframeOrientation />,
    I: <IconInvestigative />,
    IE: <IconInterpersonalStyle />,
    IG: <IconIdeaGeneration />,
    IR: <IconInductiveReasoning />,
    NC: <IconNumericalComputation />,
    NR: <IconNumericalReasoning />,
    PF: <IconSpatialVisualization />,
    PM: <IconHandEyeCoordination />,
    R: <IconRealistic />,
    S: <IconSocial />,
    VM: <IconVisualMemory />,
    VO: <IconVocabulary />,
    WA: <IconWorkApproach />,
  } as const;

  return INTEREST_ICONS[props.acronym.toUpperCase()];
};
