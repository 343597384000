export const FLAG_ECP_NEW_ROUTES = import.meta.env.VITE_FLAG_ECP_NEW_ROUTES === 'true';

export const ROUTES = {
  ROOT: '/',
  LANDING: FLAG_ECP_NEW_ROUTES ? '/educationAndCareerPlan' : '/landing',
  RESUME_BUILDER_WELCOME: FLAG_ECP_NEW_ROUTES ? '/resume-builder' : '/',
  RESUME: '/resume',
  COURSE_PLANNER: '/course-planner',
  COURSE_PLANNER_SETTINGS: '/admin/tenants/course-planner-settings',
  COURSE_PLANNER_PREVIEW: '/course-planner/preview',
};
