import { createContext, useContext, useEffect, useState } from 'react';

import GusService from '@services/gus';
import {
  AccessDocument,
  IdentityRecord,
  MeWithAccessRecordsApiResponse,
  ProductData,
  UserDocumentWithAccess,
} from '@youscience/user-service-common';
import { LEARNER_ROLE } from '../constants';

interface AuthStoreProps {
  accessDocumentId: string;
  userData: MeWithAccessRecordsApiResponse;
  currentAccess: Partial<MeAccessRecord>;
  getUserPreferences: () => Promise<void>;
  getMe: () => Promise<MeWithAccessRecordsApiResponse>;
  setUserPreferences: (id: string) => Promise<void>;
  updateMe: (data: Partial<UserDocumentWithAccess>) => Promise<void>;
  isLoadingUser: boolean;
  isLoadingUserPreferences: boolean;
  isUpdatingUserPreferences: boolean;
  isImpersonated: boolean;
}

interface AuthStore {
  userData: MeWithAccessRecordsApiResponse;
  currentAccess: Partial<AccessDocument>;
  accessDocumentId: string;
  getUserPreferences: () => Promise<void>;
  setUserPreferences: (id: string) => Promise<void>;
  getMe: () => Promise<MeWithAccessRecordsApiResponse>;
  updateMe: (data: Partial<UserDocumentWithAccess>) => Promise<void>;
  isImpersonated: boolean;
  isLoadingUser: boolean;
  isLoadingUserPreferences: boolean;
  isUpdatingUserPreferences: boolean;
}

type ProductKey = ProductData['product'];
export type MeAccessRecord = MeWithAccessRecordsApiResponse['access'][0];

const findAccess = (accessList: MeAccessRecord[], productKey: ProductKey): MeAccessRecord | undefined => {
  return accessList?.find(({ tenant }) => tenant.productAvailability?.[productKey]);
};

const getFirstAccessWithEcp = (accessList: MeAccessRecord[]) => {
  const accessWithEcp = findAccess(accessList, 'ecp');

  let accessId;

  if (accessWithEcp) {
    accessId = accessWithEcp._id as unknown as string;
  } else if (accessList?.length) {
    accessId = accessList[0]._id as unknown as string;
  }

  return accessId;
};

export const AuthProvider = (): AuthStore => {
  const [userData, setUserData] = useState<MeWithAccessRecordsApiResponse>({
    access: [] as MeAccessRecord[],
    userId: '',
    fullName: '',
    identities: [] as IdentityRecord[],
  } as MeWithAccessRecordsApiResponse);
  const [currentAccess, setCurrentAccess] = useState<Partial<MeWithAccessRecordsApiResponse>>(
    {} as Partial<MeWithAccessRecordsApiResponse>,
  );
  const [accessDocumentId, setAccessDocumentId] = useState<string>('');
  const [isImpersonated, setIsImpersonated] = useState<boolean>(false);

  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(false);
  const [isLoadingUserPreferences, setIsLoadingUserPreferences] = useState<boolean>(false);
  const [isUpdatingUserPreferences, setIsUpdatingUserPreferences] = useState<boolean>(false);

  const getMe = async () => {
    setIsLoadingUser(true);

    try {
      const response = await GusService.getMe();

      if (response) {
        setUserData((prevData) => ({ ...prevData, ...response }));
        setIsImpersonated(!!response.impersonatedBy);
      }

      setIsLoadingUser(false);
    } catch (error) {
      setIsLoadingUser(false);
    }

    return userData;
  };

  const updateMe = async (data: Partial<UserDocumentWithAccess>) => {
    await GusService.updateMe(data);

    await getMe();
  };

  const updateCurrentAccess = (currentAccessId: string) => {
    setAccessDocumentId(currentAccessId);

    const currentAccess = userData.access.find(
      ({ _id }) => (_id as unknown as string) === currentAccessId,
    ) as AccessDocument;

    setCurrentAccess(currentAccess);
  };

  const setUserPreferences = async (id: string) => {
    setIsUpdatingUserPreferences(true);

    try {
      const response = await GusService.setPreferences(id);

      if (response.currentAccess) {
        const currentAccessId = response.currentAccess.toString();

        updateCurrentAccess(currentAccessId);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        await getUserPreferences();
      }

      setIsUpdatingUserPreferences(false);
    } catch (error) {
      setIsUpdatingUserPreferences(false);
    }
  };

  const handleCurrentAccess = async (currentAccessId: string, accessList: MeAccessRecord[]) => {
    if (!accessList) return;

    const currentAccess = accessList?.find(({ _id }) => (_id as unknown as string) === currentAccessId)!;
    const isLearnerForAllTenants = accessList.every(({ tenant }) => tenant.permission?.role === LEARNER_ROLE);

    if (currentAccess) {
      const isEcpEnabled = currentAccess.tenant.productAvailability?.ecp;

      if (!isEcpEnabled && isLearnerForAllTenants) {
        const firstEnabledAccess = findAccess(accessList, 'ecp');

        if (firstEnabledAccess) {
          await setUserPreferences(firstEnabledAccess._id as unknown as string);

          return;
        }
      }

      updateCurrentAccess(currentAccessId);
    } else {
      const id = getFirstAccessWithEcp(accessList);

      if (id) {
        await setUserPreferences(id);
      }
    }
  };

  async function getUserPreferences() {
    setIsLoadingUserPreferences(true);

    const response = await GusService.getPreferences();

    if (response.currentAccess) {
      const currentAccessId = response.currentAccess.toString();

      await handleCurrentAccess(currentAccessId, userData.access);
    } else if (userData.access?.length) {
      const id = getFirstAccessWithEcp(userData.access);

      if (id) {
        await setUserPreferences(id);
      }
    }

    setIsLoadingUserPreferences(false);
  }

  useEffect(() => {
    void getMe();
  }, []);

  useEffect(() => {
    void getUserPreferences();
  }, [userData.userId]);

  return {
    userData,
    currentAccess,
    accessDocumentId,
    isImpersonated,
    getUserPreferences,
    setUserPreferences,
    getMe,
    updateMe,
    isLoadingUser,
    isLoadingUserPreferences,
    isUpdatingUserPreferences,
  };
};

export const AuthStoreContext = createContext<AuthStoreProps>({} as AuthStoreProps);

export const useAuthStoreContext = () => useContext(AuthStoreContext);
