import { styled, Theme } from '@mui/material';
import { coreThemeColors } from '@youscience/khaleesi';
import { Link } from 'react-router-dom';

export const sxStyles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: '0 0 30%',
    justifyContent: 'space-between',
  },
  cardSlider: {
    maxWidth: '1320px',
    margin: '0 auto',
  },
  navigationButton: (theme: Theme) => ({
    minWidth: '48px',
    padding: '0.75rem',
    borderRadius: '40px',
    color: coreThemeColors.educationConnectionsPrimaryColors.primary.main,

    '&:hover': {
      background: theme.palette.grey[200],
    },
  }),
  sliderTitle: {
    fontSize: '1.75rem',
  },
};

export const StyledSwiperNav = styled('div')(({ theme }) => ({
  display: 'flex',
  maxHeight: '48px',
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  visibility: 'hidden',

  [theme.breakpoints.up('md')]: {
    overflow: 'visible',
    visibility: 'visible',
    width: 'auto',
    height: 'auto',
  },
}));

export const StyledNavigationLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '210px',
  maxHeight: '48px',
  fontWeight: 600,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(2, 4),
  borderRadius: theme.spacing(8),

  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export const StyledSliderHeader = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gridColumnGap: '2rem',
  alignItems: 'center',
  marginBottom: '2rem',
}));
