import { FC, ReactElement } from 'react';

import { useAuthStoreContext } from '@context/authStore';

import useIsRootAdmin from '@hooks/useIsRootAdmin';

import { ALLOWED_ROLES } from './constants';

export const AccessWrapper: FC<{
  children: ReactElement;
  allowedRoles: string[];
  restrictOrg?: boolean;
}> = ({ children, allowedRoles, restrictOrg = false }) => {
  const { currentAccess } = useAuthStoreContext();
  const rolesToShow = [...ALLOWED_ROLES, ...allowedRoles];

  const isRootAdmin = useIsRootAdmin();

  if (restrictOrg) {
    return isRootAdmin ? children : null;
  }

  return rolesToShow.includes(currentAccess.tenant?.permission?.role ?? 'Learner') ? children : null;
};
