import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { BRIGHTPATH_HOME_URL } from '@constants/externalRoutes';
import { useAuthSessionContext } from '@context/authSession';

import { PageLoader } from '@components/Layout';
import { ALLOWED_USER_ROLES_FOR_LANDING } from '@constants';
import { useAuthStoreContext } from '@context/authStore';
import { useEcpEnabled } from '@hooks/EcpEnabled';
import { useCurrentUserRole } from '@hooks/Users';

interface ProtectedCoursePlannerRouteProps {
  redirectPath?: string;
  children?: ReactElement;
}

export const ProtectedCoursePlannerRoute = ({
  redirectPath = BRIGHTPATH_HOME_URL,
  children,
}: ProtectedCoursePlannerRouteProps) => {
  const { isAuthenticated, isLoading: isLoadingUser } = useAuthSessionContext();
  const { isLoadingUser: isLoadingGusUser } = useAuthStoreContext();
  const { ecpEnabled, isLoadingData: isLoadingEcpData, tenantSettings } = useEcpEnabled();
  const userRole = useCurrentUserRole();
  const [preSettingsReady, setPreSettingsReady] = useState<boolean>(false);

  const hasAccessToCoursePlanner = useMemo(
    () => ecpEnabled && tenantSettings?.coursePlannerEnabled,
    [ecpEnabled, tenantSettings],
  );

  const isLoadingPage = useMemo(
    () => isLoadingUser || isLoadingGusUser || isLoadingEcpData,
    [isLoadingUser, isLoadingGusUser, isLoadingEcpData],
  );

  useEffect(() => {
    if (ecpEnabled !== null && userRole !== '') setPreSettingsReady(true);
  }, [ecpEnabled, userRole]);

  if (isLoadingPage || !preSettingsReady) return <PageLoader />;

  if (isAuthenticated && hasAccessToCoursePlanner && ALLOWED_USER_ROLES_FOR_LANDING.includes(userRole)) {
    return children || <Outlet />;
  }

  window.location.href = redirectPath;
  return null;
};
