import { CardContent, Typography } from '@mui/material';
import { CoreBox } from '@youscience/khaleesi';

import InterestIcon from '@components/InterestIcon';
import { StyledCard, sxStyles } from './AptitudeCard.styles';

interface AptitudeCardProps {
  acronym: string;
  name: string;
  highlight: string;
}

export const AptitudeCard = ({ acronym, name, highlight }: AptitudeCardProps) => {
  return (
    <StyledCard>
      <CardContent>
        <CoreBox sx={sxStyles.image}>
          <InterestIcon acronym={acronym} />
        </CoreBox>

        <CoreBox>
          <Typography sx={sxStyles.title}>{name}</Typography>

          <Typography sx={sxStyles.subtitle}>{highlight}</Typography>
        </CoreBox>
      </CardContent>
    </StyledCard>
  );
};
