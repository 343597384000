import ReflectionForm from '@components/ReflectionForm';

import { ALLOWED_GRADES_FOR_ECP } from '@constants';

import { useAuthStoreContext } from '@context/authStore';

import { useIsLoadingContent } from '@customHooks/useIsLoadingContent';

import { useGetReflections } from '@hooks/Reflections';

import { ProfileWithExtendedData, ReflectionType } from '@interfaces';

import { getGradeLevel } from '@utils/helper';
import { useEffect } from 'react';

interface LearnerReflectionsProp {
  reflectionTypes?: ReflectionType[];
  onLoad?: () => void;
}

export const LearnerReflections = ({ reflectionTypes, onLoad }: LearnerReflectionsProp) => {
  const { userData } = useAuthStoreContext();
  const { isLoading: isLoadingReflections, data: reflections } = useGetReflections();

  const profile = userData?.profile as ProfileWithExtendedData;
  const gradeLevel = getGradeLevel(profile?.gradeLevel);

  const { isLoadingContent, addLoadingContent } = useIsLoadingContent();

  useEffect(() => addLoadingContent('reflections')(isLoadingReflections), [isLoadingReflections]);

  useEffect(() => {
    if (!isLoadingContent && onLoad) {
      onLoad();
    }
  }, [isLoadingContent]);

  let section = null;

  if (!isLoadingReflections && reflectionTypes && reflections && gradeLevel) {
    section = (
      <>
        {ALLOWED_GRADES_FOR_ECP.map((grade) => {
          if (gradeLevel && grade <= gradeLevel) {
            return (
              <ReflectionForm
                key={`reflection-for-${grade}`}
                grade={grade}
                currentGrade={grade === gradeLevel}
                reflectionTypes={reflectionTypes}
                reflections={reflections}
              />
            );
          }

          return null;
        })}
      </>
    );
  }

  return section;
};
