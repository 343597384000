import CollegeService from '@services/college';
import { useMutation, useQuery } from '@tanstack/react-query';

export const useGetCollegesSaved = () =>
  useQuery({
    queryKey: ['get-colleges-saved'],
    queryFn: () => CollegeService.getCollegesSaved(),
  });

export const useUnsaveFavoriteCollege = (onetsocCode: string) =>
  useMutation({
    mutationKey: ['unsave-favorite-college'],
    mutationFn: () => CollegeService.unsaveFavoriteCollege(onetsocCode),
  });
