import { Theme, useMediaQuery } from '@mui/material';
import { CoreTypography } from '@youscience/khaleesi';
import { ReactNode } from 'react';

import { Breadcrumb } from '@interfaces';

import ChevronLeftIcon from '@images/chevron-left.svg?react';
import ChevronRightIcon from '@images/chevron-right.svg?react';

import { StyledBreadcrumbs, StyledExternalLink, StyledInternalLink, sxStyles } from './Breadcrumbs.styles';

interface BreadcrumbsProps {
  links: Breadcrumb[];
  currentPage?: string;
}

const StyledLink = ({
  type,
  to,
  children,
  ...props
}: {
  type: string;
  to: string;
  children: ReactNode[] | ReactNode;
}) => {
  if (type === 'internal') {
    return (
      <StyledInternalLink to={to} {...props}>
        {children}
      </StyledInternalLink>
    );
  }
  return (
    <StyledExternalLink href={to} {...props}>
      {children}
    </StyledExternalLink>
  );
};

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { links, currentPage = '' } = props;
  const isMobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('xxs'));

  const mobileBreadcrumbs = links[links.length - 1];

  return isMobileScreen ? (
    <StyledLink {...mobileBreadcrumbs}>
      <ChevronLeftIcon />

      {mobileBreadcrumbs.text}
    </StyledLink>
  ) : (
    <StyledBreadcrumbs aria-label='breadcrumb' separator={<ChevronRightIcon color='gray' />}>
      {links.map((link) => (
        <StyledLink {...link} key={link.to}>
          {link.text}
        </StyledLink>
      ))}

      <CoreTypography sx={sxStyles.currentPage} variant='body4' component='span'>
        {currentPage}
      </CoreTypography>
    </StyledBreadcrumbs>
  );
};
