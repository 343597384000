import { Link } from '@mui/material';
import { CoreBox, CoreTypography } from '@youscience/khaleesi';
import { MutableRefObject, useEffect } from 'react';

import Interests from '@components/Aptitudes/Interests';
import { getInterests } from '@components/Aptitudes/Interests/Utils';
import Careers from '@components/Careers';
import LearnerReflections from '@components/LearnerReflections';
import { Loader } from '@components/Loader';

import { useIsLoadingContent } from '@customHooks/useIsLoadingContent';

import { DISCOVERY_BRAINGAMES_URL } from '@constants/externalRoutes';
import { CAREER_REFLECTION_TYPES } from '@constants/reflectionTypes';

import { useAuthStoreContext } from '@context/authStore';

import { useGetFeedback } from '@hooks/Aptitudes';
import { useGetCareersSavedAndSuggested } from '@hooks/Career';

import { FeedbackFromHighSchool, Interest } from '@interfaces';

import ResumeBuilderActions from '../../ResumeBuilderActions';

import { sxStyles } from './CareerSection.styles';

interface CareerSectionProps {
  userWithResultsReady: boolean;
  isResumeBuilderEnabled: boolean;
  resumeBuilderRef: MutableRefObject<HTMLAnchorElement | null>;
  onLoad?: () => void;
}

export const CareerSection = ({
  userWithResultsReady,
  resumeBuilderRef,
  isResumeBuilderEnabled,
  onLoad,
}: CareerSectionProps) => {
  const { userData } = useAuthStoreContext();
  const { isLoading: isLoadingAptitudes, data: feedback } = useGetFeedback();
  const { isLoading: isLoadingCareers, data: careers = [] } = useGetCareersSavedAndSuggested();

  const { isLoadingContent, addLoadingContent } = useIsLoadingContent();

  useEffect(() => addLoadingContent('loading-careers')(isLoadingCareers), [isLoadingCareers]);
  useEffect(() => addLoadingContent('loading-aptitudes')(isLoadingAptitudes), [isLoadingAptitudes]);

  useEffect(() => {
    if (!isLoadingContent && onLoad) {
      onLoad();
    }
  }, [isLoadingContent]);

  let interests: Interest[] = [];

  if (feedback) {
    interests = getInterests(feedback as FeedbackFromHighSchool);
  }

  return (
    <>
      <CoreTypography sx={sxStyles.sectionTitle} variant='h3'>
        Careers
      </CoreTypography>

      {userWithResultsReady ? (
        <>
          <CoreTypography sx={sxStyles.subSectionTitle} variant='h4'>
            My top work interests
          </CoreTypography>

          <CoreTypography sx={sxStyles.subSectionSubTitle} variant='p'>
            You expressed interest in these three areas based on the activities you would enjoy.
          </CoreTypography>

          <CoreBox sx={sxStyles.interestsContainer}>
            {isLoadingAptitudes ? <Loader /> : <Interests interests={interests} />}
          </CoreBox>

          <CoreBox sx={sxStyles.careersContainer}>
            {isLoadingCareers ? <Loader /> : <Careers careers={careers} userId={userData?.userId} />}
          </CoreBox>

          <CoreTypography sx={[sxStyles.subSectionTitle, { marginBottom: '2rem' }]} variant='h4'>
            My career reflections
          </CoreTypography>

          <LearnerReflections
            reflectionTypes={CAREER_REFLECTION_TYPES}
            onLoad={addLoadingContent('career-reflections')}
          />
        </>
      ) : (
        <CoreTypography sx={sxStyles.subSectionSubTitle}>
          Unlock this entire section by visiting Aptitude and Career Discovery and completing your{' '}
          <Link href={DISCOVERY_BRAINGAMES_URL} color='primary' sx={{ fontWeight: 'bold' }}>
            Brain games.
          </Link>{' '}
          You&apos;ll be able to explore your best-fit careers and reflect on the steps you&apos;ll take to achieve your
          career goals.
        </CoreTypography>
      )}

      {isResumeBuilderEnabled ? (
        <CoreBox ref={resumeBuilderRef}>
          <ResumeBuilderActions sxTitle={sxStyles.subSectionTitle} />
        </CoreBox>
      ) : null}
    </>
  );
};
