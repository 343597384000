import { CoreTypography } from '@youscience/khaleesi';
import { RefObject } from 'react';
import { Navigation, Pagination, Swiper } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { SwiperSlide } from 'swiper/react';
import { NavigationOptions } from 'swiper/types';

import CareerCard from '@components/CareerCard';

import { Career } from '@services/career';

import { StyledSwiper } from './CareerSwiper.styles';

const SWIPER_DATA = {
  PAGINATION: {
    clickable: true,
  },
  BREAKPOINTS: {
    1180: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 3,
    },
    576: {
      slidesPerView: 3,
    },
  },
};

interface CareerSwiperProps {
  careers: Career[];
  userId: string;
  nextButtonRef: RefObject<HTMLDivElement>;
  previousButtonRef: RefObject<HTMLDivElement>;
}

export const CareerSwiper = ({
  careers: careersToRender,
  userId,
  nextButtonRef,
  previousButtonRef,
}: CareerSwiperProps) => {
  if (!careersToRender?.length) {
    return <CoreTypography variant='body1'>{`You haven't selected any potential careers yet.`}</CoreTypography>;
  }

  const navigationRefs = {
    prevEl: previousButtonRef.current,
    nextEl: nextButtonRef.current,
  };

  const handleBeforeInit = (swiper: Swiper) => {
    // eslint-disable-next-line no-param-reassign
    swiper.params.navigation = {
      ...(swiper.params.navigation as NavigationOptions),
      ...navigationRefs,
    };
  };

  return (
    <StyledSwiper
      spaceBetween='32px'
      pagination={SWIPER_DATA.PAGINATION}
      navigation={navigationRefs}
      modules={[Pagination, Navigation]}
      breakpoints={SWIPER_DATA.BREAKPOINTS}
      onBeforeInit={handleBeforeInit}
    >
      {careersToRender.map((career) => (
        <SwiperSlide key={career.onetsoc_code}>
          <CareerCard career={career} userId={userId} key={`career-card-${career.onetsoc_code}`} />
        </SwiperSlide>
      ))}
    </StyledSwiper>
  );
};
