import IconCircleEmpty from '@images/circle-empty.svg?react';
import IconCircleFull from '@images/circle-full.svg?react';
import IconCircleHalf from '@images/circle-half.svg?react';
import IconHeartEmpty from '@images/heart-empty.svg?react';
import IconHeartFull from '@images/heart-full.svg?react';
import IconHeartHalf from '@images/heart-half.svg?react';
import IconSquareEmpty from '@images/square-empty.svg?react';
import IconSquareFull from '@images/square-full.svg?react';
import IconSquareHalf from '@images/square-half.svg?react';
import { CoreBox } from '@youscience/khaleesi';
import { useMemo } from 'react';
import { sxStyles } from './FitBullets.styles';

export enum FitTypes {
  Overall = 'overall_fit',
  Aptitude = 'ar_fit',
  Interest = 'ir_fit',
}

const FIT_CONSTANTS: {
  displayColor: string;
  adjective: string;
  value: number;
}[] = [
  {
    displayColor: '#5ad07d',
    adjective: 'Strong',
    value: 1,
  },
  {
    displayColor: '#85b3ff',
    adjective: 'Good',
    value: 2,
  },
  {
    displayColor: '#f9b057',
    adjective: 'Fair',
    value: 3,
  },
  {
    displayColor: '#a3adba',
    adjective: 'Weak',
    value: 4,
  },
];

export const fitConstants = new Map(FIT_CONSTANTS.map((d) => [d.value, d]));

export const fitTypeLabels: Record<FitTypes, string> = {
  [FitTypes.Aptitude]: 'Aptitude Fit',
  [FitTypes.Interest]: 'Interest Fit',
  [FitTypes.Overall]: 'Overall Fit',
};

export interface FitBulletsProps {
  fit: number;
  type?: FitTypes;
}

export const icons = (fitType?: FitTypes) => {
  switch (fitType) {
    case FitTypes.Aptitude:
      return {
        IconEmpty: IconSquareEmpty,
        IconHalf: IconSquareHalf,
        IconFull: IconSquareFull,
      };
    case FitTypes.Interest:
      return {
        IconEmpty: IconHeartEmpty,
        IconHalf: IconHeartHalf,
        IconFull: IconHeartFull,
      };
    case FitTypes.Overall:
    default:
      return {
        IconEmpty: IconCircleEmpty,
        IconHalf: IconCircleHalf,
        IconFull: IconCircleFull,
      };
  }
};

export const FitBullets = ({ fit, type }: FitBulletsProps) => {
  const { IconEmpty, IconFull, IconHalf } = useMemo(() => icons(type), [type]);
  const bullets = () => {
    switch (fit) {
      case 1:
        return [1, 2, 3].map((i) => <IconFull key={`icon-${i}`} />);
      case 2:
        return [...[1, 2].map((i) => <IconFull key={`icon-${i}`} />), <IconEmpty key='icon-3' />];
      case 3:
        return [<IconFull key='icon-1' />, ...[2, 3].map((i) => <IconEmpty key={`icon-${i}`} />)];
      case 4:
        return [<IconHalf key='icon-1' />, ...[2, 3].map((i) => <IconEmpty key={`icon-${i}`} />)];
      default:
        return [1, 2, 3].map((i) => <IconEmpty key={`icon-${i}`} />);
    }
  };

  return (
    <CoreBox sx={sxStyles.bullets} data-fit-value={fit}>
      {bullets()}
    </CoreBox>
  );
};
