import { ECP_API_URL } from '@constants/externalRoutes';
import { FeatureFlags, FeatureFlagsResponse } from '@interfaces';
import api from '../utils/api';

const get = async (): Promise<FeatureFlags> => {
  const response: FeatureFlagsResponse = await api(`${ECP_API_URL}/feature-flags`);

  return response?.data;
};

const FeatureFlagsService = { get };

export default FeatureFlagsService;
