import ResumeApi from '@api/resume';
import { Resume } from '@interfaces';
import { downloadFileResponse } from '@utils/api';
import { getItemFromLocalStorage, removeItemFromLocalStorage, setItemToLocalStorage } from '@utils/browserStorage';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const currentResumeIdKey = 'currentResumeId';

const getCurrentResumeId = () => {
  return getItemFromLocalStorage(currentResumeIdKey);
};

const setCurrentResumeId = (id: string) => {
  return setItemToLocalStorage(currentResumeIdKey, id);
};

const removeCurrentResumeId = () => {
  return removeItemFromLocalStorage(currentResumeIdKey);
};

const getCurrentResume = async (options?: {
  resumeId: string | null;
  force?: boolean;
  preventCreation?: boolean;
}): Promise<Resume | null> => {
  let id = options?.resumeId || getCurrentResumeId();

  if (options?.force && !options?.resumeId) {
    id = null;
  }
  let resume: Resume;

  if (id) {
    try {
      resume = await ResumeApi.get(id);
      return resume;
    } catch (error) {} // eslint-disable-line no-empty
  }

  const resumes: Resume[] = await ResumeApi.list();

  [resume] = resumes;

  if (!resume) {
    if (options?.preventCreation) return null;

    const data: Resume = { name: 'Default' };

    resume = await ResumeApi.create(data);
  }

  setCurrentResumeId(resume.id!);
  return resume;
};

const updateCurrentResume = async (body: Resume): Promise<Resume> => {
  const id = getCurrentResumeId()!;
  const resume = await ResumeApi.update(id, body);

  return resume;
};

const useResumeBuilder = () => {
  const [searchParams] = useSearchParams();
  const resumeId = searchParams.get('resumeId');

  useEffect(() => {
    if (!resumeId || resumeId === '') {
      return removeCurrentResumeId();
    }
    return setCurrentResumeId(resumeId);
  }, [searchParams]);
};

const getCurrentResumePreview = async (): Promise<string> => {
  const resumeId = getCurrentResumeId();
  let resumePreview;

  if (resumeId) {
    resumePreview = await ResumeApi.getPreview(resumeId);
  }

  return resumePreview ?? '';
};

const downloadSampleResume = async () => {
  const response = await ResumeApi.downloadSampleResume();

  downloadFileResponse(response, { filename: 'Sample_Resume.pdf' });
};

const ResumeBuilderService = {
  getCurrentResume,
  updateCurrentResume,
  useResumeBuilder,
  getCurrentResumePreview,
  removeCurrentResumeId,
  setCurrentResumeId,
  downloadSampleResume,
};

export default ResumeBuilderService;
