import { Theme, styled } from '@mui/material';

export const sxStyles = {
  banner: (theme: Theme) => ({
    backgroundColor: theme.palette.grey[100],
  }),
  title: (theme: Theme) => ({
    marginTop: theme.spacing(1),
    fontSize: '28px',
  }),
  titleContainer: (theme: Theme) => ({
    marginTop: theme.spacing(6),
  }),
  actionButtons: (theme: Theme) => ({
    textAlign: 'right',
    paddingTop: theme.spacing(1),
  }),
};
export const StyledContainer = styled('div')(() => ({
  maxWidth: 'calc(1240px + 2.5rem)',
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingTop: '32px',
  paddingBottom: '32px',
}));
