import { styled } from '@mui/material';
import { CoreTypography } from '@youscience/khaleesi';

export const StyledEllipsisTypography = styled(CoreTypography, {
  shouldForwardProp: (prop) => prop !== 'stringCount',
})<{ stringCount?: number }>(({ stringCount }) => ({
  display: '-webkit-box',
  WebkitLineClamp: stringCount,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  wordWrap: 'break-word',
}));
