import { YS_HELP_CENTER_URL, YS_SCHEDULE_DEMO_URL } from '@constants/externalRoutes';
import { Link } from '@mui/material';

export type ErrorType =
  | 'access-denied-ecp-disabled-learner'
  | 'access-denied-ecp-disabled-other-users'
  | 'route-not-found';

export interface ErrorPageProps {
  errorType: ErrorType;
}

export interface ErrorMessage {
  error: string;
  mainMessage: string;
  subMessage: string | JSX.Element;
}

const ScheduleDemoLink = () => {
  return (
    <Link href={YS_SCHEDULE_DEMO_URL} color='primary'>
      schedule a demo
    </Link>
  );
};

const HelpCenterLink = () => {
  return (
    <Link href={YS_HELP_CENTER_URL} color='primary'>
      help center
    </Link>
  );
};

export const errorMessages: { [key in ErrorType]: ErrorMessage } = {
  'access-denied-ecp-disabled-learner': {
    error: 'Access denied',
    mainMessage: `This YouScience® Brightpath offering is currently disabled by your organization.`,
    subMessage: (
      <>
        Education and Career Planning (ECP) tools help you review and reflect on your aptitude results, career
        interests, and education decisions. A resume builder and course planning tool are also available within ECP.
        Visit our <HelpCenterLink /> to learn more!
        {'\n\n'}
        Return home to explore other offerings and continue your journey.
      </>
    ),
  },
  'access-denied-ecp-disabled-other-users': {
    error: 'Access denied',
    mainMessage: `This feature isn't currently available to you.`,
    subMessage: (
      <>
        Want to expand your toolkit?
        {'\n\n'}
        Education and Career Planning (ECP) tools help students review and reflect on their aptitude results, career
        interests, and education decisions. A resume builder and course planning tool are also available within ECP.
        {'\n\n'}
        To get started, please contact your organization&apos;s YouScience account administrator, speak with your
        YouScience representative, or <ScheduleDemoLink />.{'\n\n'}
        Return home to continue your experience.
      </>
    ),
  },
  'route-not-found': {
    error: 'Not found',
    mainMessage: `This page isn't available`,
    subMessage: (
      <>
        Please contact your organizations&apos; YouScience account admin, speak with your YouScience representative.
        {'\n\n'}
        Return home to continue your experience.
      </>
    ),
  },
};
