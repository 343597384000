import { SyntheticEvent, useState } from 'react';
import { Autocomplete as MuiAutocomplete, TextField } from '@mui/material';

import { Option } from '@youscience/brightpath-header';

import { CURRENT_YEAR, CURRENT_MONTH } from '@constants';

export interface AutocompleteOption extends Option {
  additionData?: Option[];
  isDisabled?: boolean;
}

interface GradeAutocompleteProps {
  handleGradeChange: (grade: string | undefined, graduationYear: number | undefined) => void;
}

const getGraduationYear = (grade: number) => {
  if (grade === -1) {
    return 0;
  }

  const graduationYear = CURRENT_YEAR + (12 - grade);

  if (CURRENT_MONTH > 5) {
    return graduationYear + 1;
  }

  return graduationYear;
};

const gradeOptions: AutocompleteOption[] = [8, 9, 10, 11, 12].map((grade) => ({
  label: `Grade ${grade} (graduating in ${getGraduationYear(grade)})`,
  value: `${grade}`,
}));

export const GradeAutocomplete = (props: GradeAutocompleteProps) => {
  const { handleGradeChange } = props;

  const [grade, setGrade] = useState<AutocompleteOption | null>(null);

  const onGradeChange = (event: SyntheticEvent, grade: AutocompleteOption | null) => {
    setGrade(grade);

    handleGradeChange(grade?.value, getGraduationYear(Number(grade?.value)));
  };

  return (
    <MuiAutocomplete
      data-testid='gradeAutocomplete'
      options={gradeOptions}
      renderInput={(params) => <TextField {...params} label='What grade are you in?' />}
      clearIcon={null}
      value={grade}
      onChange={onGradeChange}
      isOptionEqualToValue={(option, value) => option.value === value.value}
    />
  );
};
