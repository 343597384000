import { DISCOVERY_API_URL } from '@constants/externalRoutes';
import { APTITUDES, PERSONAL_APPROACH_DIMENSIONS } from '@constants/feedback';

import {
  AdjustedPercentage,
  Aptitude,
  Dimension,
  Dimensions,
  Feedback,
  FeedbackFromHighSchool,
  FeedbackFromMiddleSchool,
} from '@interfaces';
import { AptitudeTalents, Scales } from '@interfaces/feedback';

import { calculatePercentageValues } from '@utils/aptitudesPercentagesCalculations';

import api from '../utils/api';

const TOP_TALENTS = 2;

const getFeedback = async (): Promise<Feedback> => {
  const response = await api(`${DISCOVERY_API_URL}/feedback`);

  return response?.data as Feedback;
};

export const getPersonalApproachDimensions = (feedback: FeedbackFromHighSchool | undefined) => {
  if (feedback) {
    const { dimensions } = feedback;

    if (dimensions) {
      return Object.keys(dimensions).reduce((result: Dimension[], dimensionKey: string) => {
        if (PERSONAL_APPROACH_DIMENSIONS.includes(dimensionKey)) {
          result.push(dimensions[dimensionKey]);
        }

        return result;
      }, []);
    }
  }

  return undefined;
};

export const getTopTalentsSelector = (adjustedPercentage: AdjustedPercentage[]) => {
  let topTalents: AdjustedPercentage[] = [];

  if (adjustedPercentage) {
    const filteredAptitudes = adjustedPercentage.filter(({ name }) => {
      const aptitude = APTITUDES[name];

      return aptitude && aptitude.middleSchool && aptitude.topTalent;
    });

    const sortedAptitudes = filteredAptitudes.sort(
      ({ percent: firstScore }, { percent: secondScore }) => firstScore - secondScore,
    );

    topTalents = sortedAptitudes
      .reverse()
      .slice(0, TOP_TALENTS)
      .map(({ name, percent, color, otherColors }) => ({
        percent,
        name,
        color,
        otherColors,
      }));
  }

  return topTalents;
};

const sortAptitudesByPercent = (
  { percent: firstPercent }: AdjustedPercentage,
  { percent: secondPercent }: AdjustedPercentage,
) => secondPercent - firstPercent;

export const getSpecificPersonalApproachDimension = (dimensions: Dimension[], dimensionName: string) =>
  dimensions.find(({ general: { name } }) => name === dimensionName);

export const getProfileAptitudesPercentages = ({ dimensions }: FeedbackFromHighSchool) => {
  let aptitudesPercentages: AdjustedPercentage[] = [];

  if (dimensions) {
    const filteredAptitudes = Object.values(dimensions).filter(
      ({ general: { name } }) => APTITUDES[name] && APTITUDES[name].highSchool,
    );

    const aptitudeMap = ({ name, percent }: AdjustedPercentage) => {
      const otherColors = APTITUDES[name].color;
      const color = otherColors.default;

      return { name, percent, color, otherColors };
    };

    aptitudesPercentages = calculatePercentageValues(filteredAptitudes).map(aptitudeMap).sort(sortAptitudesByPercent);
  }

  return aptitudesPercentages;
};

export const getSnapshotAptitudesPercentages = ({ aptitudes: dimensions }: FeedbackFromMiddleSchool) => {
  let aptitudesPercentages: AdjustedPercentage[] = [];

  if (dimensions) {
    const filteredAptitudes = dimensions.filter(({ name }) => APTITUDES[name].middleSchool);

    aptitudesPercentages = calculatePercentageValues(filteredAptitudes).map(({ name, percent }) => {
      const otherColors = APTITUDES[name].color;
      const color = otherColors.middleSchool;

      return { name, percent, color, otherColors };
    });
  }

  return aptitudesPercentages;
};

export const getAptitudeTalents = (
  feedback: FeedbackFromHighSchool,
  aptitudeDimensionKeys: Set<string>,
): AptitudeTalents[] => {
  const { dimensions } = feedback;

  if (!dimensions) return [] as AptitudeTalents[];

  const filteredDimensions: Dimensions = Object.keys(dimensions)
    .filter((key) => aptitudeDimensionKeys.has(dimensions[key].general.name))
    .reduce((obj, key) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newObject: Record<string, any> = { ...obj };

      newObject[key] = dimensions[key];

      return newObject;
    }, {});

  const mappedDimensions = Object.keys(filteredDimensions).map((key) => {
    const dimension = filteredDimensions[key];
    const { name, your_scale } = dimension.general;

    const filteredScales = Object.keys(dimension.scales)
      .filter((scaleKey) => scaleKey === your_scale)
      .reduce((obj, scaleKey) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newObject: Record<string, any> = { ...obj };

        newObject[scaleKey] = {
          moniker: dimension.scales[scaleKey].moniker,
          talents: dimension.scales[scaleKey].talents,
        };

        return newObject;
      }, {});

    return {
      name,
      your_scale,
      scales: filteredScales,
    };
  });

  return mappedDimensions;
};

const getMSAptitudeTalents = (feedback: FeedbackFromMiddleSchool, aptitudeNames: Set<string>): AptitudeTalents[] => {
  const { aptitudes } = feedback;

  if (!aptitudes) return [] as AptitudeTalents[];

  const filteredAptitudes: Aptitude[] = aptitudes.filter(({ name }) => aptitudeNames.has(name));

  return filteredAptitudes.map((item) => {
    const { name, results_bullet, moniker, scale, measurement_short } = item;

    const scales: Scales = {};

    const talents = results_bullet.map((content, index) => ({
      content,
      id: index,
    }));

    scales[scale] = {
      moniker: [{ content: moniker, id: moniker }],
      talents,
    };

    return {
      name,
      measurement_short,
      your_scale: scale,
      scales,
    };
  });
};

const FeedbackService = {
  getFeedback,
  getPersonalApproachDimensions,
  getProfileAptitudesPercentages,
  getSnapshotAptitudesPercentages,
  getAptitudeTalents,
  getSpecificPersonalApproachDimension,
  getTopTalentsSelector,
  getMSAptitudeTalents,
};

export default FeedbackService;
