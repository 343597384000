import { ISP_API_URL } from '@constants/externalRoutes';
import api from '../utils/api';

const refreshToken = async (): Promise<unknown> => {
  const response = await api(`${ISP_API_URL}/refreshToken`, { method: 'POST', withCredentials: true });

  return response?.data as unknown;
};

const IspApi = {
  refreshToken,
};

export default IspApi;
