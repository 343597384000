import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

import { ReflectionType } from '@interfaces';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

interface ReflectionFormProp {
  reflectionType: ReflectionType;
  index: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, data: unknown, index: number) => void;
}

export const ReflectionFormCheckbox = ({ reflectionType, index, onChange }: ReflectionFormProp) => {
  const values: NonNullable<Record<string, boolean>> = JSON.parse(reflectionType?.content || '{}') as Record<
    string,
    boolean
  >;

  const defaultValues: Record<string, boolean> =
    reflectionType.options?.reduce((acc: Record<string, boolean>, element: { key: string }) => {
      const newElement = { [`${element.key}`]: false };

      return { ...acc, ...newElement };
    }, {}) || {};

  const { control, getValues, setValue } = useForm<Record<string, boolean>>({
    mode: 'onChange',
    resolver: yupResolver(reflectionType.schema || yup.object().required()),
    defaultValues,
  });

  useEffect(() => {
    if (values) {
      reflectionType?.options?.forEach((element: { key: string; value: string }) => {
        if (values[element.key]) {
          setValue(element.key, values[element.key]);
        }
      });
    }
  }, []);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = getValues();

    onChange(event, data, index);
  };

  const renderCheckboxOptions = () => {
    return reflectionType.options?.map((option) => {
      return (
        <Controller
          key={option.key}
          name={option.key}
          control={control}
          render={({ field }) => (
            <FormControlLabel control={<Checkbox />} label={option.value} checked={field.value} {...field} />
          )}
        />
      );
    });
  };

  return <FormGroup onChange={handleOnChange}>{renderCheckboxOptions()}</FormGroup>;
};
