import { FormHelperText } from '@mui/material';
import { CoreBox, CoreTextField, CoreTypography } from '@youscience/khaleesi';
import { useState } from 'react';
import { StyledTextArea, sxStyles } from './CoreTextArea.styles';

interface CoreTextAreaProp {
  placeholder: string;
  row: number;
  limit: number;
  content: string;
  label?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export const CoreTextArea = ({ placeholder, row, limit, content, label, onChange }: CoreTextAreaProp) => {
  const [count, setCount] = useState<number>(content?.length || 0);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value?.length > limit) return;
    setCount(value.length);
    onChange(event);
  };

  return (
    <>
      <StyledTextArea sx={count > limit ? sxStyles.highlight : sxStyles.none}>
        {label ? <CoreBox sx={sxStyles.label}>{label}</CoreBox> : null}
        <CoreTextField
          type='text'
          fullWidth
          multiline
          onChange={handleInputChange}
          placeholder={placeholder}
          rows={row}
          value={content}
          variant='standard'
        />
      </StyledTextArea>
      <CoreTypography textAlign='left' sx={sxStyles.limitText}>
        <CoreTypography sx={count > limit ? sxStyles.error : sxStyles.none} variant='span'>
          {count}
        </CoreTypography>
        /{limit}
      </CoreTypography>
      {count > limit && <FormHelperText error>You’ve exceeded the limit allowed of {limit} characters.</FormHelperText>}
    </>
  );
};
