import AccessWrapper from '@components/AccessWrapper';
import { Drawer, List, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import { APP_BAR_HEIGHT } from '@youscience/brightpath-header';
import { CoreBox } from '@youscience/khaleesi';
import { FC, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { StyledListItemButton, sxStyles } from './SideNav.styles';
import { NAVIGATION_ITEMS } from './constants';
import { SideNavProps } from './types';

export const SideNav: FC<SideNavProps> = () => {
  const location = useLocation();

  return (
    <Drawer variant='permanent' sx={sxStyles.drawer}>
      <Toolbar sx={{ height: APP_BAR_HEIGHT }} />
      <CoreBox sx={{ overflow: 'auto' }}>
        <List sx={{ paddingTop: '0px' }}>
          {NAVIGATION_ITEMS.map((item) => (
            <Fragment key={item.key}>
              <AccessWrapper allowedRoles={item?.allowedRoles ?? ['Admin']} restrictOrg={item.restrictOrg}>
                <StyledListItemButton component={Link} item={item} key={item.key} location={location} to={item.path}>
                  <ListItemIcon sx={sxStyles.navListItemIcon}>{item.icon}</ListItemIcon>
                  <ListItemText>{item.title}</ListItemText>
                </StyledListItemButton>
              </AccessWrapper>
            </Fragment>
          ))}
        </List>
      </CoreBox>
    </Drawer>
  );
};
