import EditIcon from '@mui/icons-material/Edit';
import EmojiObjectsOutlined from '@mui/icons-material/EmojiObjectsOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { CoreBox, CoreButton, CoreTypography } from '@youscience/khaleesi';
import { useState } from 'react';

import CoreTextArea from '@components/CoreTextArea';

import { useCreateReflection, useUpdateReflection } from '@hooks/Reflections';

import { Reflection, ReflectionType } from '@interfaces';

import { SHORT_FORMAT_DATE, formatDate } from '@utils/date';
import { sxStyles } from './ReflectionForm.styles';
import { ReflectionFormCheckbox } from './ReflectionFormCheckbox/ReflectionFormCheckbox';

interface ReflectionFormProp {
  grade: number | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  currentGrade: boolean;
  reflectionTypes?: ReflectionType[];
  reflections?: Reflection[];
}

export const ReflectionForm = ({ grade, currentGrade, reflectionTypes, reflections }: ReflectionFormProp) => {
  const [inputValues, setInputValues] = useState<ReflectionType[]>([]);
  const [reflectionList, setReflectionList] = useState<Reflection[]>([]);
  const [actionForm, setActionForm] = useState<string>('read');
  const [date, setDate] = useState<string>('');
  const { mutateAsync: createReflection, isLoading: isReflectionCreating } = useCreateReflection();
  const { mutateAsync: updateReflection, isLoading: isReflectionUpdating } = useUpdateReflection();

  const initReflections = (shouldSetActionForm: boolean) => {
    let updatedDate = new Date();

    const formReflectionTypes: ReflectionType[] =
      reflectionTypes?.map((reflectionType) => {
        const currentReflection = reflectionList?.find(
          (x) =>
            x.type === reflectionType.type &&
            x.stage === reflectionType.stage &&
            x.name === reflectionType.name &&
            x.grade === grade,
        );

        if (currentGrade && shouldSetActionForm) setActionForm(currentReflection ? 'read' : 'edit');

        if (currentReflection) {
          updatedDate = new Date(currentReflection.updatedAt || '');
        }

        return {
          ...reflectionType,
          content: currentReflection?.content ?? '',
          reflectionId: currentReflection?.id,
        };
      }) || [];

    const formattedDate = formatDate(updatedDate, SHORT_FORMAT_DATE);

    setDate(formattedDate);
    setInputValues(formReflectionTypes);
  };

  if (reflections?.length && reflectionList.length === 0) {
    setReflectionList(reflections);
  }

  if ((!reflections?.length || reflectionList.length) && inputValues.length === 0 && reflectionTypes?.length) {
    initReflections(true);
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;

    inputValues[index].content = value;
    setInputValues([...inputValues]);
  };

  const onSave = async () => {
    if (isReflectionCreating || isReflectionUpdating) return;

    const reflectionListUpdated: Reflection[] = [];
    const updatedInputValues = await Promise.all(
      inputValues.map(async (inputValue) => {
        const payload: Reflection = {
          type: inputValue.type,
          name: inputValue.name,
          stage: inputValue.stage,
          content: inputValue.content,
          grade,
        };

        let reflection;

        if (inputValue?.reflectionId) {
          reflection = await updateReflection({ ...payload, id: inputValue?.reflectionId });
        } else {
          reflection = await createReflection(payload);
        }

        reflectionListUpdated.push(reflection);

        return {
          ...inputValue,
          reflectionId: reflection?.id,
          content: reflection?.content,
        } as ReflectionType;
      }),
    );

    setInputValues([...updatedInputValues]);
    setReflectionList([...reflectionListUpdated]);
    setActionForm('read');
  };

  const onEditReflection = () => {
    setActionForm('edit');
  };

  const onCancel = () => {
    initReflections(false);
    setActionForm('read');
  };

  const handleOnCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, data: unknown, index: number) => {
    inputValues[index].content = JSON.stringify(data);
    setInputValues([...inputValues]);
  };

  let formSection;
  let formActions;

  const renderTextArea = (inputValue: ReflectionType, index: number) => {
    return (
      <CoreTextArea
        label='My reflection'
        placeholder={inputValue.placeholder}
        row={3}
        limit={inputValue.limit || 500}
        content={inputValue.content}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleOnChange(event, index);
        }}
      />
    );
  };

  const renderCheckboxes = (inputValue: ReflectionType, index: number) => {
    return <ReflectionFormCheckbox reflectionType={inputValue} index={index} onChange={handleOnCheckboxChange} />;
  };

  const renderCheckboxContent = (inputValue: ReflectionType) => {
    let content = '';

    const values: NonNullable<Record<string, boolean>> = JSON.parse(inputValue.content || '{}') as Record<
      string,
      boolean
    >;

    inputValue?.options?.forEach((element: { key: string; value: string }) => {
      if (values[element.key]) {
        content = `${content}${content ? ' | ' : ''}${element.value}`;
      }
    });
    return content;
  };

  if (actionForm === 'edit') {
    formSection = (
      <CoreBox sx={sxStyles.editReflectionsContainer}>
        {inputValues.map((inputValue: ReflectionType, index: number) => {
          return (
            <CoreBox sx={sxStyles.bottomGap} key={`reflection-form-${inputValue.name}`}>
              <CoreTypography variant='subtitle2' sx={[sxStyles.reflectionTitle, sxStyles.bottomGap]} color='black'>
                {inputValue.title}
              </CoreTypography>

              {inputValue.fieldType === 'checkbox'
                ? renderCheckboxes(inputValue, index)
                : renderTextArea(inputValue, index)}
            </CoreBox>
          );
        })}
      </CoreBox>
    );
    if (!inputValues.find((x) => x.reflectionId)) {
      formActions = (
        <AccordionActions sx={sxStyles.editReflectionsActionsContainer}>
          <CoreButton
            type='submit'
            sx={[sxStyles.button]}
            variant='contained'
            onClick={onSave}
            disabled={isReflectionCreating || isReflectionUpdating}
          >
            Submit
          </CoreButton>
        </AccordionActions>
      );
    } else {
      formActions = (
        <AccordionActions sx={sxStyles.editReflectionsActionsContainer}>
          <CoreButton type='submit' sx={[sxStyles.button]} variant='outlined' onClick={onCancel}>
            Cancel
          </CoreButton>
          <CoreButton
            type='submit'
            sx={[sxStyles.button]}
            variant='contained'
            onClick={onSave}
            disabled={isReflectionCreating || isReflectionUpdating}
          >
            Save changes
          </CoreButton>
        </AccordionActions>
      );
    }
  } else {
    formSection = (
      <CoreBox sx={sxStyles.readReflectionsContainer}>
        {inputValues.map((inputValue: ReflectionType) => {
          return (
            (inputValue.content || currentGrade) && (
              <CoreBox key={inputValue.name} sx={sxStyles.readReflectionContainer}>
                <Grid container>
                  <Grid item xs={1}>
                    <EmojiObjectsOutlined fontSize='large' sx={sxStyles.readReflectionIcon} />
                  </Grid>

                  <Grid item xs={11}>
                    <CoreTypography variant='subtitle2' sx={sxStyles.reflectionTitle} color='black'>
                      {inputValue.title}
                    </CoreTypography>

                    <CoreTypography variant='p' sx={sxStyles.reflectionContent} color='black'>
                      {inputValue.fieldType === 'checkbox'
                        ? renderCheckboxContent(inputValue) || inputValue.placeholder
                        : inputValue.content || inputValue.placeholder}
                    </CoreTypography>
                  </Grid>
                </Grid>
              </CoreBox>
            )
          );
        })}
        {currentGrade && (
          <CoreBox textAlign='right'>
            <CoreButton type='submit' sx={[sxStyles.button]} variant='contained' onClick={onEditReflection}>
              <EditIcon sx={sxStyles.iconButton} /> Edit reflections
            </CoreButton>
          </CoreBox>
        )}
      </CoreBox>
    );

    if (!currentGrade && !inputValues.find((x) => x.content)) {
      formSection = null;
    }
  }

  return (
    formSection && (
      <Accordion defaultExpanded={currentGrade} sx={sxStyles.accordionContainer}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={sxStyles.accordionTitleContainer}>
          <CoreTypography variant='p' sx={sxStyles.accordionTitle} color='primary'>
            Grade {grade}
          </CoreTypography>

          <CoreTypography variant='p' sx={sxStyles.accordionTitleDate}>
            {date}
          </CoreTypography>
        </AccordionSummary>

        <AccordionDetails>{formSection}</AccordionDetails>

        {formActions}
      </Accordion>
    )
  );
};
