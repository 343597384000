import { Link } from '@mui/material';
import { CoreBox, CoreButton, CoreTypography } from '@youscience/khaleesi';
import { useEffect, useState } from 'react';

import PersonalApproachList from '@components/Aptitudes/PersonalApproachList';
import LearnerReflections from '@components/LearnerReflections';

import { useIsLoadingContent } from '@customHooks/useIsLoadingContent';

import { ECP_SOURCE } from '@constants';
import { DISCOVERY_BRAINGAMES_URL } from '@constants/externalRoutes';
import { HS_APTITUDE_REFLECTION_TYPES } from '@constants/reflectionTypes';

import { AdjustedPercentage, Dimension, FeedbackFromHighSchool } from '@interfaces';
import { AptitudeTalents } from '@interfaces/feedback';

import { PersonalApproachContext, usePersonalApproachState } from '@context/personalApproach';
import { useGetFeedback } from '@hooks/Aptitudes';
import FeedbackService from '@services/feedback';
import { redirectToBrainGames } from '@utils/urlHelper';

import DoughnutChart from '../../DoughnutChart';

import { sxStyles } from './AptitudesSection.styles';

interface AptitudesSectionProps {
  userWithResultsReady: boolean;
  onLoad?: () => void;
}

export const AptitudesSection = ({ userWithResultsReady, onLoad }: AptitudesSectionProps) => {
  const { isLoading: isLoadingAptitudes, data: feedback } = useGetFeedback() as {
    isLoading: boolean;
    data: FeedbackFromHighSchool | undefined;
  };

  const [aptitudePercentages, setAptitudePercentages] = useState<AdjustedPercentage[]>([] as AdjustedPercentage[]);
  const [personalApproachDimensions, setPersonalApproachDimensions] = useState<Dimension[]>([] as Dimension[]);
  const [aptitudeTalents, setAptitudeTalents] = useState<AptitudeTalents[]>([]);
  const personalApproachState = usePersonalApproachState();

  const { isLoadingContent, addLoadingContent } = useIsLoadingContent();

  useEffect(() => addLoadingContent('loading-aptitudes')(isLoadingAptitudes), [isLoadingAptitudes]);

  useEffect(() => {
    if (!isLoadingContent && onLoad) {
      onLoad();
    }
  }, [isLoadingContent]);

  useEffect(() => {
    if (!isLoadingAptitudes && feedback) {
      const aptitudePercentages = FeedbackService.getProfileAptitudesPercentages(feedback);

      setAptitudePercentages(aptitudePercentages);
      setPersonalApproachDimensions(FeedbackService.getPersonalApproachDimensions(feedback) ?? []);
      setAptitudeTalents(
        FeedbackService.getAptitudeTalents(feedback, new Set(aptitudePercentages.map((apt) => apt.name))),
      );
    }
  }, [isLoadingAptitudes]);

  const renderContent = () => {
    return userWithResultsReady ? (
      <CoreBox>
        <DoughnutChart aptitudePercentages={aptitudePercentages} aptitudeTalents={aptitudeTalents} />

        <CoreTypography sx={sxStyles.subtitle} variant='h4'>
          Personal Approach
        </CoreTypography>

        <PersonalApproachContext.Provider value={personalApproachState}>
          <PersonalApproachList items={personalApproachDimensions} sourcePage={ECP_SOURCE} />
        </PersonalApproachContext.Provider>

        <CoreTypography sx={sxStyles.subtitle} variant='h4'>
          My aptitude reflections
        </CoreTypography>

        <LearnerReflections
          reflectionTypes={HS_APTITUDE_REFLECTION_TYPES}
          onLoad={addLoadingContent('apitude-reflections')}
        />
      </CoreBox>
    ) : (
      <>
        <CoreTypography sx={sxStyles.subSectionSubTitle}>
          Unlock this section by visiting{' '}
          <Link href={DISCOVERY_BRAINGAMES_URL} color='primary' sx={{ fontWeight: 'bold' }}>
            Aptitude and Career Discovery
          </Link>{' '}
          and completing your brain games. You&apos;ll be able to review your natural talents and reflect on the role
          aptitudes will play in your education and career decision-making.
        </CoreTypography>

        <CoreButton sx={sxStyles.button} onClick={redirectToBrainGames}>
          Complete brain games
        </CoreButton>
      </>
    );
  };

  return (
    <>
      <CoreTypography sx={sxStyles.sectionTitle} variant='h3'>
        Your aptitudes
      </CoreTypography>

      {feedback ? renderContent() : null}
    </>
  );
};
