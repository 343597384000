/* eslint-disable @typescript-eslint/no-unsafe-call */
import { styled } from '@mui/material/styles';
import { coreThemeColors } from '@youscience/khaleesi';

export const StyledCard = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderTop: `5px solid ${coreThemeColors.educationConnectionsPrimaryColors.primary.main}`,
  borderRadius: '0px 0px 12px 12px',
  color: theme.palette.common.black,
  marginBottom: theme.spacing(7),
  minHeight: '385px',
  maxWidth: '260px',
  position: 'relative',
  textAlign: 'left',
  width: '100%',
  boxShadow: `0px 8px 24px -4px ${theme.palette.divider}`,
  transition: 'box-shadow 0.5s',

  '&:hover': {
    boxShadow: `0px 8px 32px -4px ${theme.palette.divider}`,
  },
}));

export const StyledLogo = styled('img')(() => ({
  aspectRatio: '2/1',
  blockSize: 'auto',
  height: '100%',
  maxInlineSize: '100%',
  objectFit: 'fill',
}));

export const sxStyles = {
  logoCover: {
    height: '186px',
    width: '260px',
  },
  title: {
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '260px',
    whiteSpace: 'nowrap',
  },
  subTitle: {
    color: 'rgba(0, 0, 0, 0.6)',
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '260px',
    whiteSpace: 'nowrap',
  },
  boxPadding: {
    padding: '16px 20px',
  },
  containerButtons: {
    textAlign: 'center',
    padding: '20px',
  },
  leftButton: {
    borderRight: 'solid 1px rgba(0, 0, 0, 0.12)',
  },
};

export const StyledContainerLink = styled('a')(() => ({
  textDecoration: 'none',
  '&:hover': {
    cursor: 'pointer',
  },
}));
