import { brightpathTheme } from '@youscience/theme';
import { StyledListTriangle, StyledListTriangleActive } from './PersonalApproachTriangle.styles';

interface PersonalApproachTriangleProps {
  isActive: boolean;
  color: string;
}

export const PersonalApproachTriangle = ({ isActive, color }: PersonalApproachTriangleProps) =>
  isActive ? (
    <StyledListTriangleActive theme={brightpathTheme} color={color} />
  ) : (
    <StyledListTriangle theme={brightpathTheme} color={color} />
  );
