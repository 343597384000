import { Reflection } from '@interfaces';
import ReflectionService from '@services/reflection';
import { useMutation, useQuery } from '@tanstack/react-query';

export const useGetReflections = () =>
  useQuery({ queryKey: ['get-reflections'], queryFn: () => ReflectionService.listReflections() });

export const useCreateReflection = () =>
  useMutation({
    mutationKey: ['create'],
    mutationFn: (body: Reflection) => ReflectionService.createReflection(body),
  });

export const useUpdateReflection = () =>
  useMutation({
    mutationKey: ['update'],
    mutationFn: (body: Reflection) => ReflectionService.updateReflection(body),
  });
