import { useAuthStoreContext } from '@context/authStore';
import { AccessPermission } from '@youscience/user-service-common';
import { useEffect, useState } from 'react';

export const useCurrentUserRole = () => {
  const [userRole, setUserRole] = useState<string>('');
  const { currentAccess } = useAuthStoreContext();

  useEffect(() => {
    if ('tenant' in currentAccess) {
      const userPermission: AccessPermission | undefined = currentAccess.tenant?.permission;

      setUserRole(userPermission?.role ?? '');
    }
  }, [currentAccess]);

  return userRole;
};
