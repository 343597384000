import { CoreBox } from '@youscience/khaleesi';

import { Interest } from '@interfaces';

import AptitudeCard from '../AptitudeCard';

import { sxStyles } from './Interests.styles';

interface InterestsProps {
  interests: Interest[];
}

export const Interests = ({ interests }: InterestsProps) => {
  return (
    <CoreBox sx={sxStyles.container}>
      {interests?.map((interest) => {
        const { name, highlight, acronym } = interest;

        return (
          <CoreBox key={`aptitude-card-${acronym}`} sx={sxStyles.cardWrap}>
            <AptitudeCard acronym={acronym} name={name} highlight={highlight} />
          </CoreBox>
        );
      })}
    </CoreBox>
  );
};
