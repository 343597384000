import { PageLoader } from '@components/Layout';
import { BRIGHTPATH_ADMIN_URL } from '@constants/externalRoutes';
import { useAuthStoreContext } from '@context/authStore';
import { useFetchTenant } from '@hooks/Tenant';
import { isOrgAdmin, isParentOrgAdmin, isYsAdmin } from '@utils/authorize';
import { ReactElement } from 'react';
import { Outlet, useParams } from 'react-router-dom';

interface ProtectedAdminRouteProps {
  redirectPath?: string;
  children?: ReactElement;
}

export const ProtectedAdminRoute = ({ redirectPath = BRIGHTPATH_ADMIN_URL, children }: ProtectedAdminRouteProps) => {
  const { currentAccess, isLoadingUser, isLoadingUserPreferences, isUpdatingUserPreferences } = useAuthStoreContext();
  const params = useParams();
  const tenantId = params.tenantId ? params.tenantId : '';
  const { data: tenant } = useFetchTenant(tenantId);

  if (!currentAccess?.tenant || isLoadingUser || isLoadingUserPreferences || isUpdatingUserPreferences) {
    return <PageLoader />;
  }

  if (currentAccess) {
    const deepPath = tenant?.deepPath || [];
    const hasAdminRole =
      isYsAdmin(currentAccess) || isOrgAdmin(currentAccess, tenantId) || isParentOrgAdmin(currentAccess, deepPath);

    if (hasAdminRole) {
      if (window.location.href.includes('/course-planner-settings')) {
        window.location.href = `/admin/organizations/${tenantId || ''}/configure`;
        return null;
      }
      return children || <Outlet />;
    }
  }

  window.location.href = redirectPath;
  return null;
};
