import { useState } from 'react';
import { CoreBox, CoreButton, CoreGrid, CoreTypography, CoreTooltip } from '@youscience/khaleesi';
import { Divider } from '@mui/material';
import { AttachMoney, DesignServices, Favorite, FavoriteBorder, School } from '@mui/icons-material';

import { FitBullets, FitTypes, fitConstants, fitTypeLabels } from '@components/FitBullets/FitBullets';
import EllipsisTypography from '@components/EllipsisTypography';

import { DISCOVERY_URL } from '@constants/externalRoutes';

import { useSaveFavoriteCareer, useUnsaveFavoriteCareer } from '@hooks/Career';

import { Career } from '@services/career';

import { StyledCard, StyledContainerLink, StyledEmployerLogo, sxStyles } from './CareerCard.styles';

export interface CareerCardProps {
  career: Career;
  userId: string;
  careerUrl?: string;
  fitType?: FitTypes;
}

export const CareerCard = ({ career, careerUrl, userId, fitType }: CareerCardProps) => {
  const [saved, setSaved] = useState<boolean>(career.saved);
  const { mutateAsync: saveFavoriteCareer, isLoading: isSaving } = useSaveFavoriteCareer(career.onetsoc_code, userId);
  const { mutateAsync: unsaveFavoriteCareer, isLoading: isUnsaving } = useUnsaveFavoriteCareer(
    career.onetsoc_code,
    userId,
  );
  const fitValue = career.match?.ranked_overall_fit ?? 0;

  const handleToggleFavorite = async () => {
    if (isSaving || isUnsaving) return;

    setSaved(!saved);
    if (saved) {
      await unsaveFavoriteCareer();
    } else {
      await saveFavoriteCareer();
    }
  };

  const handleMoreDetails = () => {
    window.open(`${DISCOVERY_URL}/careers#/${career.onetsoc_code}`, '_blank');
  };

  return (
    <StyledContainerLink href={careerUrl} target='_blank' rel='noreferrer'>
      <StyledCard>
        <CoreBox sx={sxStyles.logoCover} onClick={handleMoreDetails}>
          <StyledEmployerLogo src={career.images[0].url} alt={`${career.title} logo`} />
        </CoreBox>

        <CoreBox sx={sxStyles.boxPadding} onClick={handleMoreDetails}>
          <CoreGrid item container xs={12}>
            <CoreGrid item xs={8}>
              <CoreTypography variant='caption'>
                {fitConstants.get(fitValue)?.adjective ?? 'Unknown'} {fitTypeLabels[fitType ?? FitTypes.Overall]}
              </CoreTypography>
            </CoreGrid>

            <CoreGrid item xs={4} sx={{ paddingTop: '6px' }}>
              <FitBullets fit={fitValue} type={FitTypes.Overall} />
            </CoreGrid>
          </CoreGrid>
        </CoreBox>

        <Divider />

        <CoreBox sx={sxStyles.boxPadding} onClick={handleMoreDetails}>
          <CoreTooltip title={career.title} disableInteractive>
            <EllipsisTypography sx={sxStyles.title} stringCount={1} variant='h5'>
              {career.title}
            </EllipsisTypography>
          </CoreTooltip>
        </CoreBox>

        <Divider />

        <CoreBox sx={sxStyles.boxPadding} onClick={handleMoreDetails}>
          <CoreGrid item container xs={12}>
            <CoreGrid item xs={1.5}>
              <School sx={sxStyles.icon} />
            </CoreGrid>

            <CoreGrid item xs={7.5}>
              <CoreTypography variant='body2'>Education Investment</CoreTypography>
            </CoreGrid>

            <CoreGrid item xs={3} sx={{ textAlign: 'right' }}>
              <CoreTypography variant='body2' sx={sxStyles.detailNote}>
                {career.investment.education.short_label === 'None'
                  ? 'None'
                  : `${career.investment.education.short_label} YRS`}
              </CoreTypography>
            </CoreGrid>
          </CoreGrid>

          <CoreGrid item container xs={12}>
            <CoreGrid item xs={1.5}>
              <DesignServices sx={sxStyles.icon} />
            </CoreGrid>

            <CoreGrid item xs={7.5}>
              <CoreTypography variant='body2'>Projected Openings</CoreTypography>
            </CoreGrid>

            <CoreGrid item xs={3} sx={{ textAlign: 'right' }}>
              <CoreTypography variant='body2' sx={sxStyles.detailNote}>
                {career.employment.national.openings}
              </CoreTypography>
            </CoreGrid>
          </CoreGrid>

          <CoreGrid item container xs={12}>
            <CoreGrid item xs={1.5}>
              <AttachMoney sx={sxStyles.icon} />
            </CoreGrid>

            <CoreGrid item xs={7.5}>
              <CoreTypography variant='body2'>Average Salary</CoreTypography>
            </CoreGrid>

            <CoreGrid item xs={3} sx={{ textAlign: 'right' }}>
              <CoreTypography variant='body2' sx={sxStyles.detailNote}>
                {parseInt(`${career.salary.national.average / 1000}`, 10)}K
              </CoreTypography>
            </CoreGrid>
          </CoreGrid>
        </CoreBox>

        <Divider />

        <CoreBox sx={sxStyles.buttonsContainer}>
          <CoreButton sx={sxStyles.favoriteBtn} variant='text' onClick={handleToggleFavorite}>
            {saved ? <Favorite /> : <FavoriteBorder />}
          </CoreButton>
        </CoreBox>
      </StyledCard>
    </StyledContainerLink>
  );
};
