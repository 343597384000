export interface refreshToken {
  accessToken: string;
}

const getRefreshToken = () => {
  const refreshToken = localStorage.getItem('refreshToken');

  if (refreshToken) {
    return (JSON.parse(refreshToken) as refreshToken)?.accessToken;
  }

  return undefined;
};

const setRefreshToken = (refreshToken: refreshToken) => {
  localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
};

const removeRefreshToken = () => {
  localStorage.removeItem('refreshToken');
};

const TokenService = {
  getRefreshToken,
  setRefreshToken,
  removeRefreshToken,
};

export default TokenService;
