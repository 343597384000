import { coreThemeColors } from '@youscience/khaleesi';

export const sxStyles = {
  bullets: {
    display: 'flex',
    gap: '6px',

    '> svg': {
      height: '8px',
      width: '8px',

      '& path': {
        fill: coreThemeColors.educationConnectionsPrimaryColors.primary.main,
      },
    },
  },
};
