import { Theme } from '@mui/material';

export const sxStyles = {
  sectionTitle: {
    fontSize: '2.25rem',
    lineHeight: '54px',
  },
  button: (theme: Theme) => ({
    marginTop: theme.spacing(8),
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(8),
  }),
  subtitle: (theme: Theme) => ({
    margin: '2.5rem 0',
    fontSize: '1.75rem',
    marginTop: theme.spacing(10),
  }),
  subSectionSubTitle: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
};
