import { styled } from '@mui/material';

export const sxStyles = {
  toolsBannerBg: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#0C928E4D',
    padding: '4rem 0',
  },
  toolsCardWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1.5rem',
    marginTop: '1.5rem',
  },
};

export const StyledToolsSection = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  maxWidth: '1320px',
  padding: '0 1.5rem',
  width: '100%',
}));
