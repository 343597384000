import IspApi from '@api/isp';

const refreshToken = async (): Promise<unknown> => {
  const response = await IspApi.refreshToken();

  return response;
};

const IspService = { refreshToken };

export default IspService;
