import { AxiosError } from 'axios';
import indefinite from 'indefinite';

import { MAX_GRADE } from '@constants';

import { parseJSONData } from '@pages/CoursePlanner/utils';
import { MeWithAccessRecordsApiResponse } from '@youscience/user-service-common';

export const handleAxiosError = (error: AxiosError) => {
  const serverError = error as AxiosError<{ error: string }>;

  if (serverError?.response?.data) {
    const errorMessage = parseJSONData(serverError.response.data?.error || '{}');

    return errorMessage;
  }
  return serverError.response?.data;
};

export const capitalize = (str: string): string => {
  return str.replace(/^\w/, (c: string) => c.toUpperCase());
};

export const getGradeLevel = (gradeLevel: number | undefined | null): number => gradeLevel || MAX_GRADE;

export const getIndefiniteArticle = (word: string) => indefinite(word).replace(word, '');

export const getUserPreferredName = (userData: MeWithAccessRecordsApiResponse) => {
  return userData?.displayName || userData?.fullName?.split(' ')[0];
};

export const camelize = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};
