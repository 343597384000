import { styled, Theme } from '@mui/material';

export const sxStyles = {
  sectionTitle: {
    fontSize: '2.25rem',
    lineHeight: '54px',
  },
  subSectionTitle: {
    fontSize: '1.75rem',
    lineHeight: '42px',
    marginTop: '2.5rem',
  },
  subSectionSubTitle: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  interestsContainer: {
    margin: '2rem 0',
  },
  clustersContainer: {
    marginTop: '2rem',
  },
  clusterCardsWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1.5rem',
    margin: '2rem 0',
  },
  button: (theme: Theme) => ({
    marginTop: theme.spacing(8),
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(8),
  }),
};

export const StyledShowMoreBtn = styled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '146px',
  margin: '0 auto',
  padding: '0.875rem 1.375rem',
  backgroundColor: '#F9F9F9',
  color: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '40px',
  fontSize: '1rem',

  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    cursor: 'pointer',
  },
}));
