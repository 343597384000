import { Link } from '@mui/material';
import { CoreBox, CoreButton, CoreTypography } from '@youscience/khaleesi';
import { useEffect, useMemo, useState } from 'react';

import { DISCOVERY_BRAINGAMES_URL } from '@constants/externalRoutes';
import { MS_APTITUDE_REFLECTION_TYPES } from '@constants/reflectionTypes';

import AptitudeCard from '@components/Aptitudes/AptitudeCard';
import LearnerReflections from '@components/LearnerReflections';
import { Loader } from '@components/Loader';

import { useIsLoadingContent } from '@customHooks/useIsLoadingContent';

import { useGetFeedback } from '@hooks/Aptitudes';
import { AdjustedPercentage, Aptitude, AptitudeTalents, FeedbackFromMiddleSchool } from '@interfaces/feedback';
import FeedbackService from '@services/feedback';
import { redirectToBrainGames } from '@utils/urlHelper';

import DoughnutChart from '../../DoughnutChart';

import { sxStyles } from './AptitudesSection.styles';

interface AptitudesSectionProps {
  userWithResultsReady: boolean;
  onLoad?: () => void;
}

export const AptitudesSection = ({ userWithResultsReady, onLoad }: AptitudesSectionProps) => {
  const { isLoading: isLoadingAptitudes, data: feedback } = useGetFeedback() as {
    isLoading: boolean;
    data: FeedbackFromMiddleSchool | undefined;
  };

  const [aptitudePercentages, setAptitudePercentages] = useState<AdjustedPercentage[]>([] as AdjustedPercentage[]);
  const [topTwoAptitude, setTopTwoAptitude] = useState<AdjustedPercentage[]>([] as AdjustedPercentage[]);
  const [aptitudeTalents, setAptitudeTalents] = useState<AptitudeTalents[]>([]);

  const { isLoadingContent, addLoadingContent } = useIsLoadingContent();

  useEffect(() => addLoadingContent('loading-aptitudes')(isLoadingAptitudes), [isLoadingAptitudes]);

  useEffect(() => {
    if (!isLoadingContent && onLoad) {
      onLoad();
    }
  }, [isLoadingContent]);

  useEffect(() => {
    if (!isLoadingAptitudes && feedback) {
      const aptitudePercentages = FeedbackService.getSnapshotAptitudesPercentages(feedback);

      setTopTwoAptitude(FeedbackService.getTopTalentsSelector(aptitudePercentages));

      setAptitudeTalents(
        FeedbackService.getMSAptitudeTalents(feedback, new Set(aptitudePercentages.map((apt) => apt.name))),
      );

      setAptitudePercentages(aptitudePercentages);
    }
  }, [isLoadingAptitudes]);

  const preparedTopTwoAptitude = useMemo(() => {
    return topTwoAptitude.reduce((acc: Aptitude[], aptitude: AdjustedPercentage) => {
      const aptitudeData = feedback?.aptitudes.find((item) => item.name === aptitude.name);

      if (!aptitudeData) return [...acc];

      return [...acc, aptitudeData];
    }, [] as Aptitude[]);
  }, [topTwoAptitude, feedback?.aptitudes]);

  return (
    <>
      <CoreTypography sx={sxStyles.sectionTitle} variant='h3'>
        Your aptitudes
      </CoreTypography>

      {userWithResultsReady ? (
        <>
          <CoreBox sx={sxStyles.topTwoAptitude}>
            {preparedTopTwoAptitude.map(({ acronym, name, moniker }) => (
              <AptitudeCard
                key={`aptitude-card-${acronym}`}
                acronym={acronym}
                name={name}
                highlight={`You are a ${moniker}`}
              />
            ))}
          </CoreBox>

          {aptitudeTalents.length > 0 && aptitudePercentages.length > 0 ? (
            <DoughnutChart
              aptitudePercentages={aptitudePercentages}
              aptitudeTalents={aptitudeTalents}
              isShowExploreTalentsBtn={false}
            />
          ) : (
            <Loader />
          )}

          <CoreTypography sx={sxStyles.subSectionTitle} variant='h3'>
            My aptitude reflections
          </CoreTypography>

          <LearnerReflections
            reflectionTypes={MS_APTITUDE_REFLECTION_TYPES}
            onLoad={addLoadingContent('apitude-reflections')}
          />
        </>
      ) : (
        <>
          <CoreTypography sx={sxStyles.subSectionSubTitle}>
            Unlock this section by visiting{' '}
            <Link href={DISCOVERY_BRAINGAMES_URL} color='primary' sx={{ fontWeight: 'bold' }}>
              Aptitude and Career Discovery
            </Link>{' '}
            and completing your brain games. You&apos;ll be able to review your natural talents and reflect on the role
            aptitudes will play in making education and career decisions.
          </CoreTypography>

          <CoreButton sx={sxStyles.button} onClick={redirectToBrainGames}>
            Complete brain games
          </CoreButton>
        </>
      )}
    </>
  );
};
