import { CoreBox } from '@youscience/khaleesi';
import { useRef } from 'react';

import HeadSection from '@components/HeadSection';
import SidebarMenu from '@components/SidebarMenu';

import { useIsLoadingContent } from '@customHooks/useIsLoadingContent';
import { useIsSnippetUser } from '@customHooks/useIsSnippetUser';
import { TenantSettings } from '@interfaces';

import { BREADCRUMBS_PAGE_TEXT } from '@constants/breadcrumbs';

import { getUserPreferredName } from '@utils/helper';

import BriefcaseIcon from '@images/briefcase.svg?react';
import CvIcon from '@images/cv.svg?react';
import FileIcon from '@images/file.svg?react';
import GraduateCapIcon from '@images/graduate-cap.svg?react';
import PencilIcon from '@images/pencil.svg?react';

import { MeWithAccessRecordsApiResponse } from '@youscience/user-service-common';
import AptitudesSection from '../components/MiddleSchool/AptitudesSection';
import CareerReflectionsSection from '../components/MiddleSchool/CareerReflectionsSection';
import EducationSection from '../components/MiddleSchool/EducationSection';

import { StyledMainSectionContentWrap, StyledMainSectionInner, sxStyles } from '../Landing.styles';

interface MiddleSchoolLandingPageProps {
  userData: MeWithAccessRecordsApiResponse;
  tenantSettings?: TenantSettings;
  userWithResultsReady: boolean;
}

export const MiddleSchoolLandingPage = ({
  userWithResultsReady,
  userData,
  tenantSettings,
}: MiddleSchoolLandingPageProps) => {
  const aptitudesRef = useRef(null);
  const careersRef = useRef(null);
  const resumeBuilderRef = useRef(null);
  const educationRef = useRef(null);
  const coursePlannerRef = useRef(null);

  const isSnippetUser = useIsSnippetUser();

  const { isLoadingContent, addLoadingContent } = useIsLoadingContent();

  const menuItems = [
    {
      id: 1,
      title: 'Aptitudes',
      icon: <PencilIcon />,
      value: 'aptitudes',
      ref: aptitudesRef,
    },
    {
      id: 2,
      title: 'Careers',
      icon: <BriefcaseIcon />,
      value: 'careers',
      ref: careersRef,
    },
    {
      id: 3,
      title: 'Resume builder',
      icon: <CvIcon />,
      value: 'resume-builder',
      ref: resumeBuilderRef,
    },
    {
      id: 4,
      title: 'Education',
      icon: <GraduateCapIcon />,
      value: 'education',
      ref: educationRef,
    },
    {
      id: 5,
      title: 'Course planner',
      icon: <FileIcon />,
      value: 'course-planner',
      ref: coursePlannerRef,
    },
  ];

  const title = `${getUserPreferredName(userData)}, You're on an exciting journey!`;
  const subtitle =
    'Take a moment to consider your aptitudes and how they can help you make important education and career decisions. ' +
    'Use these resources to think, plan, and showcase your unique talents!';

  if (!tenantSettings) return null;

  return (
    <>
      <HeadSection title={title} subtitle={subtitle} breadcrumbsCurrentPage={BREADCRUMBS_PAGE_TEXT.LANDING} />

      <CoreBox sx={sxStyles.mainSectionBg}>
        <StyledMainSectionInner>
          <SidebarMenu menuItems={menuItems} tenantSettings={tenantSettings} isLoadingContent={isLoadingContent} />

          <StyledMainSectionContentWrap>
            {!isSnippetUser ? (
              <CoreBox ref={aptitudesRef}>
                <AptitudesSection userWithResultsReady={userWithResultsReady} onLoad={addLoadingContent('apitudes')} />
              </CoreBox>
            ) : null}

            <CoreBox ref={careersRef}>
              <CareerReflectionsSection
                userWithResultsReady={userWithResultsReady}
                isResumeBuilderEnabled={tenantSettings.resumeBuilderEnabled}
                resumeBuilderRef={resumeBuilderRef}
                isSnippetUser={isSnippetUser}
                onLoad={addLoadingContent('careers')}
              />
            </CoreBox>

            <CoreBox ref={educationRef}>
              <EducationSection
                coursePlannerRef={coursePlannerRef}
                isCoursePlannerEnabled={tenantSettings.coursePlannerEnabled}
                onLoad={addLoadingContent('education')}
              />
            </CoreBox>
          </StyledMainSectionContentWrap>
        </StyledMainSectionInner>
      </CoreBox>
    </>
  );
};
