import { useMemo } from 'react';

import { MAX_MIDDLE_SCHOOL_GRADE } from '@constants';

import { ProfileWithExtendedData } from '@interfaces';

import { useAuthStoreContext } from '@context/authStore';

import { getGradeLevel } from '@utils/helper';

export const useIsHighSchool = () => {
  const { userData } = useAuthStoreContext(); // GUS User

  const profile = userData?.profile as ProfileWithExtendedData;
  const gradeLevel = getGradeLevel(profile?.gradeLevel);

  const isHighSchool = useMemo(
    () => gradeLevel > MAX_MIDDLE_SCHOOL_GRADE,
    [(userData?.profile as ProfileWithExtendedData)?.gradeLevel],
  );

  return isHighSchool;
};
