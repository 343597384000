import { ECP_API_URL } from '@constants/externalRoutes';
import { Reflection } from '@interfaces';
import api from '../utils/api';

const create = async (body: Reflection): Promise<Reflection> => {
  const response = await api(`${ECP_API_URL}/reflections`, {
    method: 'POST',
    data: body,
  });

  return response?.data as Reflection;
};

const update = async (id: string, body: Reflection): Promise<Reflection> => {
  const response = await api(`${ECP_API_URL}/reflections/${id}`, {
    method: 'PUT',
    data: body,
  });

  return response?.data as Reflection;
};

const list = async (): Promise<Reflection[]> => {
  const response = await api(`${ECP_API_URL}/reflections`);

  return response?.data as Reflection[];
};

const ReflectionApi = {
  create,
  update,
  list,
};

export default ReflectionApi;
