import { CoreBox } from '@youscience/khaleesi';
import { MouseEvent, MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useIsSnippetUser } from '@customHooks/useIsSnippetUser';
import { TenantSettings } from '@interfaces';

import {
  StyledSidebarInner,
  StyledSidebarItemInner,
  StyledSidebarItemTitle,
  StyledSidebarWrap,
} from './SidebarMenu.styles';

interface MenuItemProps {
  id: number;
  title: string;
  icon: ReactNode;
  value: string;
  ref: MutableRefObject<HTMLAnchorElement | null>;
}
export interface SidebarMenuProps {
  menuItems: MenuItemProps[];
  tenantSettings: TenantSettings;
  isLoadingContent?: boolean;
}

export const SidebarMenu = ({ menuItems, tenantSettings, isLoadingContent }: SidebarMenuProps) => {
  const location = useLocation();
  const currentHash = location.hash.slice(1);
  const menuRef = useRef(null);

  const isSnippetUser = useIsSnippetUser();

  const [stickyMenuScrollEnabled, setStickyMenuScrollEnabled] = useState<boolean>(true);
  const [scrollTarget, setScrollTarget] = useState<string>('');
  const [targetLocationHash, setTargetLocationHash] = useState<string>('');

  const addClassName = (element: HTMLHtmlElement, className: string) => {
    if (element && element.classList) {
      element.classList.add(className);
    }
  };

  const removeClassName = (element: HTMLHtmlElement, className: string) => {
    if (element && element.classList) {
      element.classList.remove(className);
    }
  };

  const getCurrentMenuItem = () => {
    let currentMenuItem: MenuItemProps | undefined;

    menuItems.forEach((menuItem) => {
      const sectionRef = menuItem.ref.current;

      if (sectionRef && menuRef && menuRef.current) {
        const sectionOffset = sectionRef.offsetTop;

        if (sectionOffset <= window.scrollY + 150) {
          currentMenuItem = menuItem;
        }
      }
    });

    return currentMenuItem || menuItems[0];
  };

  const getNavLink = (menuItem: MenuItemProps) => {
    if (menuRef && menuRef.current) {
      const menuRefCurrent = menuRef.current as HTMLAnchorElement;
      const navLink = menuRefCurrent.querySelector(`a[href*="#${menuItem.value}"]`);

      return navLink;
    }

    return null;
  };

  const updateMenuItemSelected = (currentMenuItem?: MenuItemProps) => {
    if (!currentMenuItem) {
      menuItems.forEach((menuItem) => {
        const navLink = getNavLink(menuItem);

        navLink?.querySelector('li')?.classList.remove('Mui-selected');
      });
      return;
    }

    menuItems.forEach((menuItem) => {
      if (menuRef && menuRef.current) {
        const navLink = getNavLink(menuItem);

        if (currentMenuItem?.value === menuItem.value) {
          navLink?.querySelector('li')?.classList.add('Mui-selected');
        } else {
          navLink?.querySelector('li')?.classList.remove('Mui-selected');
        }
      }
    });
  };

  const handleOnClick = (event: MouseEvent, currentMenuItem: MenuItemProps) => {
    event.preventDefault();
    if (currentMenuItem && currentHash !== `#${currentMenuItem.value}`) {
      setScrollTarget(currentMenuItem.value);
    }
  };

  const scrollToCurrentHash = () => {
    if (currentHash) {
      const currentMenuItem: MenuItemProps | undefined = menuItems.find((x) => x.value === currentHash);

      if (!currentMenuItem) return;

      if (localStorage.getItem('location-hash-event') !== 'from-scrolling') {
        const sectionRef = currentMenuItem.ref;

        if (sectionRef && sectionRef.current) {
          sectionRef.current.style.scrollMargin = '114px';
          sectionRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }
    }
  };

  useEffect(() => {
    if (!isLoadingContent) {
      if (!scrollTarget) {
        localStorage.setItem('location-hash-event', 'from-scrolling');
        window.location.hash = targetLocationHash ? `#${targetLocationHash}` : '';
      } else if (scrollTarget === targetLocationHash) {
        setScrollTarget('');
      }
    }
  }, [targetLocationHash]);

  useEffect(() => {
    localStorage.setItem('location-hash-event', '');
    if (!isLoadingContent) {
      if (scrollTarget) {
        setStickyMenuScrollEnabled(false);
        window.location.hash = `#${scrollTarget}`;
      } else {
        setStickyMenuScrollEnabled(true);
        window.location.hash = `#${targetLocationHash}`;
      }
    }
  }, [scrollTarget]);

  useEffect(() => {
    scrollToCurrentHash();
  }, [currentHash]);

  useEffect(() => {
    const onScroll = () => {
      if (!stickyMenuScrollEnabled || isLoadingContent) {
        return;
      }
      const currentMenuItem: MenuItemProps | undefined = getCurrentMenuItem();

      updateMenuItemSelected(currentMenuItem);
    };

    const onScrollEnd = () => {
      if (!isLoadingContent) {
        const currentMenuItem: MenuItemProps | undefined = getCurrentMenuItem();

        setTargetLocationHash(currentMenuItem?.value || '');
      }
    };

    const htmlElement = document.getElementsByTagName('html')[0];

    addClassName(htmlElement, 'scroll-smooth');
    window.addEventListener('scroll', onScroll);
    window.addEventListener('scrollend', onScrollEnd);

    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('scrollend', onScrollEnd);
      removeClassName(htmlElement, 'scroll-smooth');
    };
  }, [stickyMenuScrollEnabled]);

  useEffect(() => {
    if (!isLoadingContent) {
      scrollToCurrentHash();
    }
  }, [isLoadingContent]);

  return (
    <StyledSidebarWrap ref={menuRef}>
      <StyledSidebarInner>
        {menuItems.map((item) => {
          const isSelected = currentHash === item.value;
          const currentHashLink = `#${item.value}`;

          if (!tenantSettings?.resumeBuilderEnabled && item.value === 'resume-builder') return null;

          if (!tenantSettings?.coursePlannerEnabled && item.value === 'course-planner') return null;

          if (isSnippetUser && item.value === 'aptitudes') return null;

          return (
            <Link key={item.id} to={currentHashLink} onClick={(event) => handleOnClick(event, item)}>
              <StyledSidebarItemInner selected={isSelected} value={item.value}>
                <CoreBox>{item.icon}</CoreBox>

                <StyledSidebarItemTitle>{item.title}</StyledSidebarItemTitle>
              </StyledSidebarItemInner>
            </Link>
          );
        })}
      </StyledSidebarInner>
    </StyledSidebarWrap>
  );
};
