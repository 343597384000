import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { PageLoader } from '@components/Layout';

import ErrorPage from '@pages/Error';

import { ALLOWED_USER_ROLES_FOR_LANDING, LEARNER_ROLE } from '@constants';

import { useAuthSessionContext } from '@context/authSession';
import { useAuthStoreContext } from '@context/authStore';

import { useEcpEnabled } from '@hooks/EcpEnabled';
import { useCurrentUserRole } from '@hooks/Users';

interface ProtectedLandingRouteProps {
  children?: ReactElement;
}

export const ProtectedLandingRoute = ({ children }: ProtectedLandingRouteProps) => {
  const { isAuthenticated, isLoading: isLoadingDiscoveryUser } = useAuthSessionContext();
  const { isLoadingUser: isLoadingGusUser, isLoadingUserPreferences } = useAuthStoreContext();

  const { ecpEnabled, isLoadingData: isLoadingEcpData } = useEcpEnabled();
  const userRole = useCurrentUserRole();
  const [preSettingsReady, setPreSettingsReady] = useState<boolean>(false);
  let componentToRender: React.ReactElement = <PageLoader />;

  const isLoadingUserData = useMemo(
    () => isLoadingDiscoveryUser || isLoadingGusUser || isLoadingUserPreferences || isLoadingEcpData,
    [isLoadingDiscoveryUser, isLoadingGusUser, isLoadingUserPreferences, isLoadingEcpData],
  );

  useEffect(() => {
    if (ecpEnabled !== null && userRole !== '') setPreSettingsReady(true);
  }, [ecpEnabled, userRole]);

  if (!isLoadingUserData && preSettingsReady) {
    if (isAuthenticated && ecpEnabled && ALLOWED_USER_ROLES_FOR_LANDING.includes(userRole)) {
      componentToRender = children || <Outlet />;
    } else if (isAuthenticated && !ecpEnabled && userRole === LEARNER_ROLE) {
      componentToRender = <ErrorPage errorType='access-denied-ecp-disabled-learner' />;
    } else if (isAuthenticated && !ecpEnabled) {
      componentToRender = <ErrorPage errorType='access-denied-ecp-disabled-other-users' />;
    } else {
      componentToRender = <ErrorPage errorType='route-not-found' />;
    }
  }

  return componentToRender;
};
