import { DISCOVERY_API_URL } from '@constants/externalRoutes';
import { AxiosResponse } from 'axios';
import api from '../utils/api';

export interface College {
  attributes: {
    address: string;
    baseUrl: string;
    city: string;
    collegeUrl: string;
    cost: string;
    id: number;
    name: string;
    pictureUrl: string;
    schoolLength: string;
    state: string;
    totalStudentsEnrolled: number;
    zip: string;
  };
  favoriteCollege: true;
  id: number;
  inserted_at: string;
}

const getCollegesSaved = async (): Promise<College[]> => {
  const response: AxiosResponse<{ data: College[] }> = await api(`${DISCOVERY_API_URL}/saved_favorite_colleges`);

  return response?.data?.data;
};

const unsaveFavoriteCollege = async (collegeId: string): Promise<College> => {
  const response: AxiosResponse<College> = await api(`${DISCOVERY_API_URL}/favorite_colleges/${collegeId}`, {
    method: 'DELETE',
  });

  return response?.data;
};

const CollegeService = { getCollegesSaved, unsaveFavoriteCollege };

export default CollegeService;
