import { CoreDialogContent, CoreTypography } from '@youscience/khaleesi';
import { useState } from 'react';

import DialogFooter from '@components/DialogFooter';
import { GradeAutocomplete } from '@components/GatherPersonalInfo/GradeAutocomplete';

import { BRIGHTPATH_HOME_URL } from '@constants/externalRoutes';
import { AuthProvider } from '@context/authStore';
import { UserDocumentWithAccess } from '@youscience/user-service-common';
import { StyledDialog, StyledDialogFormControls, StyledDialogTitle, sxStyles } from './GatherPersonalInfo.styles';

interface GatherPersonalInfoProps {
  isOpenGatherPersonalInfo: boolean;
  onSave: () => void;
}

export const GatherPersonalInfo = ({ isOpenGatherPersonalInfo, onSave }: GatherPersonalInfoProps) => {
  const { userData, updateMe } = AuthProvider();

  const [dataIsSaving, setDataIsSaving] = useState(false);

  const [gradeLevel, setGradeLevel] = useState<number | undefined>(undefined);
  const [graduationYear, setGraduationYear] = useState<number | undefined>(undefined);

  const handleSaveButtonClicked = async () => {
    setDataIsSaving(true);

    await updateMe({
      profile: {
        ...userData?.profile,
        graduationYear,
      },
    } as Partial<UserDocumentWithAccess>);

    setDataIsSaving(false);
    onSave();
  };

  const handleGradeChange = (gradeLevel: string | undefined, graduationYear: number | undefined) => {
    if (!Number.isNaN(Number(gradeLevel))) {
      setGradeLevel(Number(gradeLevel));
      setGraduationYear(graduationYear);
    }
  };

  return (
    <StyledDialog data-testid='gatherPersonalInfoDialog' open={isOpenGatherPersonalInfo}>
      <StyledDialogTitle data-testid='plannerQuestionerTitle'>
        {dataIsSaving ? (
          'Thank you for answering'
        ) : (
          <>
            Let us get to know you better
            <CoreTypography data-testid='subtitle' style={{ marginTop: '1rem' }}>
              We would like to know more about you so we can suggest custom options for your future education.
            </CoreTypography>
          </>
        )}
      </StyledDialogTitle>

      <CoreDialogContent sx={sxStyles.content}>
        <StyledDialogFormControls>
          <GradeAutocomplete handleGradeChange={handleGradeChange} />
        </StyledDialogFormControls>
      </CoreDialogContent>

      <DialogFooter
        goBackButtonTitle='Cancel'
        goNextButtonTitle='Save'
        isDisableNextButton={!gradeLevel}
        onGoBack={() => {
          window.location.href = BRIGHTPATH_HOME_URL;
        }}
        onGoNext={handleSaveButtonClicked}
      />
    </StyledDialog>
  );
};
