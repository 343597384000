import { DISCOVERY_URL, DISCOVERY_BRAINGAMES_URL } from '@constants/externalRoutes';

const EXPLORE_APTITUDES_URL = '/your_aptitudes_and_interests/#/aptitudes/';

export const generateAptitudeUrl = (name: string, sourcePage: string | null = null) => {
  const aptitudeLowerCaseName = name.toLowerCase().replace(/ /g, '_').replace(/-/g, '');

  const baseUrl = `${DISCOVERY_URL}/${EXPLORE_APTITUDES_URL}${aptitudeLowerCaseName}`;

  return sourcePage ? `${baseUrl}?from=${sourcePage}` : baseUrl;
};

export const redirectToBrainGames = () => {
  window.location.href = DISCOVERY_BRAINGAMES_URL;
};
