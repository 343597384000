import { useAuthStoreContext } from '@context/authStore';

export const useIsRootAdmin = () => {
  const { currentAccess } = useAuthStoreContext();

  return (
    currentAccess?.tenant?.tenantId === import.meta.env.VITE_ROOT_ORG_ID &&
    currentAccess?.tenant?.permission?.role === 'Admin'
  );
};
