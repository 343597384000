import ReflectionApi from '@api/reflection';
import { Reflection } from '@interfaces';

const listReflections = async (): Promise<Reflection[]> => {
  const reflections = await ReflectionApi.list();

  return reflections;
};

const createReflection = async (body: Reflection): Promise<Reflection> => {
  const reflection = await ReflectionApi.create(body);

  return reflection;
};

const updateReflection = async (body: Reflection): Promise<Reflection> => {
  const reflection = await ReflectionApi.update(body.id ?? '', body);

  return reflection;
};

const ReflectionService = {
  listReflections,
  createReflection,
  updateReflection,
};

export default ReflectionService;
