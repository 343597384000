import { Theme, styled } from '@mui/material';

const lightGreyBg = '#F9F9F9';

export const sxStyles = {
  headSectionBg: (theme: Theme) => ({
    overflow: 'hidden',
    backgroundColor: theme.palette.common.white,
  }),
  mainSectionBg: {
    backgroundColor: lightGreyBg,
  },
  sectionTitle: {
    marginBottom: '2.5rem',
    fontSize: '2.25rem',
  },
};

export const StyledMainSectionInner = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '1.5rem',
  maxWidth: '1320px',
  margin: '0 auto',
  padding: '4rem 1.5rem',

  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    justifyContent: 'center',
  },

  [theme.breakpoints.down('xs')]: {
    marginTop: '4rem',
  },
}));

export const StyledMainSectionContentWrap = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4rem',
  maxWidth: '900px',
  width: '100%',
}));
