import { useState } from 'react';

import { CoreButton } from '@youscience/khaleesi';

import { StyledCircularProgress } from './ActionButton.styles';

type ActionButtonProps = {
  onClick?: () => Promise<void> | void;
  busyText?: string;
} & React.ComponentPropsWithoutRef<typeof CoreButton>;

export const ActionButton = (props: ActionButtonProps) => {
  const { onClick, busyText, ...otherProps } = props;

  const [isBusy, setIsBusy] = useState(false);

  const handleClick = () => {
    if (isBusy) return;

    setIsBusy(true);

    if (!onClick) return;

    const result = onClick();

    if (result instanceof Promise) {
      result.finally(() => setIsBusy(false));
    } else {
      setIsBusy(false);
    }
  };

  return (
    <CoreButton
      {...(onClick ? { onClick: handleClick } : {})}
      {...otherProps}
      startIcon={
        isBusy ? <StyledCircularProgress buttonVariant={otherProps.variant} size={15} /> : otherProps.startIcon
      }
    >
      {isBusy && busyText ? `${busyText}...` : otherProps.children}
    </CoreButton>
  );
};
