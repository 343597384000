export const CLUSTERS: Record<string, unknown> = {
  'Law & Public Safety': {
    name: 'Law, Public Safety, Corrections & Security',
    image: 'https://images.youscience.com/law_2514787.png',
    description: `Planning and providing legal services for the community.`,
  },
  'Hospitality & Tourism': {
    name: 'Hospitality & Tourism',
    image: 'https://images.youscience.com/hospitality_86630092.png',
    description: `The exciting world of hospitality, event planning, and travel.`,
  },
  Teaching: {
    name: 'Education & Training',
    image: 'https://images.youscience.com/education_106690085.png',
    description: `Planning, managing and providing education and training services.`,
  },
  'Sales & Marketing': {
    name: 'Marketing',
    image: 'https://images.youscience.com/marketing_139366502.png',
    description: `Designing, performing, and writing media content.`,
  },
  'Distribution & Logistics': {
    name: 'Transportation, Distribution & Logistics',
    image: 'https://images.youscience.com/distribution_164303681.png',
    description: `Planning the movement of people, materials, and goods.`,
  },
  Business: {
    name: 'Distribution & Logistics',
    image: 'https://images.youscience.com/transportation_132769322.png',
    description: `Planning, management, and movement of materials or goods.`,
  },
  Engineering: {
    name: 'Science, Technology, Engineering & Math',
    image: 'https://images.youscience.com/stem_109345610.png',
    description: `Providing scientific research and technical services.`,
  },
  'Computers & Technology': {
    name: 'Information Technology',
    image: 'https://images.youscience.com/computers_172976948.png',
    description: `Related to design, development, of hardware and software.`,
  },
  Finance: {
    name: 'Finance',
    image: 'https://images.youscience.com/finance_121737160.png',
    description: `Planning, banking, insurance, and business money management.`,
  },
  'Architecture & Construction': {
    name: 'Architecture & Construction',
    image: 'https://images.youscience.com/architecture_105106628.png',
    description: `Designing, planning, building, and maintaining structures.`,
  },
  'Agriculture & Natural Resources': {
    name: 'Agriculture, Food & Natural Resources',
    image: 'https://images.youscience.com/agriculture_86709547.png',
    description: `Producing, distributing, selling, or developing agricultural products.`,
  },
  'Arts & Media': {
    name: 'Arts, A/V Technology & Media',
    image: 'https://images.youscience.com/arts_168161762.png',
    description: `Designing, performing, and writing media content.`,
  },
  'Health Science': {
    name: 'Health Science',
    image: 'https://images.youscience.com/health_125738936.png',
    description: `Providing of therapeutic diagnostic for patients.`,
  },
  'Human Services': {
    name: 'Human Services',
    image: 'https://images.youscience.com/human_151335659.png',
    description: 'Help people through counseling and mental health services.',
  },
  'Advanced Manufacturing': {
    name: 'Manufacturing',
    image: 'https://images.youscience.com/manufacturing_167632928.png',
    description: `Innovate technology to improve processes and products.`,
  },
  'Government & Public Admin': {
    name: 'Government & Public Administration',
    image: 'https://images.youscience.com/government_125738936.png',
    description: `Planning and performing at local, state, and federal levels.`,
  },
};
