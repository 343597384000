import { AppMenuListItemProps } from '@youscience/brightpath-header';
import { DISCOVERY_ADMIN_URL } from './externalRoutes';

export const BURGER_MENU_ADMIN_OPTIONS: AppMenuListItemProps[] = [
  {
    item: {
      key: 'ys-admin',
      title: 'YouScience Admin',
      path: `${DISCOVERY_ADMIN_URL}/tenants`,
      icon: <i className='ys-icon-folder' />,
      translateKey: 'ecp',
    },
  },
];
