import { Theme } from '@mui/material';

export const sxStyles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    padding: '150px',
    alignItems: 'start',
    justifyContent: 'start',
    '@media screen and (max-width: 768px)': {
      padding: '50px',
    },
    '@media screen and (max-width: 480px)': {
      padding: '30px',
    },
  },
  container: {
    backgroundColor: '#FBF5FB',
  },
  error: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '31.92px',
    textTransform: 'upperCase',
  },
  mainMessage: {
    fontWeight: 700,
    fontSize: '56px',
    lineHeight: '84px',
    my: '16px',
    '@media screen and (max-width: 768px)': {
      fontSize: '40px',
    },
    '@media screen and (max-width: 480px)': {
      fontSize: '30px',
    },
  },
  subMessage: {
    fontWeight: 400,
    fontSize: '16px',
    whiteSpace: 'pre-line',
    lineHeight: '20.8px',
    marginBottom: '30px',
    '@media screen and (max-width: 768px)': {
      fontSize: '14px',
    },
    '@media screen and (max-width: 480px)': {
      fontSize: '12px',
      marginBottom: '20px',
    },
  },
  button: (theme: Theme) => ({
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(8),

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  }),
};
