import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';

import { BRIGHTPATH_HOME_URL } from '@constants/externalRoutes';
import { ROUTES } from '@constants/routes';
import { useAuthSessionContext } from '@context/authSession';
import { useFetchCurrentResume } from '@hooks/resumeBuilder';

import { PageLoader } from '@components/Layout';
import { ALLOWED_USER_ROLES_FOR_LANDING } from '@constants';
import { useAuthStoreContext } from '@context/authStore';
import { useEcpEnabled } from '@hooks/EcpEnabled';
import { useCurrentUserRole } from '@hooks/Users';

interface ProtectedResumeBuilderRouteProps {
  redirectPath?: string;
  children?: ReactElement;
}

export const ProtectedResumeBuilderRoute = ({
  redirectPath = BRIGHTPATH_HOME_URL,
  children,
}: ProtectedResumeBuilderRouteProps) => {
  const [searchParams] = useSearchParams();
  const resumeId = searchParams.get('resumeId');
  const options = {
    force: window.location.pathname === ROUTES.RESUME_BUILDER_WELCOME,
    resumeId,
  };
  const { isAuthenticated, isLoading: isLoadingUser } = useAuthSessionContext();
  const { isLoadingUser: isLoadingGusUser } = useAuthStoreContext();
  const { data: resume, isLoading: isLoadingResume } = useFetchCurrentResume(options);
  const { ecpEnabled, isLoadingData: isLoadingEcpData, tenantSettings } = useEcpEnabled();
  const navigate = useNavigate();
  const userRole = useCurrentUserRole();
  const [preSettingsReady, setPreSettingsReady] = useState<boolean>(false);

  const hasAccessToResumeBuilder = useMemo(
    () => ecpEnabled && tenantSettings?.resumeBuilderEnabled,
    [ecpEnabled, tenantSettings],
  );

  const isLoadingPage = useMemo(
    () => isLoadingUser || isLoadingGusUser || isLoadingResume || isLoadingEcpData,
    [isLoadingUser, isLoadingGusUser, isLoadingResume, isLoadingEcpData],
  );

  useEffect(() => {
    if (ecpEnabled !== null && userRole !== '') setPreSettingsReady(true);
  }, [ecpEnabled, userRole]);

  if (isLoadingPage || !preSettingsReady) return <PageLoader />;

  if (isAuthenticated && hasAccessToResumeBuilder && ALLOWED_USER_ROLES_FOR_LANDING.includes(userRole)) {
    if (
      window.location.pathname === ROUTES.RESUME_BUILDER_WELCOME &&
      !window.location.search?.includes('from=nav') &&
      resume?.reason &&
      resume?.reason !== ''
    ) {
      navigate(ROUTES.RESUME);
      return null;
    }
    return children || <Outlet />;
  }

  window.location.href = redirectPath;
  return null;
};
