import { MeAccessRecord } from '@context/authStore';
import { DeepPath } from '@youscience/user-service-common';

export const isOrgAdmin = (currentAccess: Partial<MeAccessRecord>, tenantId: string): boolean => {
  return currentAccess?.tenant?.tenantId === tenantId && currentAccess.tenant.permission?.role === 'Admin';
};

export const isYsAdmin = (currentAccess: Partial<MeAccessRecord>): boolean => {
  return (
    currentAccess?.tenant?.tenantId === import.meta.env.VITE_ROOT_ORG_ID &&
    currentAccess.tenant.permission?.role === 'Admin'
  );
};

export const isParentOrgAdmin = (currentAccess: Partial<MeAccessRecord>, deepPath: DeepPath[]) => {
  return deepPath.some(
    (tenant) =>
      currentAccess?.tenant?.tenantId === tenant.tenantId && currentAccess.tenant.permission?.role === 'Admin',
  );
};
