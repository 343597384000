import { GUS_API_URL } from '@constants/externalRoutes';
import {
  PreferencesRecord,
  TenantDocument,
  UserDocument,
  UserDocumentWithAccess,
} from '@youscience/user-service-common';
import api from '../utils/api';

export type Subset<K> = {
  [attr in keyof K]?: K[attr] extends object
    ? Subset<K[attr]>
    : K[attr] extends object | null
    ? Subset<K[attr]> | null
    : K[attr] extends object | null | undefined
    ? Subset<K[attr]> | null | undefined
    : K[attr];
};

const getMe = async (): Promise<UserDocumentWithAccess> => {
  const response = await api(`${GUS_API_URL}/me`);

  return response?.data as UserDocumentWithAccess;
};

const updateMe = async (data: Subset<UserDocument>): Promise<UserDocumentWithAccess> => {
  const response = await api.patch(`${GUS_API_URL}/me`, data);

  return response?.data as UserDocumentWithAccess;
};

const getPreferences = async (): Promise<PreferencesRecord> => {
  const response = await api(`${GUS_API_URL}/me/preferences`);

  return response?.data as PreferencesRecord;
};

const setPreferences = async (id: string): Promise<PreferencesRecord> => {
  const response = await api(`${GUS_API_URL}/me/preferences`, { method: 'PATCH', data: { currentAccess: id } });

  return response?.data as PreferencesRecord;
};

const getTenant = async (tenantId: string): Promise<TenantDocument> => {
  const response = await api(`${GUS_API_URL}/tenants/${tenantId}`);

  return response?.data as TenantDocument;
};

const GusApi = {
  getMe,
  updateMe,
  getPreferences,
  setPreferences,
  getTenant,
};

export default GusApi;
