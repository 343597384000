import { useMemo } from 'react';

import { MIN_SCHOOL_GRADE } from '@constants';

import { ProfileWithExtendedData } from '@interfaces';

import { useAuthStoreContext } from '@context/authStore';

import { getGradeLevel } from '@utils/helper';

export const useIsSnippetUser = () => {
  const { userData } = useAuthStoreContext(); // GUS User

  const profile = userData?.profile as ProfileWithExtendedData;
  const gradeLevel = getGradeLevel(profile?.gradeLevel);

  return useMemo(() => gradeLevel === MIN_SCHOOL_GRADE, [(userData?.profile as ProfileWithExtendedData)?.gradeLevel]);
};
