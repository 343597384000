import { Fragment, useEffect, useState } from 'react';
import Media from 'react-media';

import PersonalApproachItem from '@components/Aptitudes/PersonalApproachList/PersonalApproachItem';
import {
  StyledBox,
  StyledBoxTriangles,
  StyledItems,
  StyledList,
  StyledNavigationLink,
  sxStyles,
} from '@components/Aptitudes/PersonalApproachList/PersonalApproachList.styles';
import PersonalApproachTriangle from '@components/Aptitudes/PersonalApproachList/PersonalApproachTriangle';
import { BREAKPOINTS } from '@constants';
import { APTITUDES } from '@constants/feedback';
import { usePersonalApproachContext } from '@context/personalApproach';
import { Dimension } from '@interfaces';
import { DimensionGeneralData } from '@interfaces/feedback';
import { ChevronRight } from '@mui/icons-material';
import Box from '@mui/material/Box';
import FeedbackService from '@services/feedback';
import { getIndefiniteArticle } from '@utils/helper';
import { generateAptitudeUrl } from '@utils/urlHelper';
import { CoreBox, CoreTypography } from '@youscience/khaleesi';

interface PersonalApproachListProps {
  items: Dimension[] | undefined;
  sourcePage: string;
}

const colorByAptitude = (name: string) => {
  const aptitudeColors = APTITUDES[name].color;

  return aptitudeColors.primary ?? aptitudeColors.default;
};

export const PersonalApproachList = ({ items, sourcePage }: PersonalApproachListProps) => {
  const { selectedItem, setSelectedItem } = usePersonalApproachContext();
  const [selectedItemGeneralInfo, setSelectedItemGeneralInfo] = useState<DimensionGeneralData>(
    {} as DimensionGeneralData,
  );
  const [selectedItemName, setSelectedItemName] = useState<string>('');

  const onItemSelection = (itemName: string) => {
    if (items) {
      const selected = FeedbackService.getSpecificPersonalApproachDimension(items, itemName);

      setSelectedItem(selected ?? {});
    }
  };

  const renderTriangles = (selectedItemName: string, isMobile = false) => {
    if (isMobile) {
      const color = colorByAptitude(selectedItemName);

      return <PersonalApproachTriangle isActive color={color} />;
    }

    return (
      <StyledBoxTriangles>
        {items?.map(({ general }) => {
          const { name } = general;
          const color = colorByAptitude(name);
          const isActive = selectedItemName === name;

          return <PersonalApproachTriangle key={name} isActive={isActive} color={color} />;
        })}
      </StyledBoxTriangles>
    );
  };

  const renderSelectedItemText = () => {
    const { scales: selectedItemScales } = selectedItem;
    const { your_scale: selectedItemYourScale, name: selectedItemName } = selectedItemGeneralInfo;
    const selectedItemMoniker = selectedItemScales[selectedItemYourScale].moniker;
    const [{ content: selectedItemMonikerName }] = selectedItemMoniker;
    const { monikerPrefix } = APTITUDES[selectedItemName];

    return (
      <CoreBox sx={sxStyles.boxText}>
        <CoreTypography variant='h5'>Your {selectedItemName}</CoreTypography>
        <CoreTypography variant='h5'>
          <Box component='span' sx={{ fontWeight: 300 }}>
            {monikerPrefix || 'You are'}
            {` ${getIndefiniteArticle(selectedItemMonikerName)}`}
          </Box>
          {` ${selectedItemMonikerName}`}.
        </CoreTypography>
      </CoreBox>
    );
  };

  const renderBox = (selectedItemName: string, sourcePage: string, isMobile = false) => {
    return (
      <StyledBox>
        {renderTriangles(selectedItemName, isMobile)}
        <CoreBox sx={sxStyles.boxContent}>
          {renderSelectedItemText()}
          <CoreBox sx={sxStyles.boxActions}>
            <StyledNavigationLink to={generateAptitudeUrl(selectedItemName, sourcePage)}>
              Explore
              <ChevronRight />
            </StyledNavigationLink>
          </CoreBox>
        </CoreBox>
      </StyledBox>
    );
  };

  const renderItems = (selectedItemName: string) => (
    <StyledItems>
      {items?.map(({ general, scales }) => {
        const { your_scale: yourScale, name } = general;
        const { moniker } = scales[yourScale];
        const [{ content: monikerName }] = moniker;
        const color = colorByAptitude(name);
        const isActive = selectedItemName === name;

        return (
          <Fragment key={name}>
            <PersonalApproachItem
              name={name}
              color={color}
              isActive={isActive}
              monikerName={monikerName}
              onItemSelection={onItemSelection}
            />

            <Media query={BREAKPOINTS.large}>
              {(match: boolean) => !match && isActive && renderBox(selectedItemName, sourcePage, true)}
            </Media>
          </Fragment>
        );
      })}
    </StyledItems>
  );

  useEffect(() => {
    if (items) {
      const [firstItem] = items;

      setSelectedItem(firstItem);
    }
  }, [items]);

  useEffect(() => {
    if (selectedItem?.general) {
      const { general: generalInfo } = selectedItem;
      const { name: itemName } = generalInfo;

      setSelectedItemGeneralInfo(generalInfo);
      setSelectedItemName(itemName);
    }
  }, [selectedItem]);

  return selectedItemName && selectedItemGeneralInfo ? (
    <StyledList>
      {renderItems(selectedItemName)}

      <Media query={BREAKPOINTS.large}>{selectedItemGeneralInfo && renderBox(selectedItemName, sourcePage)}</Media>
    </StyledList>
  ) : null;
};
