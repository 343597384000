/* eslint-disable @typescript-eslint/no-unsafe-call */
import { styled } from '@mui/material/styles';
import { coreThemeColors } from '@youscience/khaleesi';
import { Theme } from '@mui/material';

const CARD_WIDTH = '284px';

export const StyledCard = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderTop: `5px solid ${coreThemeColors.educationConnectionsPrimaryColors.primary.main}`,
  borderRadius: '0px 0px 12px 12px',
  color: theme.palette.common.black,
  marginBottom: theme.spacing(7),
  minHeight: '385px',
  maxWidth: CARD_WIDTH,
  position: 'relative',
  textAlign: 'left',
  width: '100%',
  boxShadow: `0px 8px 24px -4px ${theme.palette.divider}`,
  transition: 'box-shadow 0.5s',

  '&:hover': {
    boxShadow: `0px 8px 32px -4px ${theme.palette.divider}`,
  },
}));

export const StyledEmployerLogo = styled('img')(() => ({
  aspectRatio: '2/1',
  blockSize: 'auto',
  height: '100%',
  maxInlineSize: '100%',
  objectFit: 'fill',
}));

export const sxStyles = {
  logoCover: {
    height: '180px',
    width: CARD_WIDTH,
  },
  title: {
    fontSize: '1.5rem',
  },
  detailNote: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  boxPadding: {
    padding: '16px 20px',
  },
  icon: {
    fill: coreThemeColors.educationConnectionsPrimaryColors.primary.main,
  },
  buttonsContainer: {
    padding: '1rem',
  },
  favoriteBtn: (theme: Theme) => ({
    width: '100%',
    padding: '0.75rem',
    borderRadius: '40px',
    color: coreThemeColors.educationConnectionsPrimaryColors.primary.main,

    '&:hover': {
      background: theme.palette.grey[100],
    },
  }),
};

export const StyledContainerLink = styled('a')(() => ({
  textDecoration: 'none',

  '&:hover': {
    cursor: 'pointer',
  },
}));
