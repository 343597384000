import { styled, Theme } from '@mui/material';
import { Link } from 'react-router-dom';

export const StyledList = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  maxWidth: '600px',
  margin: '0 auto',
  backgroundColor: theme.palette.common.white,
  borderTopRightRadius: '3px',
  borderBottomRightRadius: '3px',
  boxShadow: `0px 8px 24px -4px ${theme.palette.divider}`,
  transition: 'box-shadow 0.5s',

  '&:hover': {
    boxShadow: `0px 8px 32px -4px ${theme.palette.divider}`,
  },

  [theme.breakpoints.down('sm')]: {
    maxWidth: '310px',
  },

  [theme.breakpoints.up('md')]: {
    maxWidth: '750px',
    maxHeight: '189px',
  },

  [theme.breakpoints.up('lg')]: {
    maxWidth: '900px',
    maxHeight: 'none',
  },
}));

export const StyledItems = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  flex: 2,

  [theme.breakpoints.up('md')]: {
    height: '200px',
  },
}));

export const StyledBox = styled('div')(({ theme }) => ({
  borderTopRightRadius: '3px',
  borderBottomRightRadius: '3px',
  maxWidth: '477px',
  overflow: 'hidden',
  position: 'relative',
  height: '155px',
  display: 'flex',

  [theme.breakpoints.up('md')]: {
    height: '200px',
    flex: 2,
  },
}));

export const StyledBoxTriangles = styled('div')(() => ({
  flex: 1,
}));

export const StyledNavigationLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: '46px',
  fontWeight: 500,
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  padding: theme.spacing(2, 4),
  borderRadius: theme.spacing(8),
  textDecoration: 'none',
  cursor: 'pointer',
  borderStyle: 'solid',
  borderWidth: '2px',
  borderColor: theme.palette.primary.main,

  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },

  [theme.breakpoints.down('md')]: {
    maxHeight: '30px',
  },
}));

export const sxStyles = {
  boxText: {
    padding: '20px',
    fontWeight: 900,
  },
  boxContent: (theme: Theme) => ({
    position: 'relative',
    width: '100%',

    [theme.breakpoints.up('md')]: {
      flex: 10,
    },
  }),
  boxActions: {
    position: 'absolute',
    right: '15px',
    bottom: '15px',
    padding: '0.3rem',
  },
};
