import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { CoreBox, CoreButton, CoreTypography } from '@youscience/khaleesi';
import { useRef } from 'react';
import { Navigation, Pagination, Swiper } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { SwiperSlide } from 'swiper/react';
import { NavigationOptions } from 'swiper/types';

import { College } from '@services/college';

import CollegeCard from '@components/CollegeCard';

import { EDUCATION_CONNECTIONS_URL } from '@constants/externalRoutes';

import {
  StyledNavigationButtonsWrap,
  StyledNavigationLink,
  StyledSliderHeader,
  StyledSwiper,
  sxStyles,
} from './Colleges.styles';

const SWIPER_DATA = {
  PAGINATION: {
    clickable: true,
  },
  BREAKPOINTS: {
    1180: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 3,
    },
    576: {
      slidesPerView: 3,
    },
  },
};

interface CollegesProps {
  colleges: College[];
}

export const Colleges = ({ colleges }: CollegesProps) => {
  const trimmedColleges = [...colleges.filter((college) => college.favoriteCollege)].slice(0, 3);
  const navigationPrevRef = useRef<HTMLDivElement>(null);
  const navigationNextRef = useRef<HTMLDivElement>(null);

  const handleBeforeInit = (swiper: Swiper) => {
    // eslint-disable-next-line no-param-reassign
    swiper.params.navigation = {
      ...(swiper.params.navigation as NavigationOptions),
      prevEl: navigationPrevRef.current,
      nextEl: navigationNextRef.current,
    };
  };

  const renderColleges = (collegesToRender: College[]) => {
    if (!collegesToRender?.length) {
      return <CoreTypography variant='body1'>{`You haven't selected any potential colleges yet.`}</CoreTypography>;
    }

    return (
      <StyledSwiper
        spaceBetween='32px'
        pagination={SWIPER_DATA.PAGINATION}
        navigation={{
          nextEl: navigationNextRef.current,
          prevEl: navigationPrevRef.current,
        }}
        modules={[Pagination, Navigation]}
        breakpoints={SWIPER_DATA.BREAKPOINTS}
        onBeforeInit={handleBeforeInit}
      >
        {collegesToRender.map((college: College) => (
          <SwiperSlide key={college.id}>
            <CollegeCard college={college} key={`college-card-${college.id}`} />
          </SwiperSlide>
        ))}
      </StyledSwiper>
    );
  };

  return (
    <CoreBox sx={sxStyles.cardSlider}>
      <StyledSliderHeader>
        <CoreTypography sx={sxStyles.sliderTitle} variant='h4'>
          My postsecondary school interests
        </CoreTypography>

        <StyledNavigationButtonsWrap>
          <StyledNavigationLink to={`${EDUCATION_CONNECTIONS_URL}/colleges`}>Explore colleges</StyledNavigationLink>

          {trimmedColleges.length > 3 && (
            <>
              <div ref={navigationPrevRef}>
                <CoreButton sx={sxStyles.navigationButton} variant='text'>
                  <ChevronLeft />
                </CoreButton>
              </div>

              <div ref={navigationNextRef}>
                <CoreButton sx={sxStyles.navigationButton} variant='text'>
                  <ChevronRight />
                </CoreButton>
              </div>
            </>
          )}
        </StyledNavigationButtonsWrap>
      </StyledSliderHeader>

      {renderColleges(trimmedColleges)}
    </CoreBox>
  );
};
