import { useMemo } from 'react';

import { useAuthSessionContext } from '@context/authSession';
import { useAuthStoreContext } from '@context/authStore';

import { useTenantSettings } from '@hooks/TenantSettings';

import { useIsHighSchool } from '@customHooks/useIsHighSchool';

import { RESULTS_READY_STATUS } from '@constants';

import { Loader } from '@components/Loader';

import { GatherPersonalInfo } from '@components/GatherPersonalInfo';
import HighSchoolLandingPage from './HighSchoolLanding';
import MiddleSchoolLandingPage from './MiddleSchoolLanding';

export const LandingPage = () => {
  const { isLoading: isLoadingDiscoveryUser } = useAuthSessionContext();
  const { userData, currentAccess, isLoadingUser: isLoadingGusUser } = useAuthStoreContext();
  const { data: tenantSettings, isLoading: isLoadingTenantSettings } = useTenantSettings(
    currentAccess?.tenant?.tenantId ?? '',
  );
  const isHighSchool = useIsHighSchool();

  const isLoadingUserData = useMemo(
    () => isLoadingDiscoveryUser || isLoadingGusUser || isLoadingTenantSettings,
    [isLoadingDiscoveryUser, isLoadingGusUser, isLoadingTenantSettings],
  );

  const resultsReady =
    userData?.productData?.some((data) => {
      return data.product === 'discovery' && data.data.assessmentsStatus === RESULTS_READY_STATUS;
    }) ?? false;

  const userWithResultsReady = !isLoadingUserData && resultsReady;

  const handleOnSave = () => {
    window.location.reload();
  };

  if (isLoadingUserData) {
    return <Loader />;
  }

  if (!userData?.profile?.graduationYear) {
    return <GatherPersonalInfo isOpenGatherPersonalInfo onSave={handleOnSave} />;
  }

  if (isHighSchool) {
    return (
      <HighSchoolLandingPage
        userData={userData}
        tenantSettings={tenantSettings}
        userWithResultsReady={userWithResultsReady}
      />
    );
  }

  return (
    <MiddleSchoolLandingPage
      userData={userData}
      tenantSettings={tenantSettings}
      userWithResultsReady={userWithResultsReady}
    />
  );
};
