import { styled, Theme } from '@mui/material';
import { CoreBox } from '@youscience/khaleesi';

export const sxStyles = {
  limitText: (theme: Theme) => ({
    fontSize: theme.typography.body2.fontSize,
    color: '#b0b0b0',
  }),
  error: (theme: Theme) => ({
    color: theme.palette.error.main,
  }),
  highlight: (theme: Theme) => ({
    border: `1px solid ${theme.palette.error.main}`,
  }),
  label: {
    marginTop: '-28px',
    backgroundColor: 'white',
    width: '90px',
    paddingLeft: '10px',
    fontSize: '12px',
    color: '#b0b0b0',
  },
  none: {},
};

export const StyledTextArea = styled(CoreBox)(({ theme }) => ({
  border: `1px solid #b0b0b0`,
  borderRadius: 5,
  padding: theme.spacing(4),
  '& .MuiInput-root:before': {
    borderBottom: '0px !important',
  },
  '& .MuiInput-root:after': {
    borderBottom: '0px !important',
  },
}));
