import { Theme, styled } from '@mui/material';
import { CoreTypography } from '@youscience/khaleesi';

export const sxStyles = {
  headSectionBg: (theme: Theme) => ({
    overflow: 'hidden',
    backgroundColor: theme.palette.common.white,
  }),
};

export const StyledHeadSectionInner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  maxWidth: '1320px',
  margin: '0 auto',
  position: 'relative',

  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

export const StyledHeadSectionInfo = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  maxWidth: '694px',
  width: '100%',
  padding: '2rem 1.5rem',
}));

export const StyledHeadSectionTitle = styled(CoreTypography)(() => ({
  marginTop: '2.5rem',
  fontSize: '36px',
  fontWeight: '700',
  lineHeight: '54px',
  letterSpacing: '0.35px',
  textAlign: 'left',
  color: 'rgba(0, 0, 0, 0.87)',
}));

export const StyledHeadSectionSubTitle = styled(CoreTypography)(() => ({
  marginTop: '1rem',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '21px',
  letterSpacing: '0.005em',
  textAlign: 'left',
  color: 'rgba(0, 0, 0, 0.6)',
}));
