import TenantSettingsApi from '@api/tenantSettings';
import { TenantSettings } from '@interfaces';
import { useMutation, useQuery } from '@tanstack/react-query';

export const useTenantSettings = (tenantId: string) =>
  useQuery({
    queryKey: ['tenant-settings'],
    queryFn: () => TenantSettingsApi.get(tenantId),
    enabled: tenantId !== '' && tenantId !== undefined && tenantId !== null,
  });

export const useSaveTenantSettings = () =>
  useMutation({
    mutationKey: ['save-tenant-settings', 'create'],
    mutationFn: (data: Partial<TenantSettings>) => TenantSettingsApi.createOrUpdate(data),
  });
