/* eslint-disable no-console */
/* eslint-disable consistent-return */
import { useEffect } from 'react';
import { pdfjs } from 'react-pdf';

// Track if we've already initialized to prevent multiple attempts
let isWorkerInitialized = false;

// Add declaration for window.pdfjsWorker
declare global {
  interface Window {
    pdfjsWorker?: unknown;
  }
}

/**
 * Set up the PDF.js worker - this runs immediately at module load time
 * to ensure it's set before any PDF components render
 *
 * Note: PDF.js may log "Warning: Setting up fake worker" during initialization.
 * This is normal and expected - it's an internal message that indicates
 * PDF.js is using a temporary fake worker while the real one is loading.
 */
function setupPdfWorker() {
  if (isWorkerInitialized) return;

  try {
    console.log('[PDF Worker] Initializing worker');

    // Polyfill for Promise.withResolvers in older Safari versions.
    // @ts-expect-error This does not exist outside of polyfill which this is doing
    if (typeof Promise.withResolvers === 'undefined') {
      if (window)
        // @ts-expect-error This does not exist outside of polyfill which this is doing
        // eslint-disable-next-line func-names
        window.Promise.withResolvers = function () {
          let resolve;
          let reject;
          const promise = new Promise((res, rej) => {
            resolve = res;
            reject = rej;
          });

          return { promise, resolve, reject };
        };
    }

    // Primary approach: Set the worker source directly
    // Using .mjs extension which is more reliable for ES modules
    const workerUrl = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.8.69/pdf.worker.mjs';

    pdfjs.GlobalWorkerOptions.workerSrc = workerUrl;
    console.log('[PDF Worker] Worker URL set to:', pdfjs.GlobalWorkerOptions.workerSrc);

    // Secondary approach: Load the worker as a script (for older browsers)
    // Only attempt this if we're in a browser environment
    if (typeof window !== 'undefined' && !window.pdfjsWorker) {
      try {
        // Dynamically import the worker for modern browsers
        import(/* webpackIgnore: true */ workerUrl)
          .then(() => {
            console.log('[PDF Worker] Worker module imported successfully');
          })
          .catch((err) => {
            console.warn('[PDF Worker] Failed to import worker module:', err);
          });
      } catch (importErr) {
        console.warn('[PDF Worker] Dynamic import not supported in this environment');
      }
    }

    isWorkerInitialized = true;
  } catch (err) {
    console.error('[PDF Worker] Error in global worker initialization:', err);
    try {
      // Last resort: empty string tells pdf.js to use built-in fallback
      pdfjs.GlobalWorkerOptions.workerSrc = '';
      console.log('[PDF Worker] Falling back to empty worker URL');
      isWorkerInitialized = true;
    } catch (fallbackErr) {
      console.error('[PDF Worker] Critical error - could not set fallback worker URL:', fallbackErr);
    }
  }
}

// Run setup immediately on module load
setupPdfWorker();

/**
 * Hook to configure the PDF.js worker and monitor its status
 * We're using a CDN URL that matches the exact version required by react-pdf
 */
export const usePdfWorker = (): void => {
  useEffect(() => {
    // Ensure worker is initialized when the component mounts
    if (!isWorkerInitialized) {
      setupPdfWorker();
    }

    // Check and repair worker URL if needed
    const checkWorkerStatus = () => {
      if (!pdfjs.GlobalWorkerOptions.workerSrc) {
        console.warn('[PDF Worker] Worker URL is empty, re-applying');
        try {
          pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.8.69/pdf.worker.mjs';
        } catch (err) {
          console.error('[PDF Worker] Failed to re-apply worker URL:', err);
        }
      }
    };

    checkWorkerStatus();
  }, []);
};
