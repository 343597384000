import { ChevronRight } from '@mui/icons-material';
import { CoreBox, CoreTypography } from '@youscience/khaleesi';
import indefinite from 'indefinite';
import { useEffect, useState } from 'react';

import { ECP_SOURCE } from '@constants';
import { AptitudeTalents } from '@interfaces/feedback';
import { generateAptitudeUrl } from '@utils/urlHelper';

import { StyledCardWrap, StyledNavigationLink, StyledTalentsWrap, sxStyles } from './AptitudeTalent.styles';

interface AptitudeTalentProps {
  dimensionKey: string;
  dimensions: AptitudeTalents[];
  isShowExploreBtn?: boolean;
}

export const AptitudeTalent = ({ dimensionKey, dimensions, isShowExploreBtn = true }: AptitudeTalentProps) => {
  const [moniker, setMoniker] = useState<string>('');
  const [measurementShort, setMeasurementShort] = useState<string>('');
  const [talents, setTalents] = useState<{ content: string; id: string | number }[]>([]);

  useEffect(() => {
    const dimension = dimensions.find((dimension) => dimension.name === dimensionKey);
    const scale = dimension?.scales?.[dimension.your_scale];
    const moniker = scale?.moniker[0]?.content ?? '';

    setMoniker(`You are ${indefinite(moniker)}`);
    setMeasurementShort(dimension?.measurement_short || '');
    setTalents(scale?.talents ?? []);
  }, [dimensionKey, dimensions]);

  return (
    <StyledCardWrap>
      <CoreBox sx={sxStyles.header}>
        <CoreTypography variant='h5'>{moniker}</CoreTypography>

        {measurementShort ? <CoreTypography sx={sxStyles.measurementShort}>{measurementShort}</CoreTypography> : null}
      </CoreBox>

      <StyledTalentsWrap>
        {talents?.map((item) => (
          <CoreTypography key={item.id} component='li'>
            {item.content}
          </CoreTypography>
        ))}
      </StyledTalentsWrap>

      {isShowExploreBtn ? (
        <CoreBox sx={sxStyles.actionsWrap}>
          <StyledNavigationLink to={generateAptitudeUrl(dimensionKey, ECP_SOURCE)}>
            Explore
            <ChevronRight />
          </StyledNavigationLink>
        </CoreBox>
      ) : null}
    </StyledCardWrap>
  );
};
