import { styled } from '@mui/material';

export const sxStyles = {
  sectionTitle: {
    margin: '2.5rem 0 2rem',
    fontSize: '1.75rem',
  },
};

export const StyledCardsWrap = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',

  [theme.breakpoints.up('xxs')]: {
    flexDirection: 'row',
  },
}));
