import { Link } from '@mui/material';
import { CoreBox, CoreButton, CoreTypography } from '@youscience/khaleesi';
import { MutableRefObject, useEffect, useState } from 'react';

import Interests from '@components/Aptitudes/Interests';
import { getInterests } from '@components/Aptitudes/Interests/Utils';
import ClusterCard from '@components/ClusterCard';
import LearnerReflections from '@components/LearnerReflections';
import { Loader } from '@components/Loader';

import { useIsLoadingContent } from '@customHooks/useIsLoadingContent';

import { CLUSTERS } from '@constants/clusters';
import { DISCOVERY_BRAINGAMES_URL } from '@constants/externalRoutes';
import { MS_CLUSTERS_REFLECTION_TYPES, MS_INTERESTS_REFLECTION_TYPES } from '@constants/reflectionTypes';

import { useGetFeedback } from '@hooks/Aptitudes';
import { useGetClustersSaved } from '@hooks/Cluster';

import { useAuthStoreContext } from '@context/authStore';
import { FeedbackFromHighSchool, Interest } from '@interfaces';
import { Cluster } from '@services/cluster';

import { redirectToBrainGames } from '@utils/urlHelper';
import ResumeBuilderActions from '../..//ResumeBuilderActions';

import { StyledShowMoreBtn, sxStyles } from './CareerReflectionsSection.styles';

const MAX_CLUSTERS_COUNT = 6;

interface CareerReflectionsSectionProps {
  userWithResultsReady: boolean;
  isResumeBuilderEnabled: boolean;
  resumeBuilderRef: MutableRefObject<HTMLAnchorElement | null>;
  isSnippetUser: boolean;
  onLoad?: () => void;
}

export const CareerReflectionsSection = ({
  userWithResultsReady,
  resumeBuilderRef,
  isResumeBuilderEnabled,
  isSnippetUser,
  onLoad,
}: CareerReflectionsSectionProps) => {
  const { userData } = useAuthStoreContext();
  const { isLoading: isLoadingAptitudes, data: feedback } = useGetFeedback();
  const { isLoading: isLoadingFavoriteClusters, data: clusters } = useGetClustersSaved(userData.userId);

  const [isFullClusterList, setIsFullClusterList] = useState(false);

  const { isLoadingContent, addLoadingContent } = useIsLoadingContent();

  useEffect(() => addLoadingContent('loading-clusters')(isLoadingFavoriteClusters), [isLoadingFavoriteClusters]);
  useEffect(() => addLoadingContent('loading-aptitudes')(isLoadingAptitudes), [isLoadingAptitudes]);

  useEffect(() => {
    if (!isLoadingContent && onLoad) {
      onLoad();
    }
  }, [isLoadingContent]);

  let interests: Interest[] = [];

  if (feedback) {
    interests = getInterests(feedback as FeedbackFromHighSchool);
  }

  const handleToggleShowMoreBtn = () => {
    setIsFullClusterList((prevState) => !prevState);
  };

  const renderClustersCard = () => {
    if (!clusters?.length) return null;

    return clusters
      .filter((cluster: Cluster) => CLUSTERS[cluster?.name])
      .map((cluster: Cluster, index) => {
        const clusterData: Cluster = {
          ...cluster,
          ...(CLUSTERS[cluster.name] as Cluster),
        };

        if (!isFullClusterList && index >= MAX_CLUSTERS_COUNT) return null;

        return <ClusterCard key={cluster.id} cluster={clusterData} userId={userData.userId} />;
      });
  };

  const renderLockMessage = () => {
    return !isSnippetUser ? (
      <CoreTypography sx={sxStyles.subSectionSubTitle} variant='p'>
        Unlock this entire section by visiting Aptitude and Career Discovery and completing your{' '}
        <Link href={DISCOVERY_BRAINGAMES_URL} color='primary' sx={{ fontWeight: 'bold' }}>
          Brain games.
        </Link>{' '}
        You&apos;ll be able to explore your career interests and reflect on your best-fit career options.
      </CoreTypography>
    ) : (
      <>
        <CoreTypography sx={sxStyles.subSectionSubTitle} variant='p'>
          Unlock this entire section by visiting{' '}
          <Link href={DISCOVERY_BRAINGAMES_URL} color='primary' sx={{ fontWeight: 'bold' }}>
            Aptitude and Career Discovery
          </Link>{' '}
          and completing your brain games. You&apos;ll be able to explore your career interests and reflect on your
          best-fit career options.
        </CoreTypography>

        <br />

        <CoreButton sx={sxStyles.button} onClick={redirectToBrainGames}>
          Complete brain games
        </CoreButton>
      </>
    );
  };

  return (
    <>
      <CoreTypography sx={sxStyles.sectionTitle} variant='h3'>
        Careers
      </CoreTypography>

      {userWithResultsReady ? (
        <>
          <CoreTypography sx={sxStyles.subSectionTitle} variant='h4'>
            My top work interests
          </CoreTypography>

          <CoreTypography sx={sxStyles.subSectionSubTitle} variant='p'>
            You expressed interest in these three areas based on the activities you would enjoy.
          </CoreTypography>

          <CoreBox sx={[sxStyles.interestsContainer]}>
            {isLoadingAptitudes ? <Loader /> : <Interests interests={interests} />}
          </CoreBox>

          <CoreTypography sx={[sxStyles.subSectionTitle, { marginBottom: '2rem' }]} variant='h4'>
            My work interest reflections
          </CoreTypography>

          <LearnerReflections
            reflectionTypes={MS_INTERESTS_REFLECTION_TYPES}
            onLoad={addLoadingContent('work-interest-reflections')}
          />

          <CoreBox sx={sxStyles.clustersContainer}>
            {isLoadingFavoriteClusters ? (
              <Loader />
            ) : (
              <>
                <CoreTypography sx={sxStyles.subSectionTitle} variant='h4'>
                  Career clusters
                </CoreTypography>

                <CoreTypography sx={sxStyles.subSectionSubTitle} variant='p'>
                  Tap the card to see what kinds of jobs are in this area.
                </CoreTypography>

                <CoreBox sx={sxStyles.clusterCardsWrap}>{renderClustersCard()}</CoreBox>

                <StyledShowMoreBtn onClick={handleToggleShowMoreBtn}>
                  {isFullClusterList ? 'Show less' : 'Show more'}
                </StyledShowMoreBtn>
              </>
            )}
          </CoreBox>

          <CoreTypography sx={[sxStyles.subSectionTitle, { marginBottom: '2rem' }]} variant='h4'>
            My career reflections
          </CoreTypography>

          <LearnerReflections
            reflectionTypes={MS_CLUSTERS_REFLECTION_TYPES}
            onLoad={addLoadingContent('career-reflections')}
          />
        </>
      ) : (
        renderLockMessage()
      )}

      {isResumeBuilderEnabled ? (
        <CoreBox ref={resumeBuilderRef}>
          <ResumeBuilderActions sxTitle={sxStyles.subSectionTitle} />
        </CoreBox>
      ) : null}
    </>
  );
};
