import { APTITUDES } from '@constants/feedback';
import { AdjustedPercentage, Aptitude, Dimension, ScaledPercentile } from '@interfaces';

const TOTAL_PERCENTAGE = 100;
const MIN_PERCENTAGE_SIZE = 5;
const MAX_PERCENTAGE_SIZE = 40;

const linearScaledCalculation = (percentile: number) => Math.ceil(percentile * 0.2);

const calculateFixedSizeSlices = (aptitudes: Dimension[] | Aptitude[]): number => {
  let fixedSizeSlices = 0;

  const aptitude = aptitudes.length && aptitudes[0];

  if (!aptitude) {
    return 0;
  }

  if ('general' in aptitude) {
    fixedSizeSlices = (aptitudes as Dimension[]).reduce((sum: number, aptitude: Dimension) => {
      const { name } = aptitude.general;
      const aptitudeValues = APTITUDES[name];

      return sum + (aptitudeValues?.fixedPercentage || 0);
    }, 0);
  } else {
    fixedSizeSlices = (aptitudes as Aptitude[]).reduce((sum: number, aptitude: Aptitude) => {
      const { name } = aptitude;
      const aptitudeValues = APTITUDES[name];

      return sum + (aptitudeValues?.fixedPercentage || 0);
    }, 0);
  }

  return fixedSizeSlices;
};

const getScaledPercentiles = (aptitudes: Dimension[] | Aptitude[]): (ScaledPercentile | undefined)[] =>
  aptitudes.map((aptitude) => {
    let name;
    let percentile;

    /* Add support to both versions Snapshot and Profile
    Profile { general: { name: '', percentile: 10 } }
    Snapshot { name: '', score: '10' }
    */
    if ('general' in aptitude) {
      const { general } = aptitude;

      name = general.name;
      percentile = general.percentile;
    } else {
      name = aptitude.name;
      percentile = parseInt(aptitude.score ?? '', 10);
    }

    if (APTITUDES[name].fixedPercentage) {
      return undefined;
    }

    const scaledPercentile = linearScaledCalculation(Number(percentile || 0));

    return { name, scaledPercentile };
  });

const addFixedSlices = (aptitudes: Dimension[] | Aptitude[], results: (AdjustedPercentage | undefined)[]) => {
  let fixedSlices: AdjustedPercentage[];

  const aptitude = aptitudes.length && aptitudes[0];

  if (!aptitude) {
    return [];
  }

  if ('general' in aptitude) {
    fixedSlices = (aptitudes as Dimension[]).reduce(
      (aptitudePercentages: (AdjustedPercentage | undefined)[], aptitude: Dimension) => {
        const { name } = aptitude.general;

        const aptitudeValues = APTITUDES[name];

        if (aptitudeValues?.fixedPercentage) {
          return [...aptitudePercentages, { name, percent: aptitudeValues.fixedPercentage }];
        }

        return aptitudePercentages;
      },
      results,
    ) as AdjustedPercentage[];
  } else {
    fixedSlices = (aptitudes as Aptitude[]).reduce(
      (aptitudePercentages: (AdjustedPercentage | undefined)[], aptitude: Aptitude) => {
        const { name } = aptitude;
        const aptitudeValues = APTITUDES[name];

        if (aptitudeValues?.fixedPercentage) {
          return [...aptitudePercentages, { name, percent: aptitudeValues.fixedPercentage }];
        }

        return aptitudePercentages;
      },
      results,
    ) as AdjustedPercentage[];
  }

  return fixedSlices;
};

const getAdjustedPercentages = (
  scaledPercentiles: (ScaledPercentile | undefined)[],
  totalPercentageButFixedSize: number,
) => {
  const totalPercentiles = scaledPercentiles.reduce(
    (total, current) => (current ? total + current.scaledPercentile : total),
    0,
  );

  return scaledPercentiles.map((percentile) => {
    let adjustedPercentage: AdjustedPercentage | undefined;

    if (percentile) {
      const { name, scaledPercentile } = percentile;
      let percent = (scaledPercentile / totalPercentiles) * totalPercentageButFixedSize;

      if (percent > MAX_PERCENTAGE_SIZE) {
        percent = MAX_PERCENTAGE_SIZE;
      } else if (percent < MIN_PERCENTAGE_SIZE) {
        percent = MIN_PERCENTAGE_SIZE;
      }

      adjustedPercentage = { name, percent };
    }

    return adjustedPercentage;
  });
};

export const calculatePercentageValues = (aptitudes: Dimension[] | Aptitude[]) => {
  let scaledPercentiles: (ScaledPercentile | undefined)[] = getScaledPercentiles(aptitudes);

  scaledPercentiles = scaledPercentiles.filter((object) => !!object);

  const fixedSizes = calculateFixedSizeSlices(aptitudes);

  const totalPercentageButFixedSize = TOTAL_PERCENTAGE - fixedSizes;

  const adjustedPercentages: (AdjustedPercentage | undefined)[] = getAdjustedPercentages(
    scaledPercentiles,
    totalPercentageButFixedSize,
  );

  const totalAdjustedPercentages = adjustedPercentages.reduce(
    (total, current) => (current ? total + current.percent : total),
    0,
  );

  const marginToAdjust = (totalPercentageButFixedSize - totalAdjustedPercentages) / adjustedPercentages.length;

  const adjustedPercentagesWithMargin = adjustedPercentages.map((adjustedPercentage) => {
    let result: AdjustedPercentage | undefined;

    if (adjustedPercentage) {
      const { name, percent } = adjustedPercentage;

      result = { name, percent: percent + marginToAdjust };
    }

    return result;
  });

  return addFixedSlices(aptitudes, adjustedPercentagesWithMargin);
};
