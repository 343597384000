import { styled, Theme } from '@mui/material';

const baseListTriangle = (color: string) => ({
  borderTop: '25px solid transparent',
  borderBottom: '25px solid transparent',
  transition: 'border ease 100ms',
  borderLeftColor: color,
});

const baseMobileStyles = (color: string) => ({
  position: 'absolute',
  right: '0',
  borderLeft: '25px solid transparent',
  borderRight: '25px solid transparent',
  borderLeftColor: 'none',
  borderTopColor: color,
});

interface props {
  theme: Theme;
  color: string;
}

export const StyledListTriangle = styled('div')(({ theme, color }: props) => ({
  ...baseListTriangle(color),
  [theme.breakpoints.down('md')]: {
    ...baseMobileStyles(color),
  },
}));

export const StyledListTriangleActive = styled('div')(({ theme, color }: props) => ({
  ...baseListTriangle(color),
  [theme.breakpoints.down('md')]: {
    ...baseMobileStyles(color),
    borderTopWidth: '30px',
    borderTopStyle: 'solid',
    borderLeftStyle: 'none',
    borderLeftWidth: '0px',
  },
  [theme.breakpoints.up('md')]: {
    borderLeftWidth: '30px',
    borderLeftStyle: 'solid',
  },
}));
